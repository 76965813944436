import { createContext } from "react";
import { LoadingOutlined } from "@ant-design/icons";

export const ADMIN_LAYOUT = {
  LOGO: {
    height: "36px",
    padding: "60px 40px 30px",
  },
  SUBTITLE: {
    fontSize: "22px",
  },
  CONTENT: {
    padding: "60px 60px",
  },
  MAXWIDTH: 800,
};

export const PASSWORD_STRONG = new RegExp(
  "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
);
export const PASSWORD_MEDIUM = new RegExp(
  "((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,}))|(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])|((?=.*[a-z])(?=.*[A-Z])(?=.{8,}))"
);

export const STORAGE_KEY_REDIRECT = "kinetica_saas_redirect";
export const STORAGE_KEY_TEAM = "kinetica_saas_team";

export const AlertContext = createContext();

export const Spinner = <LoadingOutlined style={{ fontSize: 48 }} spin />;

export const SERVICE_LEVEL = {
  FREE_SHARED: "free_shared",
  DEDICATED: "paid_hourly",
};

export const COLORS = [
  "#f72585",
  "#b5179e",
  "#7209b7",
  "#560bad",
  "#480ca8",
  "#3a0ca3",
  "#3f37c9",
  "#4361ee",
  "#4895ef",
  "#4cc9f0",
];

export const DEDICATED_SIZE_COLORS = {
  xsmall: COLORS[0],
  small: COLORS[2],
  medium: COLORS[7],
  large: COLORS[8],
};
