import { useState } from "react";
import { Modal, Form, Input, notification } from "antd";
import axios from "axios";

import { handleError } from "../../helpers";

const API_KEY = "2e148ac7-89c1-4c51-a422-b12881a009fa";

const UserDeleteModal = ({
  user,
  open = true,
  onCancel = () => {},
  onDelete = () => {},
}) => {
  const [form] = Form.useForm();

  const [deleting, setDeleting] = useState(false);

  const onFinish = async (values) => {
    if (
      !values.confirm_email_addr ||
      values.confirm_email_addr !== user.email_addr
    ) {
      form.setFields([
        {
          name: "confirm_email_addr",
          value: values.confirm_email_addr,
          errors: ["E-mail address must match exactly!"],
        },
      ]);
      return;
    }

    try {
      setDeleting(true);

      await axios.delete(
        `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/users/${user.id}`,
        {
          headers: {
            "x-api-key": API_KEY,
          },
        }
      );
      notification.success({
        message: "User Delete",
        description: "User is being deprovisioned.",
      });

      form.resetFields();
      onDelete();
    } catch (error) {
      handleError(error);
    } finally {
      setDeleting(false);
    }
  };

  const handleDelete = () => {
    form.submit();
  };

  const handleCancel = (_) => {
    form.resetFields();
    onCancel();
  };

  return user ? (
    <Modal
      title={<span style={{ color: "#ff4d4f" }}>Delete User</span>}
      open={open}
      okText="Confirm"
      okButtonProps={{ danger: true }}
      onOk={handleDelete}
      confirmLoading={deleting}
      onCancel={handleCancel}
      style={{ border: "10px solid #ff4d4f" }}
      destroyOnClose
      centered
    >
      <p>
        This is irreversible. We will delete the user and all their database
        accounts.
      </p>
      <p>
        Confirm by entering the e-mail address of the user below:
        <br />
        <strong style={{ color: "#ff4d4f" }}>{user.email_addr}</strong>
      </p>
      <Form
        form={form}
        layout="vertical"
        initialValues={{}}
        onFinish={onFinish}
      >
        <Form.Item label="Confirm E-mail Address" name="confirm_email_addr">
          <Input size="large" />
        </Form.Item>
      </Form>
    </Modal>
  ) : null;
};

export default UserDeleteModal;
