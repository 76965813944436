import React, { useState, useRef } from "react";
import { Modal, Form, Input } from "antd";
import { UserOutlined, KeyOutlined } from "@ant-design/icons";
import { useAuth } from "../../hooks/useAuth";
import { handleError } from "../../helpers";

const { Password } = Input;

const ReauthModal = ({ visible, onCancel, onReauth }) => {
  const auth = useAuth();
  const emailRef = useRef(null);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    if (!values.email || !values.password) {
      return;
    }

    try {
      setIsLoggingIn(true);
      await auth.login(values.email, values.password);
      onReauth();
    } catch (error) {
      handleError(error);
      setIsLoggingIn(false);
    }
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    // console.log("onFinishFailed", values, errorFields, outOfDate);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleLogin = () => {
    form.submit();
  };

  return (
    <Modal
      title="Verify Credentials"
      okText="Log In"
      onOk={handleLogin}
      onCancel={handleCancel}
      confirmLoading={isLoggingIn}
      closable={false}
      visible={visible}
      centered
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{}}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item label="E-Mail" name="email">
          <Input
            ref={(ref) => {
              emailRef.current = ref;
            }}
            size="large"
            prefix={<UserOutlined />}
          />
        </Form.Item>
        <Form.Item label="Password" name="password">
          <Password size="large" prefix={<KeyOutlined />} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ReauthModal;
