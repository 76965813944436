import React, { useEffect, useMemo, useState } from "react";
import { Alert, Spin } from "antd";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { IntercomProvider, useIntercom } from "react-use-intercom";

import Layout from "./components/Layout/Layout";
import PrivateRoute from "./components/Route/PrivateRoute";
import PlansRoute from "./components/Route/PlansRoute";
import Login from "./components/Auth/Login";
import Trynow from "./components/Auth/Trynow";
import EmailWebhook from "./components/Auth/EmailWebhook";
import Dashboard from "./components/Dashboard/Dashboard";
import Cluster from "./components/Cluster/Cluster";
import Account from "./components/Account/Account";
import Plans from "./components/Plans/Plans";
import Free from "./components/Free/Free";
import Dedicated from "./components/Dedicated/Dedicated";
import Admin from "./components/Admin/Admin";
import Support from "./components/Support/Support";
import NotFound from "./components/NotFound/NotFound";
import Disabled from "./components/Disabled/Disabled";
import Limit from "./components/Limit/Limit";
import { useAuth } from "./hooks/useAuth";
import firebase from "./services/firebase";
import { AlertContext, Spinner } from "./constants";
import "./App.less";

Spin.setDefaultIndicator(Spinner);

const IntercomBoot = ({ children }) => {
  const { boot, update } = useIntercom();
  const auth = useAuth();
  try {
    if (auth.user) {
      const payload = {
        userId: auth.user.db.id,
        email: auth.user.email,
        userHash: auth.user.db.hash,
        name: auth.user.displayName,
        customAttributes: {
          group_id: auth.user.db.group_id,
        },
      };
      boot();
      update(payload);
    } else {
      boot();
    }
  } catch (err) {
    boot();
  }
  return children;
};

const IntercomWrapper = ({ children }) => {
  return (
    <IntercomProvider
      appId={process.env.REACT_APP_INTERCOM_APP_ID}
      autoBoot={false}
    >
      <IntercomBoot>{children}</IntercomBoot>
    </IntercomProvider>
  );
};

const usePageViews = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== "/") {
      window.analytics.page(location.pathname);
    }
  }, [location]);
};

const App = () => {
  const auth = useAuth();
  const db = firebase.firestore();

  const [alerts, setAlerts] = useState([]);

  usePageViews();

  useEffect(() => {
    const unsubscribe = db.collection("alerts").onSnapshot((snapshot) => {
      const alerts = [];
      snapshot.forEach((doc) => {
        alerts.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setAlerts(alerts);
    });
    return () => unsubscribe();
  }, [db]);

  const activeAlerts = useMemo(() => {
    return alerts.filter((alert) => alert.status === "online");
  }, [alerts]);

  const disabled = useMemo(() => {
    return alerts.some((alert) => {
      return alert.disable;
    });
  }, [alerts]);

  return (
    <IntercomWrapper>
      <AlertContext.Provider value={activeAlerts}>
        {activeAlerts.length > 0 &&
          activeAlerts.map((alert) => {
            const { id, message, description, type } = alert;
            return (
              <Alert
                key={id}
                message={message}
                description={description}
                type={type}
                showIcon
              />
            );
          })}
        <div
          style={
            process.env.REACT_APP_ENV === "prod"
              ? {
                  height:
                    activeAlerts.length > 0
                      ? `calc(100vh - ${activeAlerts.length * 83}px)`
                      : "100vh",
                }
              : {
                  height:
                    activeAlerts.length > 0
                      ? `calc(100vh - ${activeAlerts.length * 83}px)`
                      : "100vh",
                  border: "10px solid #E040FB",
                }
          }
        >
          {disabled && auth.user !== null && (
            <Switch>
              {auth.user && auth.user.db.is_admin && (
                <PrivateRoute exact path="/admin">
                  <Layout>
                    <Admin />
                  </Layout>
                </PrivateRoute>
              )}
              <Route path="/login">
                <Login />
              </Route>
              <Route>
                <Disabled />
              </Route>
            </Switch>
          )}
          {!disabled && auth.user !== null && (
            <Switch>
              <PrivateRoute exact path="/">
                <Redirect to="/dashboard" />
              </PrivateRoute>
              <PrivateRoute exact path="/dashboard">
                <PlansRoute>
                  <Layout>
                    <Dashboard />
                  </Layout>
                </PlansRoute>
              </PrivateRoute>
              <PrivateRoute exact path="/cluster/:cluster_id">
                <Layout>
                  <Cluster />
                </Layout>
              </PrivateRoute>
              <PrivateRoute exact path="/plans">
                <Plans />
              </PrivateRoute>
              <PrivateRoute exact path="/free">
                <Limit>
                  <Free />
                </Limit>
              </PrivateRoute>
              <PrivateRoute exact path="/dedicated">
                <Dedicated />
              </PrivateRoute>
              <PrivateRoute exact path="/account">
                <Layout>
                  <Account />
                </Layout>
              </PrivateRoute>
              {auth.user && auth.user.db.is_admin && (
                <PrivateRoute exact path="/admin">
                  <Layout>
                    <Admin />
                  </Layout>
                </PrivateRoute>
              )}
              <PrivateRoute exact path="/support">
                <Layout>
                  <Support />
                </Layout>
              </PrivateRoute>
              <Route path="/email-webhook">
                <EmailWebhook />
              </Route>
              <Route path="/trynow">
                <Trynow />
              </Route>
              <Route path="/login">
                <Login />
              </Route>
              <Route>
                <NotFound />
              </Route>
            </Switch>
          )}
        </div>
      </AlertContext.Provider>
    </IntercomWrapper>
  );
};

export default App;
