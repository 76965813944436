import React, { useMemo, useState, useEffect } from "react";
import { Row, Col, Button, Badge, Alert, Spin, notification } from "antd";
import {
  ExperimentOutlined,
  CloudServerOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import axios from "axios";

import Wizard from "../Layout/Wizard";
import Header from "../Header/Header";
import Plan from "./Plan";
import KineticaLogo from "../../images/logo.svg";
import { useAuth } from "../../hooks/useAuth";
import { handleError } from "../../helpers";
import useSegment from "../../hooks/useSegment";
import { SERVICE_LEVEL } from "../../constants";

const Plans = () => {
  const auth = useAuth();

  const [clustersLoading, setClustersLoading] = useState(false);
  const [clusters, setClusters] = useState(null);

  const [products, setProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(false);

  const [prices, setPrices] = useState([]);
  const [pricesLoading, setPricesLoading] = useState(false);

  const segment = useSegment(auth);

  useEffect(() => {
    if (!auth.user.emailVerified) {
      // Segment
      segment.track("email_verify_warning");
    }

    const fetchProducts = async () => {
      try {
        setProductsLoading(true);
        const token = await auth.getIdToken();
        const resp = await axios.get(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/products`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const { products } = resp.data;
        setProducts(products);
      } catch (error) {
        handleError(error);
        setProducts([]);
      } finally {
        setProductsLoading(false);
      }
    };

    const fetchPrices = async () => {
      try {
        setPricesLoading(true);
        const token = await auth.getIdToken();
        const resp = await axios.get(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/prices`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const { prices } = resp.data;
        setPrices(prices);
      } catch (error) {
        handleError(error);
        setPrices([]);
      } finally {
        setPricesLoading(false);
      }
    };

    const fetch = async () => {
      try {
        const token = await auth.getIdToken();
        const resp = await axios.get(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/clusters`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const clusters = resp?.data?.clusters.filter(
          (cluster) => Object.keys(cluster).length > 1
        );

        setClusters(clusters);
        setClustersLoading(false);
      } catch (error) {
        handleError(error);
        setClusters([]);
        setClustersLoading(false);
      }
    };

    fetchProducts();
    fetchPrices();
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const clusterLimitExceeded = useMemo(() => {
    const limit = auth.user.db?.cluster_limit ?? 1;
    return (
      limit >= 0 &&
      clusters &&
      clusters.filter(
        (cluster) => cluster.service_level === SERVICE_LEVEL.FREE_SHARED
      ).length >= limit
    );
  }, [auth, clusters]);

  const handleResend = async () => {
    try {
      await auth.sendEmailVerification();
      notification.info({
        message: "E-Mail Verification",
        description:
          "A new verification e-mail has been sent. Please check and click the verification link to confirm your account.",
        duration: 0,
      });
    } catch (error) {
      handleError(error);
    }
  };

  const startingDedicatedPricing = useMemo(
    (_) => {
      if (products.length > 0 && prices.length > 0) {
        return products
          .filter((product) => {
            const {
              metadata: { type },
            } = product;
            return type === "compute";
          })
          .reduce((acc, cur) => {
            const price = prices.find((price) => price.product === cur.id);
            if (acc === 0) {
              return price.unit_amount / 100;
            }
            return Math.min(price.unit_amount / 100, acc);
          }, 0);
      }
      return 0;
    },
    [products, prices]
  );

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const FREE_COLORS = ["#716ADF", "#D687E2"];
  const DEDICATED_COLORS = ["#6AC0DF", "#87E2CC"];

  return (
    clusters && (
      <Wizard>
        <div style={{ textAlign: "center", marginBottom: "0px" }}>
          <img
            src={KineticaLogo}
            alt="Kinetica Logo"
            style={{ height: "40px", position: "relative" }}
          />
          <h1
            style={{
              color: "#8A68C9",
              fontSize: "34px",
              fontWeight: "200",
            }}
          >
            Plans
          </h1>
        </div>
        <div
          className="plans"
          style={{
            padding: "20px 40px 30px",
            minHeight: 680,
            width: 800,
            margin: "auto",
          }}
        >
          <Header />
          {!auth.user.emailVerified ? (
            <>
              <div style={{ height: "5px" }}></div>
              <h2 style={{ fontWeight: 300, marginBottom: "20px" }}>
                Verification
              </h2>
              <div
                style={{
                  backgroundColor: "#ffffff",
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
                  borderRadius: 20,
                  padding: "30px 50px 50px",
                  height: 565,
                }}
              >
                <Alert
                  message="Warning"
                  description={
                    <>
                      Your account{" "}
                      <strong>{auth.user.db.email_addr.toLowerCase()}</strong>{" "}
                      requires e-mail verification. You will receive an e-mail
                      within a few minutes with a link to verify your account.
                      If not, you can{" "}
                      <Button
                        type="link"
                        onClick={handleResend}
                        size="small"
                        style={{ padding: "0px" }}
                      >
                        click here to resend
                      </Button>
                      .
                    </>
                  }
                  type="warning"
                />
              </div>
            </>
          ) : (
            <>
              <h2 style={{ fontWeight: 300, marginBottom: "15px" }}>
                <Link to="/">
                  <LeftOutlined style={{ marginRight: 10 }} />
                </Link>
                Choose Your Plan
              </h2>
              <div
                style={{
                  minHeight: 420,
                  margin: "10px 0 10px",
                  padding: 0,
                  borderRadius: 10,
                }}
              >
                <Row gutter={20}>
                  <Col span={12}>
                    <Plan
                      type="free"
                      icon={
                        <ExperimentOutlined
                          style={{ fontSize: 30, color: "#ffffff" }}
                        />
                      }
                      title={
                        <>
                          <label style={{ color: "#999999" }}>
                            For Getting Started
                          </label>
                          <h2 style={{ lineHeight: "24px" }}>Free</h2>
                        </>
                      }
                      colors={FREE_COLORS}
                      description={
                        <>
                          Your very own cloud analytics engine.{" "}
                          <strong style={{ color: "#666666" }}>
                            Free Forever
                          </strong>
                        </>
                      }
                      pricing={
                        <div style={{ height: 70 }}>
                          <span style={{ fontSize: 14, display: "block" }}>
                            &nbsp;
                          </span>
                          <span style={{ fontSize: 46, lineHeight: "46px" }}>
                            $0
                          </span>
                        </div>
                      }
                      included={[
                        "Store and analyze up to 10GB of data",
                        "Shared compute resources",
                        "Full Kinetica database functionality",
                        "Unlimited SQL queries and API access",
                        "Unlimited SQL workbooks",
                        "Basic Support",
                      ]}
                      override={
                        !clustersLoading &&
                        clusterLimitExceeded && (
                          <Alert
                            message="You already activated your Free Plan account. To see your database information and login, please click the Dashboard button below."
                            type="info"
                          />
                        )
                      }
                      action={
                        !clustersLoading ? (
                          !clusterLimitExceeded ? (
                            <Link
                              to="/free"
                              onClick={() => {
                                // Segment
                                segment.track("get_started_free");
                              }}
                            >
                              <Button
                                type="primary"
                                size="large"
                                style={{
                                  borderColor: FREE_COLORS[0],
                                  borderRadius: "10px",
                                  height: "60px",
                                  fontSize: "20px",
                                  background: `linear-gradient(6deg, ${FREE_COLORS[0]} 0%, ${FREE_COLORS[1]} 100%)`,
                                }}
                                block
                              >
                                Get Started
                              </Button>
                            </Link>
                          ) : (
                            <Link to="/dashboard">
                              <Button
                                type="primary"
                                size="large"
                                style={{
                                  borderColor: FREE_COLORS[0],
                                  borderRadius: "10px",
                                  height: "60px",
                                  fontSize: "20px",
                                  background: `linear-gradient(6deg, ${FREE_COLORS[0]} 0%, ${FREE_COLORS[1]} 100%)`,
                                }}
                                block
                              >
                                Dashboard
                              </Button>
                            </Link>
                          )
                        ) : null
                      }
                    />
                  </Col>
                  <Col span={12}>
                    <Badge.Ribbon
                      text={
                        auth.user.db.is_beta ||
                        process.env.REACT_APP_ENABLE_DEDICATED === "true"
                          ? "Beta"
                          : "Coming Soon"
                      }
                      color="magenta"
                    >
                      <Spin spinning={productsLoading || pricesLoading}>
                        <Plan
                          type={SERVICE_LEVEL.DEDICATED}
                          icon={
                            <CloudServerOutlined
                              style={{ fontSize: 30, color: "#ffffff" }}
                            />
                          }
                          title={
                            <>
                              <label style={{ color: "#999999" }}>
                                For Dedicated Workloads
                              </label>
                              <h2 style={{ lineHeight: "24px" }}>
                                Kinetica Cloud
                              </h2>
                            </>
                          }
                          colors={DEDICATED_COLORS}
                          description={
                            <>
                              For production use-cases with demanding workloads.{" "}
                            </>
                          }
                          pricing={
                            <div style={{ height: 70 }}>
                              {startingDedicatedPricing > 0 && (
                                <>
                                  <span
                                    style={{ fontSize: 14, display: "block" }}
                                  >
                                    Starting at
                                  </span>
                                  <span
                                    style={{
                                      fontSize: 46,
                                      lineHeight: "46px",
                                    }}
                                  >
                                    {formatter.format(startingDedicatedPricing)}
                                  </span>{" "}
                                  /hour
                                </>
                              )}
                            </div>
                          }
                          included={[
                            "No data limits",
                            "Dedicated compute resources",
                            "Full Kinetica database functionality",
                            "Dashboarding with Reveal",
                            "Basic Support",
                          ]}
                          action={
                            <Link
                              to="/dedicated"
                              onClick={() => {
                                // Segment
                                segment.track("get_started_dedicated");
                              }}
                            >
                              {auth.user.db.is_beta ||
                              process.env.REACT_APP_ENABLE_DEDICATED ===
                                "true" ? (
                                <Button
                                  type="primary"
                                  size="large"
                                  style={{
                                    borderColor: `${DEDICATED_COLORS[0]}66`,
                                    borderRadius: "10px",
                                    height: "60px",
                                    fontSize: "20px",
                                    background: `linear-gradient(6deg, ${DEDICATED_COLORS[0]} 0%, ${DEDICATED_COLORS[1]} 100%)`,
                                  }}
                                  block
                                >
                                  Get Started
                                </Button>
                              ) : (
                                <Button
                                  type="primary"
                                  size="large"
                                  style={{
                                    borderColor: `#dddddd66`,
                                    borderRadius: "10px",
                                    height: "60px",
                                    fontSize: "20px",
                                    background: `linear-gradient(6deg, #dddddd 0%, #eeeeee 100%)`,
                                  }}
                                  disabled
                                  block
                                >
                                  Get Started
                                </Button>
                              )}
                            </Link>
                          }
                        />
                      </Spin>
                    </Badge.Ribbon>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </div>
      </Wizard>
    )
  );
};

export default Plans;
