import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Button, Tooltip, Spin, Space } from "antd";
import { useHistory } from "react-router-dom";
import {
  GoogleLoginButton,
  GithubLoginButton,
} from "react-social-login-buttons";

import { useAuth } from "../../hooks/useAuth";
import firebase, {
  googleProvider,
  githubProvider,
} from "../../services/firebase";
import {
  handleError,
  checkPasswordStrength,
  sleep,
  validateEmail,
} from "../../helpers";
import useSegment from "../../hooks/useSegment";

const PROCESSING_TIMEOUT = 500;

const SignupForm = ({ setActiveTabKey }) => {
  const auth = useAuth();
  const history = useHistory();
  const fullNameRef = useRef(null);
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [isSocialLoggingIn, setIsSocialLoggingIn] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(false);

  const segment = useSegment(auth);

  const [form] = Form.useForm();

  useEffect(() => {
    fullNameRef.current.focus();
  }, []);

  const onFinish = async (values) => {
    const { full_name, email, password, confirm_password } = values;

    if (password !== confirm_password) {
      form.setFields([
        {
          name: "confirm_password",
          errors: ["Confirm password must match password"],
        },
      ]);
      return;
    }

    const strength = checkPasswordStrength(password);
    if (strength.value !== "strong") {
      form.setFields([
        {
          name: "password",
          errors: [
            "Must contain 8 characters, 1 uppercase, 1 lowercase, and 1 number.",
          ],
        },
      ]);
      return;
    }

    try {
      setIsSigningUp(true);

      validateEmail(email);

      await auth.signup(email.toLowerCase(), password);
      await auth.updateUserProfile({
        displayName: full_name ?? email.toLowerCase(),
      });
      await auth.sendEmailVerification();

      await auth.createUser({
        email_addr: email.toLowerCase(),
        display_name: full_name ?? email.toLowerCase(),
      });

      await sleep(PROCESSING_TIMEOUT);
      history.go(0);
    } catch (error) {
      handleError(error);
      setIsSigningUp(false);
    }
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    // console.log("onFinishFailed", values, errorFields, outOfDate);
  };

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues?.password) {
      if (changedValues.password !== "") {
        const strength = checkPasswordStrength(changedValues.password);
        setPasswordStrength(strength);
      } else {
        setPasswordStrength(false);
      }
    }
  };

  const handleGoogleLogin = async () => {
    try {
      setIsSocialLoggingIn(true);

      const result = await firebase.auth().signInWithPopup(googleProvider);
      const { name, email, picture } = result?.additionalUserInfo?.profile;

      validateEmail(email);

      const existingUser = await auth.getUserByEmail(email);

      // Segment
      segment.track("console_login");

      if (existingUser) {
        await auth.updateUser(existingUser.id, {
          display_name: name ?? email.toLowerCase(),
          photo_url: picture,
        });
      } else {
        // Create user
        await auth.createUser({
          email_addr: email.toLowerCase(),
          display_name: name ?? email.toLowerCase(),
          photo_url: picture,
        });

        // Send welcome email
        await auth.sendWelcomeEmail();

        await sleep(PROCESSING_TIMEOUT);
        history.go(0);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsSocialLoggingIn(false);
    }
  };

  const handleGithubLogin = async () => {
    try {
      setIsSocialLoggingIn(true);

      const result = await firebase.auth().signInWithPopup(githubProvider);
      const {
        name,
        email,
        avatar_url: picture,
      } = result?.additionalUserInfo?.profile;

      if (!email) {
        handleError(
          "GitHub user email is required but unavailable. Please make sure email is public."
        );
        return;
      }

      validateEmail(email);

      const existingUser = await auth.getUserByEmail(email);

      // Segment
      segment.track("console_login");

      if (existingUser) {
        await auth.updateUser(existingUser.id, {
          display_name: name ?? email.toLowerCase(),
          photo_url: picture,
        });
      } else {
        // Create user
        await auth.createUser({
          email_addr: email.toLowerCase(),
          display_name: name ?? email.toLowerCase(),
          photo_url: picture,
        });

        // Send welcome email
        await auth.sendWelcomeEmail();

        await sleep(PROCESSING_TIMEOUT);
        history.go(0);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsSocialLoggingIn(false);
    }
  };

  const toggleLogin = (_) => {
    setActiveTabKey("login");
  };

  return (
    <Spin spinning={isSocialLoggingIn} size="large">
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
      >
        <Form.Item
          name="full_name"
          rules={[{ required: true, message: "Please enter your full name!" }]}
        >
          <Input
            ref={(ref) => {
              fullNameRef.current = ref;
            }}
            placeholder="Full Name"
            size="large"
            style={{ borderRadius: "5px", padding: "10px 15px" }}
          />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              type: "email",
              required: true,
              message: "Please enter your email address!",
            },
          ]}
        >
          <Input
            placeholder="E-Mail"
            size="large"
            style={{ borderRadius: "5px", padding: "10px 15px" }}
          />
        </Form.Item>
        <Tooltip
          placement="right"
          title="Must contain 8 characters, 1 uppercase, 1 lowercase, and 1 number."
        >
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please enter your password!" }]}
            validateStatus={passwordStrength?.status}
            hasFeedback
          >
            <Input
              type="password"
              placeholder="Password"
              size="large"
              style={{ borderRadius: "5px", padding: "10px 15px" }}
            />
          </Form.Item>
        </Tooltip>
        <Form.Item
          name="confirm_password"
          rules={[{ required: true, message: "Please confirm your password!" }]}
        >
          <Input
            type="password"
            placeholder="Confirm Password"
            size="large"
            style={{ borderRadius: "5px", padding: "10px 15px" }}
          />
        </Form.Item>
        <div className="button-login">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={isSigningUp}
            style={{
              border: 0,
              color: "#ffffff",
              borderRadius: "5px",
              overflow: "hidden",
              zIndex: "1",
              backgroundColor: "transparent",
            }}
            block
          >
            {isSigningUp ? "Signing Up..." : "Sign Up"}
            <div className="button-login-fill"></div>
          </Button>
        </div>
      </Form>
      <Space direction="vertical" style={{ width: "100%" }}>
        <div style={{ textAlign: "center", padding: "10px" }}>
          <Button type="link" onClick={toggleLogin} size="small">
            Already have an account? Login Here
          </Button>
        </div>
        <GoogleLoginButton
          onClick={handleGoogleLogin}
          align="center"
          style={{
            height: "40px",
            borderRadius: "5px",
            margin: "5px 0px",
            width: "100%",
            fontSize: "16px",
            fontWeight: 500,
            border: "1px solid #eeeeee",
          }}
        />
        <GithubLoginButton
          onClick={handleGithubLogin}
          align="center"
          style={{
            height: "40px",
            borderRadius: "5px",
            margin: "5px 0px",
            width: "100%",
            fontSize: "16px",
            fontWeight: 500,
          }}
        />
      </Space>
    </Spin>
  );
};

export default SignupForm;
