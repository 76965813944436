import { CheckCircleFilled } from "@ant-design/icons";

const Plan = ({
  icon,
  colors,
  title,
  description,
  override,
  pricing,
  included,
  action,
}) => {
  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        boxShadow:
          "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
        borderRadius: 20,
        padding: 30,
        height: 565,
      }}
    >
      <div>
        <div
          style={{
            height: 50,
            width: 50,
            padding: 10,
            borderRadius: 10,
            marginRight: 20,
            float: "left",
            background: `linear-gradient(6deg, ${colors[0]} 0%, ${colors[1]} 100%)`,
          }}
        >
          {icon}
        </div>
        {title}
      </div>
      <div style={{ margin: "20px 0px 10px", color: "#999999" }}>
        {description}
      </div>
      {override ? (
        <>
          <div style={{ height: 318 }}>{override}</div>
        </>
      ) : (
        <>
          <div style={{ fontSize: "16px", marginBottom: "10px" }}>
            {pricing}
          </div>
          <div style={{ marginBottom: "30px", height: 210 }}>
            <strong>What's included:</strong>
            {
              <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
                {included.map((include, idx) => (
                  <li
                    key={idx}
                    style={{ margin: "3px 0px 3px 2px", padding: 0 }}
                  >
                    <CheckCircleFilled
                      style={{
                        position: "absolute",
                        margin: "4px 2px 4px 2px",
                        fontSize: 16,
                        color: `${colors[0]}`,
                      }}
                    />
                    <div
                      style={{
                        display: "inline-block",
                        marginLeft: "25px",
                        width: "calc(100% - 30px)",
                      }}
                    >
                      {include}
                    </div>
                  </li>
                ))}
              </ul>
            }
          </div>
        </>
      )}
      {action}
    </div>
  );
};

export default Plan;
