import React, { useEffect } from "react";
import { Route, Redirect, useLocation, useHistory } from "react-router-dom";

import { useAuth } from "../../hooks/useAuth";
import { STORAGE_KEY_REDIRECT } from "../../constants";
import ProfileUpdateModal from "../Account/ProfileUpdateModal";

const DESTINATION_RESTRICTIONS = ["/", "/login", "/dashboard"];

const PrivateRoute = ({ children, ...rest }) => {
  const auth = useAuth();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const existing = sessionStorage.getItem(STORAGE_KEY_REDIRECT);
    if (!auth.user && !DESTINATION_RESTRICTIONS.includes(location.pathname)) {
      sessionStorage.setItem(STORAGE_KEY_REDIRECT, location.pathname);
    } else if (auth.user && auth.user.emailVerified && existing) {
      sessionStorage.removeItem(STORAGE_KEY_REDIRECT);
      history.push(existing);
    }
  }, [auth.user, location, history]);

  return (
    <Route
      {...rest}
      render={() => (
        <>
          <ProfileUpdateModal
            showIfMissing={["company_name", "company_contact_me"]}
          />
          {auth.user ? children : <Redirect to="/login" />}
        </>
      )}
    ></Route>
  );
};

export default PrivateRoute;
