import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Input, Row, Col, Tabs, notification } from "antd";
import { useLocation, useHistory } from "react-router-dom";

import { useAuth } from "../../hooks/useAuth";
import { handleError, checkPasswordStrength } from "../../helpers";
import KineticaLogo from "../../images/logo.svg";

const { TabPane } = Tabs;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const EmailWebhook = () => {
  const query = useQuery();
  const auth = useAuth();
  const history = useHistory();

  const [loaded, setLoaded] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(false);

  const [form] = Form.useForm();

  const mode = query.get("mode");
  const actionCode = query.get("oobCode");

  useEffect(() => {
    const processRequest = async () => {
      switch (mode) {
        case "verifyEmail": {
          try {
            await auth.verifyEmail(actionCode);

            // Send welcome email
            await auth.sendWelcomeEmail();

            // Reload user
            await auth.reload();

            setSuccess(true);
          } catch (error) {
            // handleError(error);
            setSuccess(true);
          } finally {
            setLoaded(true);
            break;
          }
        }
        default: {
        }
      }
    };
    processRequest();
  });

  const renderHeading = (mode) => {
    switch (mode) {
      case "verifyEmail": {
        return <div style={tabStyle}>E-Mail Verification</div>;
      }
      case "resetPassword": {
        return <div style={tabStyle}>Password Reset</div>;
      }
      default: {
        return <div style={tabStyle}>Unknown Transaction</div>;
      }
    }
  };

  const handleContinue = () => {
    history.push("/login?t=l");
  };

  const onFinish = async (values) => {
    const { password, confirm_password } = values;

    if (password !== confirm_password) {
      form.setFields([
        {
          name: "confirm_password",
          errors: ["Confirm password must match password"],
        },
      ]);
      return;
    }

    const strength = checkPasswordStrength(password);
    if (strength.value !== "strong") {
      form.setFields([
        {
          name: "password",
          errors: [
            "Must contain 8 characters, 1 uppercase, 1 lowercase, and 1 number.",
          ],
        },
      ]);
      return;
    }

    try {
      setIsUpdatingPassword(true);
      await auth.confirmPasswordReset(actionCode, password);
      await auth.logout();
      notification.info({
        message: "Password Reset",
        description:
          "Password updated successfully. Please log in with your new password.",
      });
      history.push("/");
    } catch (error) {
      handleError(error);
      setIsUpdatingPassword(false);
    }
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    // console.log("onFinishFailed", values, errorFields, outOfDate);
  };

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues?.password) {
      if (changedValues.password !== "") {
        const strength = checkPasswordStrength(changedValues.password);
        setPasswordStrength(strength);
      } else {
        setPasswordStrength(false);
      }
    }
  };

  const tabStyle = { width: "125px", textAlign: "center" };

  return (
    <Row style={{ top: "calc(50% - 340px)", position: "relative" }}>
      <Col xs={3} sm={5} md={6} lg={7} xl={8}></Col>
      <Col xs={18} sm={14} md={12} lg={10} xl={8}>
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <img
            src={KineticaLogo}
            alt="Kinetica Logo"
            style={{ height: "40px", position: "relative", top: "-3px" }}
          />
          <h1
            style={{
              color: "#8A68C9",
              fontSize: "34px",
              fontWeight: "200",
            }}
          >
            {process.env.REACT_APP_SITE_TITLE}
          </h1>
        </div>
        <div
          className="password-reset"
          style={{
            padding: "10px 60px 30px",
            backgroundColor: "#ffffff",
            borderRadius: "20px",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
            maxWidth: 400,
            margin: "auto",
          }}
        >
          <Tabs defaultActiveKey="reset" size="large" tabBarGutter={0} centered>
            <TabPane tab={renderHeading(mode)} key="reset">
              <div style={{ padding: "10px 0px", height: "360px" }}>
                {mode === "verifyEmail" && loaded && success && (
                  <Alert
                    message="Success"
                    description="Your e-mail has been verified!"
                    type="success"
                  />
                )}
                {mode === "resetPassword" && (
                  <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    onValuesChange={onValuesChange}
                  >
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your new password!",
                        },
                      ]}
                      validateStatus={passwordStrength?.status}
                      hasFeedback
                    >
                      <Input
                        type="password"
                        placeholder="New Password"
                        size="large"
                        style={{ borderRadius: "5px", padding: "10px 15px" }}
                      />
                    </Form.Item>
                    <Form.Item
                      name="confirm_password"
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your new password!",
                        },
                      ]}
                    >
                      <Input
                        type="password"
                        placeholder="Confirm Password"
                        size="large"
                        style={{ borderRadius: "5px", padding: "10px 15px" }}
                      />
                    </Form.Item>
                    <div className="button-login">
                      <Button
                        type="default"
                        htmlType="submit"
                        loading={isUpdatingPassword}
                        size="large"
                        style={{
                          border: 0,
                          color: "#ffffff",
                          borderRadius: "5px",
                          overflow: "hidden",
                          zIndex: "1",
                          backgroundColor: "transparent",
                        }}
                        block
                      >
                        {isUpdatingPassword ? "Resetting..." : "Reset Password"}
                        <div className="button-login-fill"></div>
                      </Button>
                    </div>
                  </Form>
                )}
                {loaded && !success && (
                  <Alert
                    message="Error"
                    description="Unable to complete transaction"
                    type="error"
                  />
                )}
                {loaded && (
                  <div className="button-login" style={{ marginTop: "20px" }}>
                    <Button
                      type="primary"
                      onClick={handleContinue}
                      size="large"
                      style={{
                        border: 0,
                        color: "#ffffff",
                        borderRadius: "5px",
                        overflow: "hidden",
                        zIndex: "1",
                        backgroundColor: "transparent",
                      }}
                      block
                    >
                      Continue
                      <div className="button-login-fill"></div>
                    </Button>
                  </div>
                )}
              </div>
            </TabPane>
          </Tabs>
        </div>
      </Col>
      <Col xs={3} sm={5} md={6} lg={7} xl={8}></Col>
    </Row>
  );
};

export default EmailWebhook;
