import { useState, useMemo, useEffect } from "react";
import {
  Button,
  Empty,
  Space,
  Table,
  Modal,
  Input,
  Tag,
  notification,
  Tooltip,
} from "antd";
import axios from "axios";
import {
  DeleteOutlined,
  InfoOutlined,
  CaretRightOutlined,
  SearchOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import { format } from "date-fns";

import { useAuth } from "../../hooks/useAuth";
import firebase from "../../services/firebase";
import { handleError, uppercaseFirst } from "../../helpers";

const { Column } = Table;

const API_KEY = "2e148ac7-89c1-4c51-a422-b12881a009fa";

const ClusterHistoryModal = ({
  cluster,
  users,
  assignments,
  width = 750,
  height = 600,
  visible,
  close,
}) => {
  const auth = useAuth();
  const db = firebase.firestore();

  const [searchTerm, setSearchTerm] = useState("");
  const [completing, setCompleting] = useState(false);
  const [resetting, setResetting] = useState(false);

  const [actions, setActions] = useState([]);

  useEffect(() => {
    const unsubscribe = db
      .collection("actions")
      .where("cluster_id", "==", cluster.id)
      .orderBy("created", "desc")
      .limit(100)
      .onSnapshot((snapshot) => {
        const actions = [];
        snapshot.forEach((doc) => {
          actions.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setActions(actions);
      });
    return () => unsubscribe();
  }, [db, auth, cluster]);

  const handleReset = (action) => async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/actions/${action.id}`,
        {
          claimed: false,
        },
        {
          headers: {
            "x-api-key": API_KEY,
          },
        }
      );
      notification.success({
        message: "Action Reset",
      });
    } catch (error) {
      handleError(error);
    } finally {
      setResetting(false);
    }
  };

  const handleComplete = (action) => () => {
    const provision_cluster = async () => {
      try {
        await axios.put(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/actions/${action.id}/complete`,
          {
            action: {
              log: "Database provisioned",
              status: "complete",
            },
            cluster: {
              workbench_url: `https://${cluster.name}.saas.kinetica.com/workbench`,
              hostname: `${cluster.name}.saas.kinetica.com`,
              postgres: `postgresql://${cluster.name}.saas.kinetica.com:5432`,
              table_monitor: `tcp://${cluster.name}.saas.kinetica.com:9002`,
              status: "online",
            },
          },
          {
            headers: {
              "x-api-key": API_KEY,
            },
          }
        );
        notification.success({
          message: "Action Completed",
        });
      } catch (error) {
        handleError(error);
      } finally {
        setCompleting(false);
      }
    };

    const deprovision_cluster = async () => {
      try {
        await axios.put(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/actions/${action.id}/complete`,
          {
            action: {
              log: "Database deprovisioned",
              status: "complete",
            },
          },
          {
            headers: {
              "x-api-key": API_KEY,
            },
          }
        );
        notification.success({
          message: "Action Completed",
        });
      } catch (error) {
        handleError(error);
      } finally {
        setCompleting(false);
      }
    };

    const provision_user = async () => {
      try {
        await axios.put(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/actions/${action.id}/complete`,
          {
            action: {
              log: "User provisioned",
              status: "complete",
            },
            assignment: {
              status: "complete",
            },
          },
          {
            headers: {
              "x-api-key": API_KEY,
            },
          }
        );
        notification.success({
          message: "Action Completed",
        });
      } catch (error) {
        handleError(error);
      } finally {
        setCompleting(false);
      }
    };

    const deprovision_user = async () => {
      try {
        await axios.put(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/actions/${action.id}/complete`,
          {
            action: {
              log: "User deprovisioned",
              status: "complete",
            },
          },
          {
            headers: {
              "x-api-key": API_KEY,
            },
          }
        );
        notification.success({
          message: "Action Completed",
        });
      } catch (error) {
        handleError(error);
      } finally {
        setCompleting(false);
      }
    };

    const upgrade_cluster = async () => {
      try {
        await axios.put(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/actions/${action.id}/complete`,
          {
            action: {
              log: "Upgrade vCluster success",
              status: "complete",
            },
            cluster: {
              upgrade_info: null,
            },
          },
          {
            headers: {
              "x-api-key": API_KEY,
            },
          }
        );
        notification.success({
          message: "Action Completed",
        });
      } catch (error) {
        handleError(error);
      } finally {
        setCompleting(false);
      }
    };

    const { type } = action;
    if (type === "provision_cluster") {
      provision_cluster();
    } else if (type === "deprovision_cluster") {
      deprovision_cluster();
    } else if (type === "provision_user") {
      provision_user();
    } else if (type === "deprovision_user") {
      deprovision_user();
    } else if (type === "upgrade_cluster") {
      upgrade_cluster();
    }
  };

  const handleDeleteAction = (action) => async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/actions/${action.id}`,
        {
          headers: {
            "x-api-key": API_KEY,
          },
        }
      );
      notification.success({
        message: "Action Delete",
        description: "Action has been deleted.",
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleActionInfo = (action) => () => {
    Modal.info({
      content: (
        <pre style={{ fontSize: "11px" }}>
          {JSON.stringify(action, null, 2)}
        </pre>
      ),
      centered: true,
      width: 700,
    });
  };

  const onSearch = (evt) => {
    setSearchTerm(evt.target.value);
  };

  const filteredActions = useMemo(() => {
    return actions.filter((action) => {
      if (
        searchTerm.trim() === "" ||
        (searchTerm.trim() !== "" && searchTerm.trim().length <= 2)
      ) {
        return true;
      }

      const {
        id,
        type,
        created,
        updated,
        completed,
        claimed,
        status,
        cluster_id,
        user_id,
        assignment_id,
        ...rest
      } = action;

      const user = users.find((user) => user.id === user_id);
      const assignment = assignments.find(
        (assignment) => assignment.id === assignment_id
      );

      const detail = {
        ...rest,
        cluster: cluster && {
          cluster_name: cluster.cluster_name,
          status: cluster.status,
        },
        user: user && {
          display_name: user.display_name ?? "",
          email_addr: user.email_addr.toLowerCase(),
        },
        assignment: assignment && {
          admin_username: assignment.admin_username,
          status: assignment.status,
        },
      };

      return (
        type
          .split("_")
          .join(" ")
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        JSON.stringify(detail).toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }, [actions, users, assignments, cluster, searchTerm]);

  const toMinutes = (seconds) => {
    return Math.round((seconds * 100) / 60) / 100;
  };

  const statusStyle = {
    width: "80px",
    textAlign: "center",
    marginBottom: "1px",
    marginLeft: "8px",
  };

  return (
    <Modal
      title={`Cluster History: ${cluster.cluster_name}`}
      onCancel={close}
      onOk={close}
      closable={false}
      visible={visible}
      footer={[
        <Button key="ok" type="primary" onClick={close}>
          OK
        </Button>,
      ]}
      width={width}
      height={height}
      centered
      destroyOnClose
    >
      <div
        style={{
          height: height - 200,
          marginBottom: "20px",
          textAlign: "center",
        }}
      >
        {actions.length > 0 ? (
          <div style={{ marginBottom: "20px" }}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Input
                value={searchTerm}
                onChange={onSearch}
                addonAfter={<SearchOutlined />}
                placeholder="Enter search term"
                size="large"
              />
              <div
                style={{
                  height: height - 230,
                  overflowY: "auto",
                }}
              >
                <Table
                  dataSource={filteredActions}
                  rowKey="id"
                  size="small"
                  pagination={false}
                >
                  <Column
                    title="Date"
                    dataIndex="created"
                    key="created"
                    width={1}
                    render={(created) => {
                      return (
                        <div
                          style={{
                            fontSize: "11px",
                            lineHeight: "12px",
                            width: 55,
                          }}
                        >
                          {format(new Date(created.seconds * 1000), "MM/dd/yy")}
                          <br />
                          {format(
                            new Date(created.seconds * 1000),
                            "hh:mm aaa"
                          )}
                        </div>
                      );
                    }}
                  />
                  <Column
                    title="Type"
                    dataIndex="type"
                    key="type"
                    width={80}
                    render={(type) => {
                      return (
                        <div
                          style={{
                            fontSize: "11px",
                            lineHeight: "12px",
                          }}
                        >
                          {type.split("_").join(" ")}
                        </div>
                      );
                    }}
                  />
                  <Column
                    title="Detail"
                    dataIndex="id"
                    key="id"
                    render={(_, record) => {
                      const {
                        id,
                        type,
                        created,
                        updated,
                        completed,
                        claimed,
                        status,
                        cluster_id,
                        user_id,
                        assignment_id,
                        ...rest
                      } = record;

                      const user = users.find((user) => user.id === user_id);
                      const assignment = assignments.find(
                        (assignment) => assignment.id === assignment_id
                      );

                      const detail = {
                        ...rest,
                        cluster: cluster && {
                          cluster_name: cluster.cluster_name,
                          status: cluster.status,
                        },
                        user: user && {
                          display_name: user.display_name ?? "",
                          email_addr: user.email_addr.toLowerCase(),
                        },
                        assignment: assignment && {
                          admin_username: assignment.admin_username,
                          status: assignment.status,
                        },
                      };
                      return (
                        <pre
                          style={{
                            fontSize: "10px",
                            lineHeight: "11px",
                            maxWidth: "270px",
                            overflowX: "scroll",
                            overflowY: "hidden",
                          }}
                        >
                          {JSON.stringify(detail, null, 2)}
                        </pre>
                      );
                    }}
                  />
                  <Column
                    title="Status"
                    dataIndex="status"
                    key="status"
                    width={110}
                    render={(status, record) => {
                      const { created, completed } = record;

                      const seconds =
                        created && completed
                          ? Math.round((completed - created) * 100) / 100
                          : null;

                      return (
                        <>
                          {record.claimed ? (
                            <Tag color="#87d068" style={statusStyle}>
                              Claimed
                            </Tag>
                          ) : (
                            <Tag color="#ff5500" style={statusStyle}>
                              Unclaimed
                            </Tag>
                          )}
                          <br />
                          {status === "complete" ? (
                            <Tag color="#87d068" style={statusStyle}>
                              {uppercaseFirst(status)}
                            </Tag>
                          ) : (
                            <Tag color="#ff5500" style={statusStyle}>
                              {status ? uppercaseFirst(status) : "Unknown"}
                            </Tag>
                          )}
                          {seconds !== null ? (
                            <Tag color="#cccccc" style={statusStyle}>
                              {seconds < 60
                                ? `${seconds} s`
                                : `${toMinutes(seconds)} m`}
                            </Tag>
                          ) : null}
                        </>
                      );
                    }}
                  />
                  <Column
                    title=""
                    dataIndex="id"
                    key="id"
                    width={85}
                    render={(id, record) => (
                      <Space direction="vertical" style={{ float: "right" }}>
                        <Space style={{ float: "right" }}>
                          <Button
                            onClick={handleActionInfo(record)}
                            size="small"
                          >
                            <InfoOutlined />
                          </Button>
                          <Tooltip title="Reset claim">
                            <Button
                              onClick={handleReset(record)}
                              loading={resetting}
                              disabled={resetting || !record.claimed}
                              size="small"
                            >
                              <RetweetOutlined />
                            </Button>
                          </Tooltip>
                        </Space>
                        <Space style={{ float: "right" }}>
                          <Tooltip title="Set complete">
                            <Button
                              onClick={handleComplete(record)}
                              loading={completing}
                              disabled={
                                completing || record.status === "complete"
                              }
                              size="small"
                            >
                              <CaretRightOutlined />
                            </Button>
                          </Tooltip>
                          <Button
                            onClick={handleDeleteAction(record)}
                            size="small"
                          >
                            <DeleteOutlined />
                          </Button>
                        </Space>
                      </Space>
                    )}
                  />
                </Table>
              </div>
            </Space>
          </div>
        ) : (
          <Empty
            description="No Actions Found"
            style={{ marginTop: "40px" }}
          ></Empty>
        )}
      </div>
    </Modal>
  );
};

export default ClusterHistoryModal;
