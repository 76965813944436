import React, { useCallback, useEffect, useState, useMemo } from "react";
import {
  Alert,
  Button,
  Badge,
  notification,
  Spin,
  Space,
  Empty,
  Tooltip,
  Row,
  Col,
  Card,
  Tag,
} from "antd";
import axios from "axios";
import {
  ReloadOutlined,
  PlusOutlined,
  LoginOutlined,
  SettingOutlined,
  CheckOutlined,
  LoadingOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";

import { useAuth } from "../../hooks/useAuth";
import useSegment from "../../hooks/useSegment";
import firebase from "../../services/firebase";
import { handleError, uppercaseFirst } from "../../helpers";
import WorkbenchScreenshot from "../../images/workbench_screenshot.png";
import Wallpaper from "../../images/abstract_background.png";
import {
  ADMIN_LAYOUT,
  SERVICE_LEVEL,
  DEDICATED_SIZE_COLORS,
} from "../../constants";
import FreeSharedImage from "../../images/cloud_database_free_shared.png";
import DedicatedImage from "../../images/cloud_database_dedicated.png";
import DedicatedPromoImage from "../../images/cloud_database_dedicated_promo.png";

const { Ribbon } = Badge;
const { Meta } = Card;

const Spinner = (
  <LoadingOutlined
    style={{
      fontSize: 48,
      margin: -24,
    }}
    spin
  />
);

const SpinnerSmall = (
  <LoadingOutlined
    style={{
      fontSize: 32,
      margin: -16,
    }}
    spin
  />
);

const SIZE_LABELS = {
  XS: "Extra Small",
  S: "Small",
  M: "Medium",
  L: "Large",
};

const SIZE_COLORS = {
  XS: "xsmall",
  S: "small",
  M: "medium",
  L: "large",
};

const COLOR = {
  BLUE: "#4E90FF",
  PURPLE: "#722ED1",
  GREEN: "#66BB6A",
};

function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

const Dashboard = ({ clusters: passedClusters = null }) => {
  const auth = useAuth();
  const history = useHistory();
  const segment = useSegment(auth);
  const db = firebase.firestore();

  const [clustersLoading, setClustersLoading] = useState(false);
  const [clusters, setClusters] = useState(passedClusters);
  const [actions, setActions] = useState([]);

  const getClusters = useCallback(() => {
    const fetch = async () => {
      try {
        setClustersLoading(true);
        const token = await auth.getIdToken();
        const resp = await axios.get(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/clusters`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const clusters = resp?.data?.clusters.filter(
          (cluster) => Object.keys(cluster).length > 1
        );

        setClusters(clusters);
      } catch (error) {
        handleError(error);
        setClusters([]);
      } finally {
        setClustersLoading(false);
      }
    };
    fetch();
  }, [auth]);

  const getActions = useCallback(() => {
    const fetch = async () => {
      try {
        const token = await auth.getIdToken();
        const resp = await axios.get(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/actions`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const actions = resp?.data?.actions.filter(
          (action) => Object.keys(action).length > 1
        );

        setActions(actions);
      } catch (error) {
        handleError(error);
        setActions([]);
      }
    };
    fetch();
  }, [auth]);

  const refresh = useCallback(() => {
    getClusters();
    getActions();
  }, [getActions, getClusters]);

  const debouncedRefresh = useMemo(() => {
    return debounce(refresh, 400);
  }, [refresh]);

  useEffect(() => {
    const unsubscribe = db
      .collection("dashboards")
      .doc(auth.user.db.id)
      .onSnapshot((snapshot) => {
        getClusters();
        getActions();
      });
    return () => unsubscribe();
  }, [db, auth, getClusters, getActions]);

  const handleResend = async () => {
    try {
      await auth.sendEmailVerification();
      notification.info({
        message: "E-Mail Verification",
        description:
          "A new verification e-mail has been sent. Please check and click the verification link to confirm your account.",
        duration: 0,
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleRefreshClick = () => {
    debouncedRefresh();
  };

  const getRibbonText = (cluster) => {
    if (cluster.service_level === SERVICE_LEVEL.FREE_SHARED) {
      return "Free";
    } else if (cluster.service_level === SERVICE_LEVEL.DEDICATED) {
      return "Dedicated";
    }
    return cluster.service_level;
  };

  const getRibbonColor = (cluster) => {
    if (cluster.service_level === SERVICE_LEVEL.FREE_SHARED) {
      return COLOR.BLUE;
    }
    return COLOR.PURPLE;
  };

  const getBorderColor = (cluster) => {
    return `${getRibbonColor(cluster)}33`;
  };

  const getBackground = (cluster) => {
    const color = DEDICATED_SIZE_COLORS[SIZE_COLORS[cluster.cluster_size]];
    return `linear-gradient(#ffffff 40%, ${color}11 70%, ${color}22 100%)`;
  };

  const getCloudImage = (cluster) => {
    if (cluster.service_level === SERVICE_LEVEL.FREE_SHARED) {
      return FreeSharedImage;
    }
    return DedicatedImage;
  };

  const handleLoginClick = (cluster, username) => () => {
    // Segment
    segment.track("workbench_login");

    window.open(
      `${cluster.workbench_url}/workbench/login?username=${username}`
    );
  };

  const handleManageClick = (cluster) => () => {
    history.push(`/cluster/${cluster.id}`);
  };

  const renderClusterProcessing = (_) => {
    return (
      <>
        <Spin
          wrapperClassName="render-processing"
          indicator={SpinnerSmall}
          spinning={true}
        >
          <div
            style={{
              height: 55,
              color: "#8A68C9",
              backgroundColor: "#ffffff",
              fontSize: "14px",
              lineHeight: "14px",
              padding: "10px 10px 0px",
              borderRadius: "5px 5px 0 0",
            }}
          >
            Cluster provisioning may take 10 to 15 minutes.
          </div>
        </Spin>
        <div
          style={{
            height: 40,
            color: "#ffffff",
            backgroundColor: "#8A68C9",
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "14px",
            padding: "5px 10px 10px",
            borderRadius: "0 0 5px 5px",
          }}
        >
          You will receive an e-mail when it is ready.
        </div>
      </>
    );
  };

  const renderProcessing = (message) => {
    return (
      <Spin
        wrapperClassName="render-processing"
        indicator={SpinnerSmall}
        spinning={true}
      >
        <div
          style={{
            height: 95,
            color: "#8A68C9",
            backgroundColor: "#ffffff",
            fontSize: "14px",
            lineHeight: "14px",
            padding: "10px",
            borderRadius: 5,
          }}
        >
          {message}
        </div>
      </Spin>
    );
  };

  const getInstanceType = (type) => {
    if (type.includes("CPU")) {
      return "cpu";
    } else if (type.includes("GPU")) {
      return "gpu";
    }
    return "";
  };

  const formatReason = (reason) => {
    return uppercaseFirst(reason.replace(/provision/i, "provisioning"));
  };

  const handleTryDedicatedClick = (_) => {
    history.push(`/dedicated`);
  };

  const showDedicatedPromo = useMemo(
    (_) => {
      return !clusters.some(
        (cluster) => cluster.service_level === SERVICE_LEVEL.DEDICATED
      );
    },
    [clusters]
  );

  const fillerCount = useMemo(
    (_) => {
      if (showDedicatedPromo) {
        return 2 - (clusters.length % 3);
      }
      return 3 - (clusters.length % 3);
    },
    [clusters, showDedicatedPromo]
  );

  return clusters !== null ? (
    <>
      <h1>Dashboard</h1>
      <div style={{ maxWidth: ADMIN_LAYOUT.MAXWIDTH }}>
        {!auth.user.emailVerified ? (
          <>
            <div style={{ height: "5px" }}></div>
            <h2 style={{ fontWeight: 300, marginBottom: "20px" }}>
              Verification
            </h2>
            <Alert
              message="Warning"
              description={
                <>
                  Your account{" "}
                  <strong>{auth.user.db.email_addr.toLowerCase()}</strong>{" "}
                  requires e-mail verification. You will receive an e-mail
                  within a few minutes with a link to verify your account. If
                  not, you can{" "}
                  <Button
                    type="link"
                    onClick={handleResend}
                    size="small"
                    style={{ padding: "0px" }}
                  >
                    click here to resend
                  </Button>
                  .
                </>
              }
              type="warning"
            />
          </>
        ) : (
          <>
            <div style={{ height: "5px" }}></div>
            <div style={{ float: "right", marginTop: "5px" }}>
              <Space>
                <Link to="/plans">
                  <Button icon={<PlusOutlined />} size="small">
                    New Database
                  </Button>
                </Link>
                <Button
                  icon={<ReloadOutlined />}
                  onClick={handleRefreshClick}
                  loading={clustersLoading}
                  size="small"
                >
                  Refresh
                </Button>
              </Space>
            </div>
            <h2 style={{ fontWeight: 300, marginBottom: "20px" }}>Databases</h2>
            <div
              style={{
                padding: "50px 40px 0px 40px",
                backgroundImage: `url(${Wallpaper})`,
                backgroundPositionY: "top",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                WebkitBackgroundSize: "cover",
                MozBackgroundSize: "cover",
                OBackgroundSize: "cover",
                marginBottom: 40,
                display: "none",
              }}
            >
              <Row gutter={50}>
                <Col span={11}>
                  <h1
                    style={{
                      fontSize: 42,
                      lineHeight: "36px",
                      fontWeight: 600,
                      color: COLOR.PURPLE,
                    }}
                  >
                    Workbench
                  </h1>
                  <h3>
                    A rich easy-to-use interface with many powerful data
                    analytics features
                  </h3>
                  <div
                    style={{
                      height: 160,
                      overflow: "hidden",
                      borderRadius: "5px 5px 0px 0px",
                      border: "1px solid #826fdf22",
                      backgroundColor: "#ffffff",
                      padding: "10px 10px 0px 10px",
                      marginTop: 20,
                    }}
                  >
                    <div style={{ height: 220, overflow: "hidden" }}>
                      <img
                        src={WorkbenchScreenshot}
                        alt="Workbench Screenshot"
                        style={{
                          width: "100%",
                        }}
                      />
                    </div>
                  </div>
                </Col>
                <Col span={13}>
                  <div
                    style={{ backgroundColor: "#ffffff", padding: "20px 30px" }}
                  >
                    <ul
                      style={{
                        listStyle: "none",
                        margin: "0px 0px 20px 0px",
                        padding: 0,
                      }}
                    >
                      {[
                        "Interactively explore data",
                        "Organize and store SQL workbooks",
                        "Import and export data streams",
                        "Sophisticated, yet intuitive interface",
                      ].map((item) => (
                        <li
                          key={item}
                          style={{
                            margin: "4px 0px",
                            display: "inline-block",
                          }}
                        >
                          <CheckOutlined style={{ color: COLOR.PURPLE }} />
                          <span
                            style={{
                              marginLeft: 4,
                              display: "block",
                              float: "right",
                            }}
                          >
                            {item}
                          </span>
                        </li>
                      ))}
                    </ul>
                    <Link to="/plans">
                      <Button
                        icon={<PlusOutlined />}
                        type="primary"
                        size="large"
                        block
                      >
                        Create New Database
                      </Button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
            {clusters.length > 0 ? (
              <Row gutter={30}>
                {clusters
                  .sort((a, b) => {
                    if (a.service_level < b.service_level) return -1;
                    if (a.service_level > b.service_level) return 1;
                    if (a.cluster_label < b.cluster_label) return -1;
                    if (a.cluster_label > b.cluster_label) return 1;
                    return 0;
                  })
                  .map((cluster) => {
                    const {
                      service_level,
                      cluster_size,
                      cluster_type,
                      assignments,
                    } = cluster;
                    const assignment = assignments.find(
                      (assignment) => assignment.cluster_id === cluster.id
                    );
                    const clusterActions = actions.filter(
                      (action) =>
                        (action.cluster_id === cluster.id &&
                          action.user_id === auth.user.db.id) ||
                        (action.cluster_id === cluster.id &&
                          action.user_id === null)
                    );

                    const pendingActions = clusterActions.filter(
                      (action) => action.status === "pending"
                    );

                    const processing =
                      pendingActions.length > 0
                        ? {
                            status: true,
                            reason: pendingActions[0].type
                              .split("_")
                              .map((_, idx, list) => {
                                if (idx === list.length - 1) return list[0];
                                return list[idx + 1];
                              })
                              .join(" "),
                          }
                        : {
                            status: false,
                          };
                    const upgradeInfo = cluster.upgrade_info ?? null;

                    const clusterProcessing =
                      cluster.status.toLowerCase() === "pending";
                    const otherProcessing =
                      processing.status ||
                      (!clustersLoading && assignment?.status !== "complete");

                    const hasError =
                      (!otherProcessing &&
                        assignment?.status.toLowerCase() !== "complete") ||
                      (!clusterProcessing &&
                        cluster?.status.toLowerCase() !== "online");

                    return (
                      <Col key={cluster.id} span={8}>
                        <Ribbon
                          text={
                            upgradeInfo &&
                            upgradeInfo.upgradeAvailable &&
                            service_level !== SERVICE_LEVEL.FREE_SHARED
                              ? "Upgrade Available"
                              : getRibbonText(cluster)
                          }
                          color={
                            upgradeInfo &&
                            upgradeInfo.upgradeAvailable &&
                            service_level !== SERVICE_LEVEL.FREE_SHARED
                              ? COLOR.GREEN
                              : getRibbonColor(cluster)
                          }
                        >
                          <Card
                            className="cluster-card"
                            cover={
                              <img
                                alt="example"
                                src={getCloudImage(cluster)}
                                style={{
                                  marginTop: 30,
                                  padding: 1,
                                }}
                              />
                            }
                            actions={[
                              cluster.status.toLowerCase() === "online" ? (
                                <div
                                  key="manage"
                                  onClick={handleManageClick(cluster)}
                                  style={{
                                    backgroundColor: "#ffffff99",
                                    padding: "10px",
                                    margin: "0 5px",
                                    borderRadius: 5,
                                    cursor: "pointer",
                                  }}
                                >
                                  <SettingOutlined /> Manage
                                </div>
                              ) : (
                                <Tooltip
                                  title={`Status: ${
                                    cluster.status.toLowerCase() || "unknown"
                                  }`}
                                >
                                  <div
                                    key="manage"
                                    style={{
                                      color: "#66666633",
                                      cursor: "not-allowed",
                                    }}
                                  >
                                    <SettingOutlined /> Manage
                                  </div>
                                </Tooltip>
                              ),
                            ]}
                            style={{
                              marginBottom: 30,
                              borderRadius: 5,
                              backgroundImage: getBackground(cluster),
                              borderColor: getBorderColor(cluster),
                              cursor: "default",
                              height: 338,
                            }}
                            bodyStyle={{ padding: "10px 20px 20px" }}
                            bordered
                            hoverable
                          >
                            <Meta
                              title={
                                service_level === SERVICE_LEVEL.FREE_SHARED
                                  ? "Cloud Free"
                                  : cluster.cluster_label || "Unknown"
                              }
                              description={
                                clusterProcessing ? (
                                  renderClusterProcessing()
                                ) : false && hasError ? (
                                  <div
                                    style={{
                                      height: 95,
                                      color: "red",
                                      backgroundColor: "#ffffff",
                                      fontSize: "14px",
                                      lineHeight: "14px",
                                      padding: "10px",
                                      borderRadius: 5,
                                    }}
                                  >
                                    Provisioning error has occurred. Please
                                    contact support at{" "}
                                    <a href="mailto:success@kinetica.com">
                                      success@kinetica.com
                                    </a>{" "}
                                    for further assistance.
                                  </div>
                                ) : otherProcessing ? (
                                  renderProcessing(
                                    `${
                                      processing.reason
                                        ? formatReason(processing.reason)
                                        : "Provisioning"
                                    } may take a few moments.`
                                  )
                                ) : (
                                  <div>
                                    {cluster.status !== "online" && (
                                      <Tag
                                        color={
                                          cluster.status.toLowerCase() ===
                                          "online"
                                            ? "green"
                                            : "red"
                                        }
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {cluster.status || "unknown"}
                                      </Tag>
                                    )}
                                    <Tag>{SIZE_LABELS[cluster_size]}</Tag>
                                    <Tag>
                                      {getInstanceType(
                                        cluster_type
                                      ).toUpperCase()}
                                    </Tag>
                                    <div
                                      className="cluster"
                                      style={{ marginTop: 32 }}
                                    >
                                      <Tooltip
                                        title={
                                          <div style={{ padding: "5px 10px" }}>
                                            DB User:{" "}
                                            <strong>
                                              {assignment?.admin_username}
                                            </strong>
                                          </div>
                                        }
                                        color="magenta"
                                      >
                                        <div className={`button-login`}>
                                          <Button
                                            type="primary"
                                            htmlType="submit"
                                            size="large"
                                            onClick={handleLoginClick(
                                              cluster,
                                              assignment?.admin_username
                                            )}
                                            disabled={
                                              assignment?.status !== "complete"
                                            }
                                            style={{
                                              border: 0,
                                              color: "#ffffff",
                                              borderRadius: "5px",
                                              overflow: "hidden",
                                              zIndex: "1",
                                              backgroundColor: "transparent",
                                            }}
                                            block
                                          >
                                            <LoginOutlined /> Login
                                            <div className="button-login-fill"></div>
                                          </Button>
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                )
                              }
                            />
                          </Card>
                        </Ribbon>
                      </Col>
                    );
                  })}
                {showDedicatedPromo && (
                  <Col key={`filler_dedicated`} span={8}>
                    <Ribbon text="New!" color="#9470EE">
                      <div
                        className="animated-gradient"
                        style={{
                          height: 338,
                          marginBottom: 30,
                          borderRadius: 5,
                          textAlign: "center",
                        }}
                      >
                        <div style={{ padding: "15px 20px" }}>
                          <h1
                            style={{
                              margin: 0,
                              color: "#f9f9f9",
                              fontSize: "22px",
                              lineHeight: "29px",
                              textShadow: "1px 1px 1px #00000033",
                            }}
                          >
                            Kinetica Cloud
                          </h1>
                          <h4
                            style={{
                              margin: 0,
                              color: "#f9f9f9cc",
                              fontSize: "12px",
                              fontWeight: 300,
                            }}
                          >
                            For Dedicated Workloads
                          </h4>
                        </div>
                        <img
                          src={DedicatedPromoImage}
                          style={{ maxHeight: 122, width: "100%" }}
                          alt="Dedicated Promo"
                        />
                        <div
                          style={{
                            height: 100,
                            padding: "0px",
                            textAlign: "center",
                          }}
                        >
                          <Tag
                            style={{
                              color: "#ffffff",
                              backgroundColor: "#00000011",
                              border: 0,
                              margin: 3,
                            }}
                          >
                            Starting at $1.80/hr
                          </Tag>
                          <Tag
                            style={{
                              color: "#ffffff",
                              backgroundColor: "#00000011",
                              border: 0,
                              margin: 3,
                            }}
                          >
                            No data limits
                          </Tag>
                          <Tag
                            style={{
                              color: "#ffffff",
                              backgroundColor: "#00000011",
                              border: 0,
                              margin: 3,
                            }}
                          >
                            Dedicated compute resources
                          </Tag>
                          <Tag
                            style={{
                              color: "#ffffff",
                              backgroundColor: "#00000011",
                              border: 0,
                              margin: 3,
                            }}
                          >
                            Full database functionality
                          </Tag>
                        </div>
                        <div
                          key="manage"
                          onClick={handleTryDedicatedClick}
                          style={{
                            color: "#ffffff",
                            backgroundColor: "#00000022",
                            padding: "10px",
                            margin: "0 5px",
                            borderRadius: 5,
                            cursor: "pointer",
                            textAlign: "center",
                            fontWeight: 300,
                            position: "absolute",
                            width: "calc(100% - 10px)",
                            bottom: 5,
                          }}
                        >
                          Get Started <ArrowRightOutlined />
                        </div>
                      </div>
                    </Ribbon>
                  </Col>
                )}
                {Array.from(Array(fillerCount).keys()).map((key) => (
                  <Col key={`filler_${key}`} span={8}>
                    <div
                      style={{
                        height: 338,
                        marginBottom: 30,
                        borderRadius: 5,
                        backgroundColor: "#f8f8f8",
                      }}
                    ></div>
                  </Col>
                ))}
              </Row>
            ) : (
              <Empty
                description="No Clusters Found"
                style={{ marginTop: "40px" }}
              ></Empty>
            )}
          </>
        )}
      </div>
    </>
  ) : (
    <div
      style={{
        maxWidth: ADMIN_LAYOUT.MAXWIDTH,
        padding: "200px",
        textAlign: "center",
      }}
    >
      <Spin indicator={Spinner} size="large" />
    </div>
  );
};

export default Dashboard;
