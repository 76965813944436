import React, { useState, useEffect } from "react";
import { Badge, Spin } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import axios from "axios";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Link } from "react-router-dom";

import Wizard from "./Wizard";
import WizardLayout from "../Layout/Wizard";
import { useAuth } from "../../hooks/useAuth";
import { handleError } from "../../helpers";
import Header from "../Header/Header";
import KineticaLogo from "../../images/logo.svg";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Dedicated = () => {
  const auth = useAuth();

  const [customer, setCustomer] = useState(null);
  const [customerLoading, setCustomerLoading] = useState(false);

  const [products, setProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(false);

  const [prices, setPrices] = useState([]);
  const [pricesLoading, setPricesLoading] = useState(false);

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        setCustomerLoading(true);
        const token = await auth.getIdToken();
        const resp = await axios.post(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/customers`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const { customer } = resp.data;
        setCustomer(customer);
      } catch (error) {
        handleError(error);
        setCustomer(null);
      } finally {
        setCustomerLoading(false);
      }
    };
    const fetchProducts = async () => {
      try {
        setProductsLoading(true);
        const token = await auth.getIdToken();
        const resp = await axios.get(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/products`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const { products } = resp.data;
        setProducts(products);
      } catch (error) {
        handleError(error);
        setProducts([]);
      } finally {
        setProductsLoading(false);
      }
    };
    const fetchPrices = async () => {
      try {
        setPricesLoading(true);
        const token = await auth.getIdToken();
        const resp = await axios.get(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/prices`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const { prices } = resp.data;
        setPrices(prices);
      } catch (error) {
        handleError(error);
        setPrices([]);
      } finally {
        setPricesLoading(false);
      }
    };
    fetchCustomer();
    fetchProducts();
    fetchPrices();
  }, [auth]);

  return (
    <WizardLayout>
      <div style={{ textAlign: "center", marginBottom: "0px" }}>
        <img
          src={KineticaLogo}
          alt="Kinetica Logo"
          style={{ height: "40px", position: "relative" }}
        />
        <h1
          style={{
            color: "#8A68C9",
            fontSize: "34px",
            fontWeight: "200",
          }}
        >
          Dedicated
        </h1>
      </div>
      <div
        className="dedicated"
        style={{
          padding: "20px 40px 30px",
          borderRadius: "20px",
          minHeight: 680,
          width: 800,
          margin: "auto",
        }}
      >
        <Header />
        <h2 style={{ fontWeight: 300, marginBottom: "15px" }}>
          <Link to="/plans">
            <LeftOutlined style={{ marginRight: 10 }} />
          </Link>
          Create New Database
        </h2>
        <Badge.Ribbon text="Beta" color="magenta">
          <div
            style={{
              backgroundColor: "#ffffff",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
              borderRadius: 20,
              padding: "30px 50px 50px",
              height: 565,
            }}
          >
            <Spin
              spinning={customerLoading || productsLoading || pricesLoading}
              style={{ height: 565 }}
              size="large"
            >
              {customer && products.length > 0 && prices.length > 0 && (
                <Elements stripe={stripePromise}>
                  <Wizard
                    customer={customer}
                    products={products
                      .map((product) => {
                        return {
                          ...product,
                          price: prices.find(
                            (price) => price.product === product.id
                          ),
                        };
                      })
                      .sort((a, b) => {
                        if (a.price.unit_amount > b.price.unit_amount) return 1;
                        if (a.price.unit_amount < b.price.unit_amount)
                          return -1;
                        return 0;
                      })}
                  />
                </Elements>
              )}
            </Spin>
          </div>
        </Badge.Ribbon>
      </div>
    </WizardLayout>
  );
};

export default Dedicated;
