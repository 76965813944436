import React, { useState, useRef } from "react";
import { Modal, Form, Input, Select, Row, Col, notification } from "antd";
import { RedoOutlined } from "@ant-design/icons";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import { handleError } from "../../helpers";
import { SERVICE_LEVEL } from "../../constants";

const API_KEY = "2e148ac7-89c1-4c51-a422-b12881a009fa";

const providers = [
  {
    label: "Azure",
    value: "azure",
  },
  {
    label: "AWS",
    value: "aws",
  },
];

const service_levels = [
  {
    label: "Free Shared",
    value: SERVICE_LEVEL.FREE_SHARED,
  },
  {
    label: "Dedicated",
    value: SERVICE_LEVEL.DEDICATED,
  },
];

const ClusterCreateModal = ({
  host_clusters,
  initialValues,
  visible,
  close,
}) => {
  const [isCreatingIn, setIsCreating] = useState(false);

  const nameRef = useRef(null);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    if (!values.cluster_name) {
      return;
    }

    try {
      setIsCreating(true);
      await axios.post(
        `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/clusters`,
        values,
        {
          headers: {
            "x-api-key": API_KEY,
          },
        }
      );
      notification.success({
        message: "Cluster Create",
        description: "Your new cluster is being provisioned.",
      });
      setIsCreating(false);
      close();
    } catch (error) {
      setIsCreating(false);
      handleError(error);
    }
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    // console.log("onFinishFailed", values, errorFields, outOfDate);
  };

  const handleCancel = () => {
    close();
  };

  const handleCreate = () => {
    form.submit();
  };

  const generateName = () => {
    form.setFieldsValue({
      cluster_name: `cluster-${uuidv4().split("-")[0]}`,
    });
  };

  return (
    <Modal
      title="Create Cluster"
      okText="Create"
      onOk={handleCreate}
      onCancel={handleCancel}
      confirmLoading={isCreatingIn}
      width={600}
      closable={false}
      visible={visible}
      centered
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          cluster_name: `cluster-${uuidv4().split("-")[0]}`,
          cluster_size: "XS",
          cluster_type: "SmallCPU",
          cloud_provider: "aws",
          cloud_region: "aws_useast_1",
          service_level: SERVICE_LEVEL.FREE_SHARED,
          host_cluster_id: host_clusters.length > 0 ? host_clusters[0].id : "",
          ...initialValues,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item label="Name" name="cluster_name">
          <Input
            ref={(ref) => {
              nameRef.current = ref;
            }}
            size="large"
            addonAfter={<RedoOutlined onClick={generateName} />}
          />
        </Form.Item>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item label="Size" name="cluster_size">
              <Input size="large" disabled={initialValues.cluster_size} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Type" name="cluster_type">
              <Input size="large" disabled={initialValues.cluster_type} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item label="Provider" name="cloud_provider">
              <Select options={providers} size="large" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Region" name="cloud_region">
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Service Level" name="service_level">
          <Select
            options={service_levels}
            size="large"
            disabled={initialValues.service_level}
          />
        </Form.Item>
        <Form.Item label="Host Cluster" name="host_cluster_id">
          <Select
            options={host_clusters.map((host) => {
              return {
                value: host.id,
                label: `${host.name} | ${host.type} | ${host.id}`,
              };
            })}
            size="large"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ClusterCreateModal;
