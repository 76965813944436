import { useState, useEffect, useMemo } from "react";
import {
  Divider,
  Spin,
  Typography,
  Descriptions,
  Popconfirm,
  Form,
  Radio,
  Space,
  Row,
  Col,
  Table,
  Button,
  Collapse,
  notification,
  Tooltip,
  Modal,
} from "antd";
import {
  DeleteOutlined,
  DollarCircleFilled,
  DollarCircleOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { format } from "date-fns";

import { useAuth } from "../../hooks/useAuth";
import useSegment from "../../hooks/useSegment";
import {
  handleError,
  updateInvoiceLineItemDesc,
  uppercaseFirst,
} from "../../helpers";
import AmexCard from "../../images/cards/amex.svg";
import VisaCard from "../../images/cards/visa.svg";
import MastercardCard from "../../images/cards/mastercard.svg";
import DiscoverCard from "../../images/cards/discover.svg";
import StripeLogo from "../../images/cards/stripe.svg";
import MeteringBillingInfo from "./MeteringBillingInfo";

const { Paragraph } = Typography;
const { Panel } = Collapse;
const { Column } = Table;
const { confirm } = Modal;

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const getCardImage = (brand) => {
  switch (brand) {
    case "amex": {
      return AmexCard;
    }
    case "visa": {
      return VisaCard;
    }
    case "mastercard": {
      return MastercardCard;
    }
    case "discover": {
      return DiscoverCard;
    }
    default: {
      return null;
    }
  }
};

const BillingPane = () => {
  const auth = useAuth();
  const segment = useSegment(auth);
  const stripe = useStripe();
  const elements = useElements();
  const [form] = Form.useForm();

  const [customer, setCustomer] = useState(null);
  const [customerLoading, setCustomerLoading] = useState(false);

  const [balanceTransactions, setBalanceTransactions] = useState(null);
  const [balanceTransactionsLoading, setBalanceTransactionsLoading] =
    useState(false);

  const [paymentMethods, setPaymentMethods] = useState(null);
  const [paymentMethodsLoading, setPaymentMethodsLoading] = useState(false);

  const [upcomingInvoice, setUpcomingInvoice] = useState(null);
  const [upcomingInvoiceLoading, setUpcomingInvoiceLoading] = useState(false);

  const [invoices, setInvoices] = useState(null);
  const [invoicesLoading, setInvoicesLoading] = useState(false);

  const [enableAddCard, setEnableAddCard] = useState(false);
  const [paymentMethodsAdding, setPaymentMethodsAdding] = useState(false);

  const [removePaymentMethod, setRemovePaymentmethod] = useState(null);
  const [paymentMethodRemoving, setPaymentMethodRemoving] = useState(false);

  const [preauthAmount, setPreauthAmount] = useState(0);

  useEffect(() => {
    const fetchPreauthAmount = async () => {
      try {
        const token = await auth.getIdToken();
        const resp = await axios.get(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/config/stripe/preauth_amount`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setPreauthAmount(resp.data.preauth_amount);
      } catch (error) {
        handleError(error);
        setPreauthAmount(0);
      }
    };

    const fetchCustomer = async () => {
      try {
        setCustomerLoading(true);
        const token = await auth.getIdToken();
        const resp = await axios.post(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/customers`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const { customer } = resp.data;
        setCustomer(customer);
      } catch (error) {
        handleError(error);
        setCustomer(null);
      } finally {
        setCustomerLoading(false);
      }
    };

    fetchPreauthAmount();
    fetchCustomer();
  }, [auth]);

  useEffect(() => {
    if (customer) {
      const fetchPaymentMethods = async () => {
        try {
          setPaymentMethodsLoading(true);
          const token = await auth.getIdToken();
          const resp = await axios.get(
            `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/payment_methods/customer`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const { payment_methods } = resp.data;
          setPaymentMethods(payment_methods);

          if (customer.invoice_settings.default_payment_method) {
            form.setFieldsValue({
              payment_method_id:
                customer.invoice_settings.default_payment_method,
            });
          }
        } catch (error) {
          handleError(error);
          setPaymentMethods([]);
        } finally {
          setPaymentMethodsLoading(false);
        }
      };
      const fetchBalanceTransactions = async () => {
        try {
          setBalanceTransactionsLoading(true);
          const token = await auth.getIdToken();
          const resp = await axios.get(
            `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/balance_transactions/customer`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const { balance_transactions } = resp.data;
          setBalanceTransactions(balance_transactions);
        } catch (error) {
          handleError(error);
          setBalanceTransactions([]);
        } finally {
          setBalanceTransactionsLoading(false);
        }
      };
      const fetchUpcomingInvoice = async () => {
        try {
          setUpcomingInvoiceLoading(true);
          const token = await auth.getIdToken();
          const resp = await axios.get(
            `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/invoices/customer/upcoming`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const { invoice } = resp.data;
          setUpcomingInvoice(invoice);
        } catch (error) {
          // handleError(error);
          setUpcomingInvoice(null);
        } finally {
          setUpcomingInvoiceLoading(false);
        }
      };
      const fetchInvoices = async () => {
        try {
          setInvoicesLoading(true);
          const token = await auth.getIdToken();
          const resp = await axios.get(
            `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/invoices/customer`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const { invoices } = resp.data;
          setInvoices(invoices.filter((invoice) => invoice.number));
        } catch (error) {
          handleError(error);
          setInvoices([]);
        } finally {
          setInvoicesLoading(false);
        }
      };
      fetchPaymentMethods();
      fetchBalanceTransactions();
      fetchUpcomingInvoice();
      fetchInvoices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, customer?.id]);

  const handleCancelAddPaymentMethod = async () => {
    if (elements.getElement(CardElement)) {
      setEnableAddCard(false);
    }
  };

  const handleRemovePaymentMethod = (paymentMethod) => async () => {
    try {
      setRemovePaymentmethod(paymentMethod);
      setPaymentMethodRemoving(true);
      const token = await auth.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/payment_methods/${paymentMethod.id}/detach`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPaymentMethodsLoading(true);
      const resp = await axios.get(
        `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/payment_methods/customer`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { payment_methods } = resp.data;
      setPaymentMethods(payment_methods);
    } catch (error) {
      handleError(error);
    } finally {
      setPaymentMethodRemoving(false);
      setRemovePaymentmethod(null);
      setPaymentMethodsLoading(false);
    }
  };

  const addPaymentMethod = async (_) => {
    setPaymentMethodsAdding(true);
    const token = await auth.getIdToken();
    let new_payment_method = null;

    try {
      // Segment
      segment.track("add_payment_method");

      const resp = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });

      if (resp.error) {
        handleError(resp.error.message);
      } else {
        // Reject prepaid cards
        if (["prepaid"].includes(resp.paymentMethod?.card?.funding)) {
          throw new Error(
            "Invalid payment method funding type: " +
              resp.paymentMethod?.card?.funding
          );
        }

        const resp2 = await axios.post(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/payment_methods/${resp.paymentMethod.id}/attach`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        new_payment_method = resp2.data.payment_method;

        if (preauthAmount > 0) {
          // Pre-authorize payment method
          const preauthResp = await axios.post(
            `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/payment_methods/${new_payment_method.id}/preauth`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          new_payment_method = preauthResp.data.payment_method;
        }

        const customerResp = await axios.put(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/customers`,
          {
            address: {
              postal_code:
                new_payment_method.billing_details.address.postal_code,
              country: new_payment_method.card.country,
            },
            invoice_settings: {
              default_payment_method: new_payment_method.id,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const { customer } = customerResp.data;
        setCustomer(customer);

        const fetchPaymentMethods = async () => {
          try {
            setPaymentMethodsLoading(true);
            const token = await auth.getIdToken();
            const resp = await axios.get(
              `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/payment_methods/customer`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            const { payment_methods } = resp.data;
            setPaymentMethods(payment_methods);
            if (payment_methods.length === 0) {
              setEnableAddCard(true);
            }
          } catch (error) {
            handleError(error);
            setPaymentMethods([]);
            setEnableAddCard(true);
          } finally {
            setPaymentMethodsLoading(false);
          }
        };
        await fetchPaymentMethods();

        setEnableAddCard(false);

        form.setFieldsValue({ payment_method_id: new_payment_method.id });
      }
    } catch (error) {
      if (new_payment_method) {
        await axios.post(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/payment_methods/${new_payment_method.id}/detach`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
      handleError(error);
    } finally {
      setPaymentMethodsAdding(false);
    }
  };

  const handleAddPaymentMethod = async () => {
    if (elements.getElement(CardElement)) {
      if (preauthAmount > 0) {
        confirm({
          title: "Verification",
          icon: <ExclamationCircleOutlined />,
          content: `A hold authorization of $${(
            Math.round(preauthAmount) / 100
          ).toFixed(
            2
          )} is required to verify this payment method. The hold will be released within the next few business days. Click 'I Agree' if you would like to proceed.`,
          okText: "I Agree",
          onOk: addPaymentMethod,
          centered: true,
        });
      } else {
        addPaymentMethod();
      }
    }
  };

  const renderPaymentMethods = (paymentMethods) => {
    return (
      paymentMethods &&
      paymentMethods.length > 0 && (
        <Form.Item name="payment_method_id">
          <Radio.Group style={{ width: "100%" }}>
            <Space direction="vertical" style={{ width: "100%" }}>
              {paymentMethods.map((method) => {
                const { id, card } = method;
                const selected = customer.invoice_settings
                  .default_payment_method
                  ? customer.invoice_settings.default_payment_method === id
                  : false;
                return (
                  <Radio
                    key={id}
                    value={id}
                    style={{
                      padding: "5px 15px",
                      width: "100%",
                      backgroundColor: selected ? "#ac92d611" : undefined,
                      borderRadius: 8,
                    }}
                  >
                    <img
                      src={getCardImage(card.brand)}
                      style={{ height: 24, marginRight: 5 }}
                      alt={`${card.brand} Card`}
                    />{" "}
                    {uppercaseFirst(card.brand)} ending in {card.last4} Exp{" "}
                    {card.exp_month}/{card.exp_year}{" "}
                    {method?.metadata?.preauth_id ? (
                      <Tooltip title="Verified" placement="right">
                        <CheckCircleOutlined style={{ color: "green" }} />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Unverified" placement="right">
                        <CheckCircleOutlined style={{ color: "#cccccc" }} />
                      </Tooltip>
                    )}
                    {!selected ? (
                      <Popconfirm
                        title="Are you sure you want to delete this card?"
                        onConfirm={handleRemovePaymentMethod(method)}
                        onCancel={(_) => {}}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          type="text"
                          icon={<DeleteOutlined />}
                          style={{
                            borderRadius: 10,
                            margin: "0px",
                            top: "-2px",
                            display: "inline-block",
                          }}
                          loading={
                            paymentMethodRemoving &&
                            removePaymentMethod.id === method.id
                          }
                          size="small"
                          danger
                        ></Button>
                      </Popconfirm>
                    ) : (
                      <Button
                        type="text"
                        icon={<DeleteOutlined />}
                        style={{
                          borderRadius: 10,
                          margin: "0px",
                          top: "-2px",
                          display: "inline-block",
                        }}
                        loading={
                          paymentMethodRemoving &&
                          removePaymentMethod.id === method.id
                        }
                        disabled
                        size="small"
                        danger
                      ></Button>
                    )}
                  </Radio>
                );
              })}
            </Space>
          </Radio.Group>
        </Form.Item>
      )
    );
  };

  const onFinish = async (values) => {};

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    // console.log("onFinishFailed", values, errorFields, outOfDate);
  };

  const onValuesChange = async (changedValues, allValues) => {
    if (changedValues?.payment_method_id) {
      try {
        const token = await auth.getIdToken();

        const pmResp = await axios.get(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/payment_methods/${changedValues?.payment_method_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const { payment_method } = pmResp.data;

        await axios.put(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/customers`,
          {
            address: {
              postal_code: payment_method.billing_details.address.postal_code,
              country: payment_method.card.country,
            },
            invoice_settings: {
              default_payment_method: payment_method.id,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const resp = await axios.post(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/customers`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const { customer } = resp.data;
        setCustomer(customer);
      } catch (error) {
        handleError(error);
        setCustomer(null);
      }

      notification.success({
        message: "Default global payment method updated",
      });
    }
  };

  const formatter = useMemo(() => {
    let currency = "USD";
    if (upcomingInvoice) {
      currency = upcomingInvoice.currency.toUpperCase();
    }
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency,
    });
  }, [upcomingInvoice]);

  const handleMeteringBillingInfo = () => {
    Modal.info({
      icon: null,
      content: <MeteringBillingInfo />,
      centered: true,
      width: 800,
    });
  };

  return (
    <div style={{ padding: "0 20px" }}>
      <Button
        type="primary"
        size="small"
        icon={<InfoCircleOutlined />}
        onClick={handleMeteringBillingInfo}
        style={{ borderRadius: 10, marginTop: 5, float: "right" }}
        ghost
      >
        How We Bill
      </Button>
      <h2 style={{ fontWeight: 300, marginBottom: "15px" }}>Billing</h2>
      <Form
        form={form}
        initialValues={{}}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
      >
        <Spin spinning={customerLoading} size="large" />
        {customer && !customerLoading && (
          <>
            <h3>Estimated Due</h3>
            <p>
              This is an estimate of the amount you owe based on your current
              billing period usage after credits & prepayments.
            </p>
            <Spin
              spinning={upcomingInvoiceLoading || balanceTransactionsLoading}
              size="large"
            />
            {!upcomingInvoice && !upcomingInvoiceLoading && (
              <>
                <h1>{formatter.format(0 / 100)}</h1>
                <Descriptions
                  layout="vertical"
                  column={4}
                  size="small"
                  bordered
                >
                  <Descriptions.Item
                    label="Balance"
                    style={{ textAlign: "right" }}
                  >
                    {balanceTransactions && balanceTransactions.length > 0
                      ? formatter.format(
                          balanceTransactions[0].ending_balance / 100
                        )
                      : formatter.format(0)}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Payment Due"
                    style={{ textAlign: "right" }}
                  >
                    N/A
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Prepayments"
                    style={{ textAlign: "right" }}
                  >
                    N/A
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Total Usage"
                    style={{ textAlign: "right" }}
                  >
                    {formatter.format(0 / 100)}
                  </Descriptions.Item>
                </Descriptions>
              </>
            )}
            {upcomingInvoice && !upcomingInvoiceLoading && (
              <>
                <h1>{formatter.format(upcomingInvoice.amount_due / 100)}</h1>
                <Descriptions
                  layout="vertical"
                  column={4}
                  size="small"
                  bordered
                >
                  <Descriptions.Item
                    label="Balance"
                    style={{ textAlign: "right" }}
                  >
                    {balanceTransactions && balanceTransactions.length > 0
                      ? formatter.format(
                          balanceTransactions[0].ending_balance / 100
                        )
                      : formatter.format(0)}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Payment Due"
                    style={{ textAlign: "right" }}
                  >
                    {format(
                      new Date(upcomingInvoice.next_payment_attempt * 1000),
                      "MM/dd/yy"
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Prepayments"
                    style={{ textAlign: "right" }}
                  >
                    {formatter.format(
                      upcomingInvoice.pre_payment_credit_notes_amount / 100
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item
                    label="Total Usage"
                    style={{ textAlign: "right" }}
                  >
                    {formatter.format(upcomingInvoice.total / 100)}
                  </Descriptions.Item>
                </Descriptions>
                <br />
                <Collapse ghost>
                  <Panel
                    header={
                      <>
                        Usage Detail for{" "}
                        {format(
                          new Date(upcomingInvoice.period_start * 1000),
                          "MM/dd/yy"
                        )}{" "}
                        -{" "}
                        {format(
                          new Date(upcomingInvoice.period_end * 1000),
                          "MM/dd/yy"
                        )}
                      </>
                    }
                    key="1"
                  >
                    <Descriptions
                      layout="horizontal"
                      column={1}
                      size="small"
                      bordered
                    >
                      {upcomingInvoice.lines.data
                        .sort((a, b) => {
                          if (a.plan.amount > b.plan.amount) return -1;
                          if (a.plan.amount < b.plan.amount) return 1;
                          return 0;
                        })
                        .map((item) => {
                          return (
                            <Descriptions.Item
                              key={item.id}
                              label={updateInvoiceLineItemDesc(
                                item.description
                              )}
                              style={{ textAlign: "right" }}
                            >
                              <strong>
                                {formatter.format(item.amount / 100)}
                              </strong>
                            </Descriptions.Item>
                          );
                        })}
                    </Descriptions>
                  </Panel>
                </Collapse>
              </>
            )}
            <Divider dashed />
            <Button
              type="primary"
              size="small"
              onClick={() => {
                setEnableAddCard(true);
              }}
              style={{ borderRadius: 10, float: "right" }}
              ghost
            >
              Add Payment Method
            </Button>
            <h3>Payment Methods</h3>
            <Paragraph type="secondary" style={{ marginBottom: "20px" }}>
              Add and/or select a default global payment method for all your
              invoices.
            </Paragraph>
            <Spin spinning={paymentMethodsLoading} size="large" />
            {paymentMethods &&
              !paymentMethodsLoading &&
              renderPaymentMethods(paymentMethods)}
            {enableAddCard && (
              <Row gutter={20}>
                <Col span={18}>
                  <Form.Item name="new_payment_method">
                    <div
                      style={{
                        padding: "10px 15px",
                        backgroundColor: "#ac92d611",
                        borderRadius: 8,
                      }}
                    >
                      <CardElement options={CARD_ELEMENT_OPTIONS} />
                    </div>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Space>
                    <Button
                      type="primary"
                      onClick={handleAddPaymentMethod}
                      loading={paymentMethodsAdding}
                      style={{
                        borderRadius: 10,
                        width: "100%",
                        margin: "4px 0",
                      }}
                      ghost
                    >
                      Add Card
                    </Button>
                    <Button
                      onClick={handleCancelAddPaymentMethod}
                      style={{
                        borderRadius: 10,
                        width: "100%",
                        margin: "4px 0",
                      }}
                      danger
                      ghost
                    >
                      X
                    </Button>
                  </Space>
                </Col>
              </Row>
            )}
            <div
              style={{
                textAlign: "center",
                padding: "10px",
                fontSize: "12px",
                color: "#cccccc",
              }}
            >
              Powered by{" "}
              <img src={StripeLogo} style={{ height: 28 }} alt={`Stripe`} />
            </div>
            <Divider dashed />
            <h3>Billing History</h3>
            <Spin spinning={invoicesLoading} size="large" />
            {invoices && !invoicesLoading && (
              <Table dataSource={invoices} rowKey="id" size="small">
                <Column
                  title="Invoice"
                  dataIndex="number"
                  key="number"
                  render={(text, record, index) => {
                    return (
                      <a
                        href={record.hosted_invoice_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {record.number}
                      </a>
                    );
                  }}
                />
                <Column
                  title="Date"
                  dataIndex="id"
                  key="id"
                  render={(text, record, index) => {
                    return `${format(
                      new Date(record.created * 1000),
                      "MM/dd/yy"
                    )}`;
                  }}
                />
                <Column
                  title="Period"
                  dataIndex="period_start"
                  key="period_start"
                  render={(text, record, index) => {
                    return `${format(
                      new Date(record.period_start * 1000),
                      "MM/dd/yy"
                    )} 
                    - 
                    ${format(new Date(record.period_end * 1000), "MM/dd/yy")}`;
                  }}
                />
                <Column
                  title="Paid"
                  dataIndex="paid"
                  align="center"
                  key="paid"
                  render={(text, record, index) => {
                    return record.paid ? (
                      <DollarCircleFilled
                        style={{ fontSize: 20, color: "green" }}
                      />
                    ) : (
                      <DollarCircleOutlined
                        style={{ fontSize: 20, color: "red" }}
                      />
                    );
                  }}
                />
                <Column
                  title="Amount"
                  dataIndex="amount_due"
                  align="right"
                  key="amount_due"
                  render={(text, record, index) => {
                    return formatter.format(record.amount_due / 100);
                  }}
                />
              </Table>
            )}
          </>
        )}
      </Form>
    </div>
  );
};

export default BillingPane;
