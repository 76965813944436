import { Row, Col } from "antd";

const Wizard = (props) => {
  const topStyle = {
    top: "max(calc(50% - 400px), 20px)",
    position: "relative",
  };

  return (
    <Row style={topStyle}>
      <Col xs={2} sm={3} md={3} lg={3} xl={3}></Col>
      <Col xs={20} sm={18} md={18} lg={18} xl={18}>
        {props.children}
      </Col>
      <Col xs={2} sm={3} md={3} lg={3} xl={3}></Col>
    </Row>
  );
};

export default Wizard;
