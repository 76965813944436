import { Table } from "antd";

const COMPUTE_DATA = [
  {
    key: "cpu",
    type: "Standard database (CPU)",
    xs: "$1.80/hour",
    s: "$3.60/hour",
    m: "$7.20/hour",
  },
  {
    key: "gpu",
    type: "GPU Accelerated database (GPU)",
    xs: "$3.25/hour",
    s: "$6.50/hour",
    m: "$13.00/hour",
  },
];

const COMPUTE_COLUMNS = [
  {
    title: "Service",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "XS",
    dataIndex: "xs",
    key: "xs",
  },
  {
    title: "S",
    dataIndex: "s",
    key: "s",
  },
  {
    title: "M",
    dataIndex: "m",
    key: "m",
  },
];

const STORAGE_DATA = [
  {
    key: "storage",
    type: "Kinetica Storage",
    rate: "$0.023 per GB per month (charged in $0.01 units)",
  },
];

const STORAGE_COLUMNS = [
  {
    title: "Service",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Rate",
    dataIndex: "rate",
    key: "rate",
  },
];

const NETWORK_DATA = [
  {
    key: "network",
    type: "Kinetica Data Transfer",
    rate: "$0.09 per GB per month  (charged in $0.01 units)",
  },
];

const NETWORK_COLUMNS = [
  {
    title: "Service",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Rate",
    dataIndex: "rate",
    key: "rate",
  },
];

const MeteringBillingInfo = (_) => {
  return (
    <div>
      <h2 style={{ color: "#32325d" }}>Usage Metering and Billing</h2>
      <h3>
        Your Kinetica Cloud charges for this cluster are based on three
        components:
      </h3>
      <ol style={{ margin: 0, padding: "0 5px 0 20px" }}>
        <li style={{ margin: "5px 0" }}>
          <strong>Kinetica Database Usage</strong>: based on the number of hours
          of database usage and the hourly rate for your database configuration.
          You are only charged for the hours the database is running. The hourly
          rate varies based on the type (Standard or GPU Accelerated) and size
          (XS, S, M) of your database.
          <Table
            dataSource={COMPUTE_DATA}
            columns={COMPUTE_COLUMNS}
            size="small"
            pagination={false}
            style={{ margin: "10px 0" }}
            bordered
          />
        </li>
        <li style={{ margin: "5px 0" }}>
          <strong>Kinetica Storage</strong>: based on the amount of data stored
          in cloud object storage and the storage charges from Kinetica’s cloud
          provider. Cloud object storage is only used once you exceed the disk
          storage for your database.
          <Table
            dataSource={STORAGE_DATA}
            columns={STORAGE_COLUMNS}
            size="small"
            pagination={false}
            style={{ margin: "10px 0" }}
            bordered
          />
        </li>
        <li style={{ margin: "5px 0" }}>
          <strong>Kinetica Data Transfer</strong>: based on the amount of data
          egress from your database and the network charges from Kinetica’s
          cloud provider.
          <Table
            dataSource={NETWORK_DATA}
            columns={NETWORK_COLUMNS}
            size="small"
            pagination={false}
            style={{ margin: "10px 0" }}
            bordered
          />
        </li>
      </ol>
      <h3>Note:</h3>
      <ul style={{ margin: 0, padding: "0 5px 0 20px" }}>
        <li style={{ margin: "5px 0" }}>Prices do not include local taxes.</li>
        <li style={{ margin: "5px 0" }}>
          Database Usage, Cloud storage, and Data Transfer are all metered and
          reported approximately every 24 hours. As a result, the usage
          estimates above may not include the most recent period. Please allow
          at least 24 hours for all usage to be reported.
        </li>
      </ul>
    </div>
  );
};

export default MeteringBillingInfo;
