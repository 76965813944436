import React, { useState, useEffect, useContext, createContext } from "react";
import firebase from "../services/firebase";
import axios from "axios";

import { sleep, validateEmail } from "../helpers";

const PROCESSING_TIMEOUT = 200;

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  const setCurrentUser = async (user) => {
    if (user) {
      try {
        const {
          displayName,
          email,
          emailVerified,
          isAnonymous,
          metadata,
          phoneNumber,
          photoURL,
          providerData,
          uid,
        } = user;
        const token = await user.getIdToken();
        const dbUser = await getCurrentUser();

        if (dbUser) {
          window.analytics.identify(dbUser.id, {
            name: dbUser.display_name,
            email: dbUser.email_addr.toLowerCase(),
            company_name: dbUser?.company_name,
            company_contact_me: dbUser?.company_contact_me,
            company_interest_graph: dbUser?.company_interest_graph,
            company_interest_olap: dbUser?.company_interest_olap,
            company_interest_other: dbUser?.company_interest_other,
            company_interest_perf: dbUser?.company_interest_perf,
            company_interest_real: dbUser?.company_interest_real,
            company_interest_space: dbUser?.company_interest_space,
            company_interest_sqlgpt: dbUser?.company_interest_sqlgpt,
            company_interest_time: dbUser?.company_interest_time,
          });
          if (dbUser.group_id) {
            window.analytics.group(dbUser.group_id);
          }

          setUser({
            displayName,
            email,
            emailVerified,
            isAnonymous,
            metadata,
            phoneNumber,
            photoURL,
            providerData,
            uid,
            token,
            db: dbUser,
          });
        }
      } catch (error) {
        console.error(error.toString());
        setUser(false);
      }
    } else {
      setUser(false);
    }
  };

  const getCurrentUser = async () => {
    const token = await getIdToken();
    const resp = await axios.get(
      `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/users/me`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return resp?.data?.user;
  };

  const getUserByEmail = async (email) => {
    const token = await getIdToken();
    const resp = await axios.get(
      `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/users/email/${email}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return resp?.data?.user;
  };

  const login = async (email, password) => {
    await sleep(PROCESSING_TIMEOUT);
    const response = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    return response.user;
  };

  const reload = async () => {
    await sleep(PROCESSING_TIMEOUT);
    const currentUser = firebase.auth().currentUser;
    await currentUser.reload();
    await setCurrentUser(currentUser);
    return true;
  };

  const signup = async (email, password) => {
    await sleep(PROCESSING_TIMEOUT);
    const response = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    return response.user;
  };

  const updateUserProfile = async (profileUpdate) => {
    await sleep(PROCESSING_TIMEOUT);
    const currentUser = firebase.auth().currentUser;
    await currentUser.updateProfile(profileUpdate);
    await setCurrentUser(currentUser);
    return true;
  };

  const updateUserPassword = async (newPassword) => {
    await sleep(PROCESSING_TIMEOUT);
    const currentUser = firebase.auth().currentUser;
    await currentUser.updatePassword(newPassword);
    await setCurrentUser(currentUser);
    return true;
  };

  const logout = async () => {
    await sleep(PROCESSING_TIMEOUT);
    await firebase.auth().signOut();
    await setCurrentUser(null);
  };

  const sendEmailVerification = async () => {
    await sleep(PROCESSING_TIMEOUT);
    const currentUser = firebase.auth().currentUser;
    await currentUser.sendEmailVerification();
    return true;
  };

  const verifyEmail = async (actionCode) => {
    await sleep(PROCESSING_TIMEOUT);
    await firebase.auth().applyActionCode(actionCode);
    return true;
  };

  const sendPasswordResetEmail = async (email) => {
    await sleep(PROCESSING_TIMEOUT);
    await firebase.auth().sendPasswordResetEmail(email);
    return true;
  };

  const confirmPasswordReset = async (actionCode, password) => {
    await sleep(PROCESSING_TIMEOUT);
    await firebase.auth().confirmPasswordReset(actionCode, password);
    return true;
  };

  const getIdToken = async (force = false) => {
    return await firebase.auth().currentUser.getIdToken(force);
  };

  const createUser = async (user) => {
    const token = await getIdToken();
    await axios.post(
      `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/users`,
      user,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const updateUser = async (id, user) => {
    let token = await getIdToken();

    await axios.post(
      `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/users/claims`,
      {
        idToken: token,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    token = await getIdToken(true);

    await axios.put(
      `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/users/${id}`,
      user,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    await reload();
  };

  const sendWelcomeEmail = async () => {
    const token = await getIdToken();
    await axios.post(
      `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/emails/welcome`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      try {
        if (user) {
          validateEmail(user.email.toLowerCase());
        }
        await setCurrentUser(user);
      } catch (error) {
        console.error(error.toString());
        await setCurrentUser(null);
        await logout();
      } finally {
        setIsAuthenticating(false);
      }
    });
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const values = {
    user,
    createUser,
    updateUser,
    updateUserProfile,
    updateUserPassword,
    isAuthenticating,
    login,
    reload,
    signup,
    logout,
    sendEmailVerification,
    verifyEmail,
    sendPasswordResetEmail,
    confirmPasswordReset,
    getCurrentUser,
    getUserByEmail,
    getIdToken,
    sendWelcomeEmail,
  };

  return (
    <AuthContext.Provider value={values}>
      {!isAuthenticating && children}
    </AuthContext.Provider>
  );
};
