import { useState } from "react";
import { Modal, Form, Input, notification } from "antd";
import axios from "axios";

import { handleError } from "../../helpers";

const API_KEY = "2e148ac7-89c1-4c51-a422-b12881a009fa";

const ClusterDeleteModal = ({
  cluster,
  open = true,
  onCancel = () => {},
  onDelete = () => {},
}) => {
  const deleted = cluster.status === "deleted";

  const [form] = Form.useForm();

  const [deleting, setDeleting] = useState(false);

  const onFinish = async (values) => {
    if (
      !values.confirm_cluster_name ||
      values.confirm_cluster_name !== cluster.cluster_name
    ) {
      form.setFields([
        {
          name: "confirm_cluster_name",
          value: values.confirm_cluster_name,
          errors: ["Database name must match exactly!"],
        },
      ]);
      return;
    }

    try {
      setDeleting(true);

      if (deleted) {
        await axios.delete(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/clusters/${cluster.id}/final`,
          {
            headers: {
              "x-api-key": API_KEY,
            },
          }
        );
        notification.success({
          message: "Cluster Delete Final",
          description: "Cluster has been completely deleted.",
        });
      } else {
        await axios.delete(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/clusters/${cluster.id}`,
          {
            headers: {
              "x-api-key": API_KEY,
            },
          }
        );
        notification.success({
          message: "Cluster Delete",
          description: "Cluster is being deprovisioned.",
        });
      }

      form.resetFields();
      onDelete();
    } catch (error) {
      handleError(error);
    } finally {
      setDeleting(false);
    }
  };

  const handleDelete = () => {
    form.submit();
  };

  const handleCancel = (_) => {
    form.resetFields();
    onCancel();
  };

  return cluster ? (
    <Modal
      title={
        <span style={{ color: "#ff4d4f" }}>
          Delete Cluster {deleted && "Final"}
        </span>
      }
      open={open}
      okText="Confirm"
      okButtonProps={{ danger: true }}
      onOk={handleDelete}
      confirmLoading={deleting}
      onCancel={handleCancel}
      style={{ border: "10px solid #ff4d4f" }}
      destroyOnClose
      centered
    >
      <p>
        This is irreversible. We will delete the cluster and all associated
        backups. All cluster data will be purged and irretrievable.
      </p>
      <p>
        Confirm by entering the name of the cluster below:
        <br />
        <strong style={{ color: "#ff4d4f" }}>{cluster.cluster_name}</strong>
      </p>
      <Form
        form={form}
        layout="vertical"
        initialValues={{}}
        onFinish={onFinish}
      >
        <Form.Item label="Confirm cluster name" name="confirm_cluster_name">
          <Input size="large" />
        </Form.Item>
      </Form>
    </Modal>
  ) : null;
};

export default ClusterDeleteModal;
