import { useCallback, useState, useEffect } from "react";
import { Table, Tag } from "antd";
import axios from "axios";
import { format } from "date-fns";

import { useAuth } from "../../hooks/useAuth";
import firebase from "../../services/firebase";
import { handleError, uppercaseFirst } from "../../helpers";

const { Column } = Table;

const HistoryPane = ({ cluster }) => {
  const auth = useAuth();
  const db = firebase.firestore();

  const [clusterActions, setClusterActions] = useState([]);

  const getActions = useCallback(() => {
    const fetch = async () => {
      try {
        const token = await auth.getIdToken();
        const resp = await axios.get(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/actions/cluster/${cluster.id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const actions = resp?.data?.actions
          .filter((action) => Object.keys(action).length > 1)
          .sort((a, b) => {
            return b.created._seconds - a.created._seconds;
          });

        setClusterActions(actions);
      } catch (error) {
        handleError(error);
        setClusterActions([]);
      }
    };
    fetch();
  }, [auth, cluster.id]);

  useEffect(() => {
    const unsubscribe = db
      .collection("dashboards")
      .doc(auth.user.db.id)
      .onSnapshot((snapshot) => {
        getActions();
      });
    return () => unsubscribe();
  }, [db, auth, getActions]);

  const toMinutes = (seconds) => {
    return Math.round((seconds * 100) / 60) / 100;
  };

  const statusStyle = {
    width: "80px",
    textAlign: "center",
    margin: "0px 4px",
  };

  return (
    <div>
      <Table
        dataSource={clusterActions}
        rowKey="id"
        size="small"
        pagination={false}
      >
        <Column
          title="Date"
          dataIndex="created"
          key="created"
          width={1}
          render={(created) => {
            return (
              <div
                style={{
                  width: 130,
                }}
              >
                {format(
                  new Date(created._seconds * 1000),
                  "MM/dd/yy hh:mm aaa"
                )}
              </div>
            );
          }}
        />
        <Column
          title="Type"
          dataIndex="type"
          key="type"
          render={(type) => {
            return <div>{type.split("_").join(" ")}</div>;
          }}
        />
        <Column
          title="Status"
          dataIndex="status"
          key="status"
          width={200}
          render={(status, record) => {
            const { created, completed } = record;

            const seconds =
              created && completed
                ? Math.round((completed._seconds - created._seconds) * 100) /
                  100
                : null;

            return (
              <>
                {status === "complete" ? (
                  <Tag color="#87d068" style={statusStyle}>
                    {uppercaseFirst(status)}
                  </Tag>
                ) : (
                  <Tag color="#ff5500" style={statusStyle}>
                    {status ? uppercaseFirst(status) : "Unknown"}
                  </Tag>
                )}
                {seconds !== null ? (
                  <Tag color="#cccccc" style={statusStyle}>
                    {seconds < 60 ? `${seconds} s` : `${toMinutes(seconds)} m`}
                  </Tag>
                ) : null}
              </>
            );
          }}
        />
      </Table>
    </div>
  );
};

export default HistoryPane;
