import React, { useState, useEffect, useMemo } from "react";
import {
  Modal,
  Divider,
  Spin,
  Typography,
  Descriptions,
  Form,
  Space,
  Table,
  Tooltip,
  Collapse,
} from "antd";
import axios from "axios";
import { format } from "date-fns";
import {
  DollarCircleFilled,
  DollarCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

import {
  handleError,
  updateInvoiceLineItemDesc,
  uppercaseFirst,
} from "../../helpers";
import AmexCard from "../../images/cards/amex.svg";
import VisaCard from "../../images/cards/visa.svg";
import MastercardCard from "../../images/cards/mastercard.svg";
import DiscoverCard from "../../images/cards/discover.svg";

const { Paragraph } = Typography;
const { Panel } = Collapse;
const { Column } = Table;

const API_KEY = "2e148ac7-89c1-4c51-a422-b12881a009fa";

const getCardImage = (brand) => {
  switch (brand) {
    case "amex": {
      return AmexCard;
    }
    case "visa": {
      return VisaCard;
    }
    case "mastercard": {
      return MastercardCard;
    }
    case "discover": {
      return DiscoverCard;
    }
    default: {
      return null;
    }
  }
};

const UserBillingModal = ({
  user,
  width = 750,
  height = 600,
  visible,
  close,
}) => {
  const [form] = Form.useForm();

  const [customer, setCustomer] = useState(null);
  const [customerLoading, setCustomerLoading] = useState(false);

  const [balanceTransactions, setBalanceTransactions] = useState(null);
  const [balanceTransactionsLoading, setBalanceTransactionsLoading] =
    useState(false);

  const [paymentMethods, setPaymentMethods] = useState(null);
  const [paymentMethodsLoading, setPaymentMethodsLoading] = useState(false);

  const [upcomingInvoice, setUpcomingInvoice] = useState(null);
  const [upcomingInvoiceLoading, setUpcomingInvoiceLoading] = useState(false);

  const [invoices, setInvoices] = useState(null);
  const [invoicesLoading, setInvoicesLoading] = useState(false);

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        setCustomerLoading(true);
        const resp = await axios.get(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/customers/${user.stripe_customer_id}`,
          {
            headers: {
              "x-api-key": API_KEY,
            },
          }
        );

        const { customer } = resp.data;
        setCustomer(customer);
      } catch (error) {
        handleError(error);
        setCustomer(null);
      } finally {
        setCustomerLoading(false);
      }
    };
    fetchCustomer();
  }, [user]);

  useEffect(() => {
    if (customer) {
      const fetchPaymentMethods = async () => {
        try {
          setPaymentMethodsLoading(true);
          const resp = await axios.get(
            `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/payment_methods/customer/${customer.id}`,
            {
              headers: {
                "x-api-key": API_KEY,
              },
            }
          );

          const { payment_methods } = resp.data;
          setPaymentMethods(payment_methods);

          if (customer.invoice_settings.default_payment_method) {
            form.setFieldsValue({
              payment_method_id:
                customer.invoice_settings.default_payment_method,
            });
          }
        } catch (error) {
          handleError(error);
          setPaymentMethods([]);
        } finally {
          setPaymentMethodsLoading(false);
        }
      };
      const fetchBalanceTransactions = async () => {
        try {
          setBalanceTransactionsLoading(true);
          const resp = await axios.get(
            `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/balance_transactions/customer/${customer.id}`,
            {
              headers: {
                "x-api-key": API_KEY,
              },
            }
          );

          const { balance_transactions } = resp.data;
          setBalanceTransactions(balance_transactions);
        } catch (error) {
          handleError(error);
          setBalanceTransactions([]);
        } finally {
          setBalanceTransactionsLoading(false);
        }
      };
      const fetchUpcomingInvoice = async () => {
        try {
          setUpcomingInvoiceLoading(true);
          const resp = await axios.get(
            `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/invoices/customer/upcoming/${customer.id}`,
            {
              headers: {
                "x-api-key": API_KEY,
              },
            }
          );

          const { invoice } = resp.data;
          setUpcomingInvoice(invoice);
        } catch (error) {
          // handleError(error);
          setUpcomingInvoice(null);
        } finally {
          setUpcomingInvoiceLoading(false);
        }
      };
      const fetchInvoices = async () => {
        try {
          setInvoicesLoading(true);
          const resp = await axios.get(
            `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/stripe/invoices/customer/${customer.id}`,
            {
              headers: {
                "x-api-key": API_KEY,
              },
            }
          );

          const { invoices } = resp.data;
          setInvoices(invoices.filter((invoice) => invoice.number));
        } catch (error) {
          handleError(error);
          setInvoices([]);
        } finally {
          setInvoicesLoading(false);
        }
      };
      fetchPaymentMethods();
      fetchBalanceTransactions();
      fetchUpcomingInvoice();
      fetchInvoices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.id]);

  const renderPaymentMethods = (paymentMethods) => {
    return (
      paymentMethods &&
      paymentMethods.length > 0 && (
        <Space direction="vertical" style={{ width: "100%" }}>
          {paymentMethods.map((method) => {
            const { id, card } = method;
            const selected = customer.invoice_settings.default_payment_method
              ? customer.invoice_settings.default_payment_method === id
              : false;
            return (
              <span
                style={{
                  padding: "6px 8px 9px 5px",
                  backgroundColor: selected ? "#8A68C922" : "transparent",
                }}
              >
                <img
                  src={getCardImage(card.brand)}
                  style={{ height: 24, marginRight: 5 }}
                  alt={`${card.brand} Card`}
                />{" "}
                {uppercaseFirst(card.brand)} ending in {card.last4} Exp{" "}
                {card.exp_month}/{card.exp_year}{" "}
                {method?.metadata?.preauth_id ? (
                  <Tooltip title="Verified" placement="right">
                    <CheckCircleOutlined style={{ color: "green" }} />
                  </Tooltip>
                ) : (
                  <Tooltip title="Unverified" placement="right">
                    <CheckCircleOutlined style={{ color: "#cccccc" }} />
                  </Tooltip>
                )}
              </span>
            );
          })}
        </Space>
      )
    );
  };

  const formatter = useMemo(() => {
    let currency = "USD";
    if (upcomingInvoice) {
      currency = upcomingInvoice.currency.toUpperCase();
    }
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency,
    });
  }, [upcomingInvoice]);

  return (
    <Modal
      title={`User Billing: ${user.email_addr}`}
      cancelButtonProps={{ style: { display: "none" } }}
      onOk={close}
      okText="Close"
      closable={false}
      open={visible}
      width={width}
      height={height}
      centered
      destroyOnClose
    >
      <div
        style={{
          height: height - 200,
          marginBottom: "20px",
          overflowY: "scroll",
          padding: "0 10px",
        }}
      >
        {customer && (
          <i style={{ float: "right", color: "#ffffff" }}>{customer.id}</i>
        )}
        <h2 style={{ fontWeight: 300, marginBottom: "15px" }}>Billing</h2>
        <Form form={form} initialValues={{}} layout="vertical">
          <Spin spinning={customerLoading} size="large" />
          {customer && !customerLoading && (
            <>
              <h3>Estimated Due</h3>
              <p>
                This is an estimate of the amount you owe based on your current
                billing period usage after credits & prepayments.
              </p>
              <Spin
                spinning={upcomingInvoiceLoading || balanceTransactionsLoading}
                size="large"
              />
              {!upcomingInvoice && !upcomingInvoiceLoading && (
                <>
                  <h1>{formatter.format(0 / 100)}</h1>
                  <Descriptions
                    layout="vertical"
                    column={4}
                    size="small"
                    bordered
                  >
                    <Descriptions.Item
                      label="Balance"
                      style={{ textAlign: "right" }}
                    >
                      {balanceTransactions && balanceTransactions.length > 0
                        ? formatter.format(
                            balanceTransactions[0].ending_balance / 100
                          )
                        : formatter.format(0)}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Payment Due"
                      style={{ textAlign: "right" }}
                    >
                      N/A
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Prepayments"
                      style={{ textAlign: "right" }}
                    >
                      N/A
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Total Usage"
                      style={{ textAlign: "right" }}
                    >
                      {formatter.format(0 / 100)}
                    </Descriptions.Item>
                  </Descriptions>
                </>
              )}
              {upcomingInvoice && !upcomingInvoiceLoading && (
                <>
                  <h1>{formatter.format(upcomingInvoice.amount_due / 100)}</h1>
                  <Descriptions
                    layout="vertical"
                    column={4}
                    size="small"
                    bordered
                  >
                    <Descriptions.Item
                      label="Balance"
                      style={{ textAlign: "right" }}
                    >
                      {balanceTransactions && balanceTransactions.length > 0
                        ? formatter.format(
                            balanceTransactions[0].ending_balance / 100
                          )
                        : formatter.format(0)}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Payment Due"
                      style={{ textAlign: "right" }}
                    >
                      {format(
                        new Date(upcomingInvoice.next_payment_attempt * 1000),
                        "MM/dd/yy"
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Prepayments"
                      style={{ textAlign: "right" }}
                    >
                      {formatter.format(
                        upcomingInvoice.pre_payment_credit_notes_amount / 100
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item
                      label="Total Usage"
                      style={{ textAlign: "right" }}
                    >
                      {formatter.format(upcomingInvoice.total / 100)}
                    </Descriptions.Item>
                  </Descriptions>
                  <br />
                  <Collapse ghost>
                    <Panel
                      header={
                        <>
                          Usage Detail for{" "}
                          {format(
                            new Date(upcomingInvoice.period_start * 1000),
                            "MM/dd/yy"
                          )}{" "}
                          -{" "}
                          {format(
                            new Date(upcomingInvoice.period_end * 1000),
                            "MM/dd/yy"
                          )}
                        </>
                      }
                      key="1"
                    >
                      <Descriptions
                        layout="horizontal"
                        column={1}
                        size="small"
                        bordered
                      >
                        {upcomingInvoice.lines.data
                          .sort((a, b) => {
                            if (a.plan.amount > b.plan.amount) return -1;
                            if (a.plan.amount < b.plan.amount) return 1;
                            return 0;
                          })
                          .map((item) => {
                            return (
                              <Descriptions.Item
                                key={item.id}
                                label={updateInvoiceLineItemDesc(
                                  item.description
                                )}
                                style={{ textAlign: "right" }}
                              >
                                <strong>
                                  {formatter.format(item.amount / 100)}
                                </strong>
                              </Descriptions.Item>
                            );
                          })}
                      </Descriptions>
                    </Panel>
                  </Collapse>
                </>
              )}
              <Divider dashed />
              <h3>Payment Methods</h3>
              <Paragraph type="secondary" style={{ marginBottom: "20px" }}>
                Add and/or select a default global payment method for all your
                invoices.
              </Paragraph>
              <Spin spinning={paymentMethodsLoading} size="large" />
              {paymentMethods &&
                !paymentMethodsLoading &&
                renderPaymentMethods(paymentMethods)}
              <Divider dashed />
              <h3>Billing History</h3>
              <Spin spinning={invoicesLoading} size="large" />
              {invoices && !invoicesLoading && (
                <Table dataSource={invoices} rowKey="id" size="small">
                  <Column
                    title="Invoice"
                    dataIndex="number"
                    key="number"
                    render={(text, record, index) => {
                      return (
                        <a
                          href={record.hosted_invoice_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {record.number}
                        </a>
                      );
                    }}
                  />
                  <Column
                    title="Date"
                    dataIndex="id"
                    key="id"
                    render={(text, record, index) => {
                      return `${format(
                        new Date(record.created * 1000),
                        "MM/dd/yy"
                      )}`;
                    }}
                  />
                  <Column
                    title="Period"
                    dataIndex="period_start"
                    key="period_start"
                    render={(text, record, index) => {
                      return `${format(
                        new Date(record.period_start * 1000),
                        "MM/dd/yy"
                      )} 
                    - 
                    ${format(new Date(record.period_end * 1000), "MM/dd/yy")}`;
                    }}
                  />
                  <Column
                    title="Paid"
                    dataIndex="paid"
                    align="center"
                    key="paid"
                    render={(text, record, index) => {
                      return record.paid ? (
                        <DollarCircleFilled
                          style={{ fontSize: 20, color: "green" }}
                        />
                      ) : (
                        <DollarCircleOutlined
                          style={{ fontSize: 20, color: "red" }}
                        />
                      );
                    }}
                  />
                  <Column
                    title="Amount"
                    dataIndex="amount_due"
                    align="right"
                    key="amount_due"
                    render={(text, record, index) => {
                      return formatter.format(record.amount_due / 100);
                    }}
                  />
                </Table>
              )}
            </>
          )}
        </Form>
      </div>
    </Modal>
  );
};

export default UserBillingModal;
