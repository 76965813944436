import { useMemo, useEffect, useState } from "react";
import axios from "axios";
import { Button, Table, Tag, Popconfirm, Space, notification } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { format } from "date-fns";

import { useAuth } from "../../hooks/useAuth";
import firebase from "../../services/firebase";
import { handleError, uppercaseFirst } from "../../helpers";

const { Column } = Table;

const API_KEY = "2e148ac7-89c1-4c51-a422-b12881a009fa";

const CleanupPane = ({ clusters, users, teams, assignments }) => {
  const auth = useAuth();
  const db = firebase.firestore();

  const [actions, setActions] = useState([]);

  useEffect(() => {
    db.collection("actions")
      .orderBy("created", "desc")
      .get((snapshot) => {
        const actions = [];
        snapshot.forEach((doc) => {
          actions.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setActions(actions);
      });
    return () => {};
  }, [db, auth]);

  const badAssignments = useMemo(() => {
    return assignments.filter((assignment) => {
      return (
        !clusters.some((cluster) => cluster.id === assignment.cluster_id) ||
        !users.some((user) => user.id === assignment.user_id)
      );
    });
  }, [clusters, users, assignments]);

  const handleDeleteAssignment = (assignment) => async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/assignments/${assignment.id}`,
        {
          headers: {
            "x-api-key": API_KEY,
          },
        }
      );
      notification.success({
        message: "Assignment Delete",
        description: "Assignment is being deleted.",
      });
    } catch (error) {
      handleError(error);
    }
  };

  const badTeams = useMemo(() => {
    return teams.filter((team) => {
      return !users.some((user) => user.id === team.admin_id);
    });
  }, [teams, users]);

  const handleDeleteTeam = (team) => async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/teams/${team.id}`,
        {
          headers: {
            "x-api-key": API_KEY,
          },
        }
      );
      notification.success({
        message: "Team Delete",
        description: "Team is being deleted.",
      });
    } catch (error) {
      handleError(error);
    }
  };

  const badActions = useMemo(() => {
    return actions.filter((action) => {
      return (
        (action.cluster_id &&
          !clusters.some((cluster) => cluster.id === action.cluster_id)) ||
        (action.user_id && !users.some((user) => user.id === action.user_id)) ||
        (action.assignment_id &&
          !assignments.some(
            (assignment) => assignment.id === action.assignment_id
          ))
      );
    });
  }, [clusters, users, actions, assignments]);

  const handleDeleteAction = (action) => async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/actions/${action.id}`,
        {
          headers: {
            "x-api-key": API_KEY,
          },
        }
      );
      notification.success({
        message: "Action Delete",
        description: "Action is being deleted.",
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleSanitizePayloads = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/actions/sanitize`,
        {},
        {
          headers: {
            "x-api-key": API_KEY,
          },
        }
      );
      notification.success({
        message: "Sanitize Action Payloads",
        description: "Action payloads sanitized.",
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleClearAllBadAction = async () => {
    try {
      await Promise.all(
        badActions.map((action) => {
          return handleDeleteAction(action)();
        })
      );
      notification.success({
        message: "Clear All Bad Actions",
        description: "All bad actions cleared.",
      });
    } catch (error) {
      handleError(error);
    }
  };

  const statusStyle = {
    width: "70px",
    textAlign: "center",
    marginLeft: "8px",
  };

  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <h3>Bad Assignments</h3>
        <Table
          dataSource={badAssignments}
          rowKey="id"
          size="small"
          pagination={false}
        >
          <Column
            title="Date"
            dataIndex="created"
            key="created"
            width={1}
            render={(created) => {
              return (
                <div
                  style={{
                    fontSize: "11px",
                    lineHeight: "12px",
                    width: 55,
                  }}
                >
                  {format(new Date(created.seconds * 1000), "MM/dd/yy")}
                  <br />
                  {format(new Date(created.seconds * 1000), "hh:mm aaa")}
                </div>
              );
            }}
          />
          <Column
            title="Username"
            dataIndex="admin_username"
            key="admin_username"
          />
          <Column
            title="Status"
            dataIndex="status"
            key="status"
            width={100}
            render={(status) => (
              <>
                {status === "complete" ? (
                  <Tag color="#87d068" style={statusStyle}>
                    {uppercaseFirst(status)}
                  </Tag>
                ) : (
                  <Tag color="#ff5500" style={statusStyle}>
                    {uppercaseFirst(status)}
                  </Tag>
                )}
              </>
            )}
          />
          <Column
            title=""
            dataIndex="id"
            key="id"
            width={85}
            render={(id, record) => (
              <Popconfirm
                key="reset"
                title={<>Are you sure you want to delete this assignment?</>}
                onConfirm={handleDeleteAssignment(record)}
                okText="Yes"
                cancelText="No"
              >
                <Button style={{ float: "right" }} size="small">
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            )}
          />
        </Table>
      </div>
      <div style={{ marginBottom: "20px" }}>
        <h3>Bad Teams</h3>
        <Table
          dataSource={badTeams}
          rowKey="id"
          size="small"
          pagination={false}
        >
          <Column
            title="Date"
            dataIndex="created"
            key="created"
            width={1}
            render={(created) => {
              return (
                <div
                  style={{
                    fontSize: "11px",
                    lineHeight: "12px",
                    width: 55,
                  }}
                >
                  {format(new Date(created.seconds * 1000), "MM/dd/yy")}
                  <br />
                  {format(new Date(created.seconds * 1000), "hh:mm aaa")}
                </div>
              );
            }}
          />
          <Column title="Admin ID" dataIndex="admin_id" key="admin_id" />
          <Column
            title=""
            dataIndex="id"
            key="id"
            width={85}
            render={(id, record) => (
              <Popconfirm
                key="reset"
                title={<>Are you sure you want to delete this team?</>}
                onConfirm={handleDeleteTeam(record)}
                okText="Yes"
                cancelText="No"
              >
                <Button style={{ float: "right" }} size="small">
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            )}
          />
        </Table>
      </div>
      <div style={{ marginBottom: "20px" }}>
        <Space style={{ float: "right" }}>
          <Popconfirm
            key="reset"
            title={<>Are you sure you want to sanitize action payloads?</>}
            onConfirm={handleSanitizePayloads}
            okText="Yes"
            cancelText="No"
          >
            <Button size="small">
              <DeleteOutlined /> Sanitize Payloads
            </Button>
          </Popconfirm>
          <Popconfirm
            key="clearall"
            title={<>Are you sure you want to clear all bad actions?</>}
            onConfirm={handleClearAllBadAction}
            okText="Yes"
            cancelText="No"
          >
            <Button size="small">
              <DeleteOutlined /> Clear All
            </Button>
          </Popconfirm>
        </Space>
        <h3>Bad Actions</h3>
        <Table
          dataSource={badActions}
          rowKey="id"
          size="small"
          pagination={false}
        >
          <Column
            title="Date"
            dataIndex="created"
            key="created"
            width={1}
            render={(created) => {
              return (
                <div
                  style={{
                    fontSize: "11px",
                    lineHeight: "12px",
                    width: 55,
                  }}
                >
                  {format(new Date(created.seconds * 1000), "MM/dd/yy")}
                  <br />
                  {format(new Date(created.seconds * 1000), "hh:mm aaa")}
                </div>
              );
            }}
          />
          <Column title="Type" dataIndex="type" key="type" />
          <Column
            title="Status"
            dataIndex="status"
            key="status"
            width={100}
            render={(status) => (
              <>
                {status === "complete" ? (
                  <Tag color="#87d068" style={statusStyle}>
                    {uppercaseFirst(status)}
                  </Tag>
                ) : (
                  <Tag color="#ff5500" style={statusStyle}>
                    {status ? uppercaseFirst(status) : "Unknown"}
                  </Tag>
                )}
              </>
            )}
          />
          <Column
            title=""
            dataIndex="id"
            key="id"
            width={85}
            render={(id, record) => (
              <Popconfirm
                key="reset"
                title={<>Are you sure you want to delete this action?</>}
                onConfirm={handleDeleteAction(record)}
                okText="Yes"
                cancelText="No"
              >
                <Button style={{ float: "right" }} size="small">
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            )}
          />
        </Table>
      </div>
    </>
  );
};

export default CleanupPane;
