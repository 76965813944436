import { useState, useMemo } from "react";
import { Space, Input, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { format } from "date-fns";

const { Column } = Table;

const UsagePane = ({ usage, clusters }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const records = useMemo(
    (_) => {
      return usage
        .map((record) => {
          const cluster = clusters.find(
            (cluster) => cluster.id === record.cluster_id
          );
          return {
            ...record,
            cluster_name: cluster ? cluster.cluster_name : "",
          };
        })
        .filter(({ cluster_name, type }) => {
          if (searchTerm.trim() !== "") {
            return (
              cluster_name.includes(searchTerm.trim()) ||
              type.includes(searchTerm.trim())
            );
          }
          return true;
        });
    },
    [usage, clusters, searchTerm]
  );

  const onSearch = (evt) => {
    setSearchTerm(evt.target.value);
  };

  const pageSize = useMemo((_) => {
    return Math.floor((window.innerHeight - 400) / 53);
  }, []);

  return (
    <div style={{ marginBottom: "20px" }}>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Input
          value={searchTerm}
          onChange={onSearch}
          addonAfter={<SearchOutlined />}
          placeholder="Enter search term"
          size="large"
        />
        <Table
          dataSource={records}
          rowKey="id"
          size="small"
          pagination={{
            position: ["bottomCenter"],
            pageSize,
          }}
        >
          <Column
            title="Date"
            dataIndex="created"
            key="created"
            width={1}
            render={(created) => {
              return (
                <div
                  style={{
                    fontSize: "11px",
                    lineHeight: "12px",
                    width: 55,
                  }}
                >
                  {format(new Date(created.seconds * 1000), "MM/dd/yy")}
                  <br />
                  {format(new Date(created.seconds * 1000), "hh:mm aaa")}
                </div>
              );
            }}
          />
          <Column title="Cluster" dataIndex="cluster_name" key="cluster_name" />
          <Column title="Type" dataIndex="type" key="type" width={80} />
          <Column
            title="Info"
            dataIndex="usage_record_id"
            key="usage_record_id"
            render={(_, record) => {
              return (
                <div
                  style={{
                    fontSize: "11px",
                    lineHeight: "12px",
                  }}
                >
                  ur_id: {record?.usage_record_id}
                  <br />
                  si_id: {record?.subscription_item_id}
                  <br />
                  err: {record?.error}
                </div>
              );
            }}
          />
          <Column
            title="Quantity"
            dataIndex="quantity"
            key="quantity"
            width={80}
            render={(quantity) => {
              return (
                <div style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                  {quantity}
                </div>
              );
            }}
          />
        </Table>
      </Space>
    </div>
  );
};

export default UsagePane;
