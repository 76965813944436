import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Button, Space, Spin, notification } from "antd";
import { useHistory } from "react-router-dom";
import {
  GoogleLoginButton,
  GithubLoginButton,
} from "react-social-login-buttons";

import { useAuth } from "../../hooks/useAuth";
import firebase, {
  googleProvider,
  githubProvider,
} from "../../services/firebase";
import { handleError, sleep, validateEmail } from "../../helpers";
import useSegment from "../../hooks/useSegment";

const PROCESSING_TIMEOUT = 100;

const LoginForm = ({ setActiveTabKey }) => {
  const auth = useAuth();
  const history = useHistory();
  const emailRef = useRef(null);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [isSendingReset, setIsSendingReset] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isSocialLoggingIn, setIsSocialLoggingIn] = useState(false);

  const segment = useSegment(auth);

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  const handleGoogleLogin = async () => {
    try {
      setIsSocialLoggingIn(true);

      const result = await firebase.auth().signInWithPopup(googleProvider);
      const { name, email, picture } = result?.additionalUserInfo?.profile;

      validateEmail(email);

      const existingUser = await auth.getUserByEmail(email);

      // Segment
      segment.track("console_login");

      if (existingUser) {
        await auth.updateUser(existingUser.id, {
          display_name: name ?? email.toLowerCase(),
          photo_url: picture,
        });
      } else {
        // Create user
        await auth.createUser({
          email_addr: email.toLowerCase(),
          display_name: name ?? email.toLowerCase(),
          photo_url: picture,
        });

        // Send welcome email
        await auth.sendWelcomeEmail();

        await sleep(PROCESSING_TIMEOUT);
        history.go(0);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsSocialLoggingIn(false);
    }
  };

  const handleGithubLogin = async () => {
    try {
      setIsSocialLoggingIn(true);

      const result = await firebase.auth().signInWithPopup(githubProvider);
      const {
        name,
        email,
        avatar_url: picture,
      } = result?.additionalUserInfo?.profile;

      if (!email) {
        handleError(
          "GitHub user email is required but unavailable. Please make sure email is public."
        );
        return;
      }

      validateEmail(email);

      const existingUser = await auth.getUserByEmail(email);

      // Segment
      segment.track("console_login");

      if (existingUser) {
        await auth.updateUser(existingUser.id, {
          display_name: name ?? email.toLowerCase(),
          photo_url: picture,
        });
      } else {
        // Create user
        await auth.createUser({
          email_addr: email.toLowerCase(),
          display_name: name ?? email.toLowerCase(),
          photo_url: picture,
        });

        // Send welcome email
        await auth.sendWelcomeEmail();

        await sleep(PROCESSING_TIMEOUT);
        history.go(0);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsSocialLoggingIn(false);
    }
  };

  const togglePasswordReset = () => {
    setIsPasswordReset(!isPasswordReset);
  };

  const onFinish = async (values) => {
    if (!isPasswordReset) {
      if (!values.email || !values.password) {
        return;
      }

      try {
        setIsLoggingIn(true);

        validateEmail(values.email);

        await auth.login(values.email, values.password);

        // Segment
        segment.track("console_login");

        history.push("/");
      } catch (error) {
        handleError(error);
        setIsLoggingIn(false);
      }
    } else {
      if (!values.email) {
        return;
      }

      try {
        setIsSendingReset(true);
        await auth.sendPasswordResetEmail(values.email);
        notification.info({
          message: "Password Reset",
          description:
            "A password reset e-mail has been sent. Please check and click the reset password link to reset your password.",
        });
        setIsPasswordReset(false);

        // Segment
        segment.track("password_reset");
      } catch (error) {
        // handleError(error);
        notification.info({
          message: "Password Reset",
          description:
            "A password reset e-mail has been sent. Please check and click the reset password link to reset your password.",
        });
      } finally {
        setIsSendingReset(false);
      }
    }
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    // console.log("onFinishFailed", values, errorFields, outOfDate);
  };

  const toggleSignup = (_) => {
    setActiveTabKey("signup");
  };

  return (
    <Spin spinning={isSocialLoggingIn} size="large">
      <Form
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item name="email">
          <Input
            ref={(ref) => {
              emailRef.current = ref;
            }}
            placeholder="E-Mail"
            size="large"
            style={{ borderRadius: "5px", padding: "10px 15px" }}
          />
        </Form.Item>
        {!isPasswordReset ? (
          <>
            <Form.Item name="password">
              <Input
                type="password"
                placeholder="Password"
                size="large"
                style={{ borderRadius: "5px", padding: "10px 15px" }}
              />
            </Form.Item>
            <div className="button-login">
              <Button
                type="default"
                htmlType="submit"
                loading={isLoggingIn}
                size="large"
                style={{
                  border: 0,
                  color: "#ffffff",
                  borderRadius: "5px",
                  overflow: "hidden",
                  zIndex: "1",
                }}
                block
              >
                {isLoggingIn ? "Logging In..." : "Log In"}
                <div className="button-login-fill"></div>
              </Button>
            </div>
          </>
        ) : (
          <div className="button-login">
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={isSendingReset}
              style={{
                border: 0,
                color: "#ffffff",
                borderRadius: "5px",
                overflow: "hidden",
                zIndex: "1",
              }}
              block
            >
              {isSendingReset ? "Sending..." : "Send Password Reset"}
              <div className="button-login-fill"></div>
            </Button>
          </div>
        )}
      </Form>
      <Space direction="vertical" style={{ width: "100%" }}>
        {!isPasswordReset ? (
          <div style={{ textAlign: "center", padding: "10px" }}>
            <Button type="link" onClick={togglePasswordReset} size="small">
              Forgot Your Password?
            </Button>
            <Button type="link" onClick={toggleSignup} size="small">
              Don't have an account? Sign Up Here
            </Button>
            <div style={{ height: 118 }}></div>
          </div>
        ) : (
          <div style={{ textAlign: "center", padding: "10px" }}>
            <Button type="link" onClick={togglePasswordReset} size="small">
              Cancel
            </Button>
            <div style={{ height: 213 }}></div>
          </div>
        )}
        <GoogleLoginButton
          onClick={handleGoogleLogin}
          align="center"
          style={{
            height: "40px",
            borderRadius: "5px",
            margin: "5px 0px",
            width: "100%",
            fontSize: "16px",
            fontWeight: 500,
            border: "1px solid #eeeeee",
          }}
        />
        <GithubLoginButton
          onClick={handleGithubLogin}
          align="center"
          style={{
            height: "40px",
            borderRadius: "5px",
            margin: "5px 0px",
            width: "100%",
            fontSize: "16px",
            fontWeight: 500,
          }}
        />
      </Space>
    </Spin>
  );
};

export default LoginForm;
