import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  ClusterOutlined,
  UserOutlined,
  // CommentOutlined,
  ToolOutlined,
} from "@ant-design/icons";
import { useAuth } from "../../hooks/useAuth";

const Nav = () => {
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();

  const handleNav = (route) => () => {
    history.push(route);
  };

  const menuStyle = {
    float: "right",
    fontSize: "18px",
    fontWeight: 200,
    width: "100%",
    padding: "10px 40px 10px",
    marginBottom: "1px",
    color: "#8A68C9",
    cursor: "pointer",
    borderRadius: "0px 0px 0px 0px",
    textAlign: "right",
  };
  const menuStyleOn = {
    ...menuStyle,
    color: "#8A68C9",
    fontWeight: 400,
    backgroundColor: "#8A68C911",
  };

  return (
    <>
      <div
        style={{
          width: "calc(100% - 60px)",
          borderRadius: "5px 0px 0px 5px",
          float: "right",
          overflow: "hidden",
        }}
      >
        <div
          onClick={handleNav("/dashboard")}
          style={
            ["/dashboard", "/plans", "/free", "/dedicated", "/onprem"].includes(
              location.pathname
            )
              ? menuStyleOn
              : menuStyle
          }
        >
          <ClusterOutlined style={{ marginRight: "5px" }} /> Dashboard
        </div>
        <div
          onClick={handleNav("/account")}
          style={
            ["/account"].includes(location.pathname) ? menuStyleOn : menuStyle
          }
        >
          <UserOutlined style={{ marginRight: "5px" }} /> Account
        </div>
        {auth.user.db.is_admin && (
          <div
            onClick={handleNav("/admin")}
            style={
              ["/admin"].includes(location.pathname) ? menuStyleOn : menuStyle
            }
          >
            <ToolOutlined style={{ marginRight: "5px" }} /> Admin
          </div>
        )}
        {/* <div
          onClick={handleNav("/support")}
          style={
            ["/support"].includes(location.pathname) ? menuStyleOn : menuStyle
          }
        >
          <CommentOutlined style={{ marginRight: "5px" }} /> Support
        </div> */}
      </div>
    </>
  );
};

export default Nav;
