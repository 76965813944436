import React, { useCallback, useState } from "react";
import axios from "axios";
import {
  Modal,
  Table,
  Tag,
  Input,
  Popconfirm,
  Button,
  Space,
  Divider,
  notification,
} from "antd";
import {
  SearchOutlined,
  DeleteOutlined,
  FileSyncOutlined,
} from "@ant-design/icons";
import { format } from "date-fns";

import { handleError, uppercaseFirst } from "../../helpers";
import { SERVICE_LEVEL } from "../../constants";

const { Column } = Table;

const API_KEY = "2e148ac7-89c1-4c51-a422-b12881a009fa";

const UserClustersModal = ({
  id,
  clusters = [],
  host_clusters = [],
  assignments = [],
  width = 750,
  height = 600,
  visible,
  close,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const onSearch = (evt) => {
    setSearchTerm(evt.target.value);
  };

  const statusStyle = {
    width: "70px",
    textAlign: "center",
    marginLeft: "8px",
  };

  const filteredClusters = useCallback(
    (service_level = "") => {
      return clusters
        .filter((cluster) => {
          return (
            assignments.reduce((acc, cur) => {
              if (cur.cluster_id === cluster.id && cur.user_id === id) {
                return acc + 1;
              }
              return acc;
            }, 0) > 0
          );
        })
        .filter((cluster) => {
          return (
            ((cluster.cluster_label &&
              cluster.cluster_label
                .toLowerCase()
                .includes(searchTerm.toLowerCase())) ||
              cluster.cluster_name
                .toLowerCase()
                .includes(searchTerm.toLowerCase())) &&
            (service_level === "" || cluster.service_level === service_level)
          );
        })
        .sort((a, b) => {
          if (a.service_level > b.service_level) return -1;
          if (a.service_level < b.service_level) return 1;
          if (a.cluster_label < b.cluster_label) return -1;
          if (a.cluster_label > b.cluster_label) return 1;
          return 0;
        });
    },
    [id, clusters, searchTerm, assignments]
  );

  const handleBackupCluster = (cluster) => async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/clusters/${cluster.id}/backup`,
        {},
        {
          headers: {
            "x-api-key": API_KEY,
          },
        }
      );
      notification.success({
        message: "Cluster Backup",
        description: "Cluster is being backed up.",
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleDeleteCluster = (cluster) => async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/clusters/${cluster.id}`,
        {
          headers: {
            "x-api-key": API_KEY,
          },
        }
      );
      notification.success({
        message: "Cluster Delete",
        description: "Cluster is being deprovisioned.",
      });
    } catch (error) {
      handleError(error);
    }
  };

  const buildTable = (label, clusters) => {
    return (
      <Table
        className={`${label.toLowerCase()}-clusters`}
        dataSource={clusters}
        rowKey="id"
        size="small"
        rowClassName={(record) => {
          return record.owner_id ? "used" : "free";
        }}
        pagination={false}
      >
        <Column
          title="Date"
          dataIndex="created"
          key="created"
          width={1}
          render={(created) => {
            return (
              <div
                style={{
                  fontSize: "11px",
                  lineHeight: "12px",
                  width: 55,
                }}
              >
                {format(new Date(created.seconds * 1000), "MM/dd/yy")}
                <br />
                {format(new Date(created.seconds * 1000), "hh:mm aaa")}
              </div>
            );
          }}
        />
        <Column
          title={label}
          dataIndex="cluster_name"
          key="cluster_name"
          render={(cluster_name, record) => {
            return (
              <a href={record.workbench_url} target="_blank" rel="noreferrer">
                {cluster_name}
              </a>
            );
          }}
        />
        <Column
          title="Version"
          dataIndex="current_version"
          key="current_version"
          width={1}
          render={(current_version) => {
            return (
              <div style={{ textAlign: "right", whiteSpace: "nowrap" }}>
                {current_version}
              </div>
            );
          }}
        />
        <Column
          title="Size"
          dataIndex="cluster_size"
          key="cluster_size"
          width={1}
          render={(cluster_size) => {
            return <div style={{ textAlign: "center" }}>{cluster_size}</div>;
          }}
        />
        <Column
          title="Type"
          dataIndex="cluster_type"
          key="cluster_type"
          width={1}
          render={(cluster_type) => {
            return (
              <div style={{ textAlign: "center" }}>
                {cluster_type.includes("CPU") ? "CPU" : ""}
                {cluster_type.includes("GPU") ? "GPU" : ""}
              </div>
            );
          }}
        />
        {/* <Column
          title="Host"
          dataIndex="host_cluster_id"
          key="host_cluster_id"
          width={60}
          render={(host_cluster_id) => {
            const host_cluster = host_clusters.find(
              (host_cluster) => host_cluster.id === host_cluster_id
            );
            return host_cluster ? (
              <div style={{ textAlign: "center" }}>{host_cluster.type}</div>
            ) : (
              ""
            );
          }}
        /> */}
        <Column
          title="Status"
          dataIndex="status"
          key="status"
          width={100}
          render={(status) => (
            <>
              {status === "online" ? (
                <Tag color="#87d068" style={statusStyle}>
                  {uppercaseFirst(status)}
                </Tag>
              ) : (
                <Tag color="#ff5500" style={statusStyle}>
                  {uppercaseFirst(status)}
                </Tag>
              )}
            </>
          )}
        />
        <Column
          title=""
          dataIndex="id"
          key="id"
          width={85}
          render={(id, record) => (
            <Space style={{ float: "right" }}>
              <Popconfirm
                key="delete"
                title={<>Are you sure you want to backup this cluster?</>}
                onConfirm={handleBackupCluster(record)}
                okText="Yes"
                cancelText="No"
              >
                <Button style={{ float: "right" }} size="small">
                  <FileSyncOutlined />
                </Button>
              </Popconfirm>
              <Popconfirm
                key="delete"
                title={<>Are you sure you want to delete this cluster?</>}
                onConfirm={handleDeleteCluster(record)}
                okText="Yes"
                cancelText="No"
                disabled={record.service_level === SERVICE_LEVEL.FREE_SHARED}
              >
                <Button
                  style={{ float: "right" }}
                  size="small"
                  disabled={record.service_level === SERVICE_LEVEL.FREE_SHARED}
                >
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
    );
  };

  return (
    <Modal
      title="User Clusters"
      onCancel={close}
      onOk={close}
      closable={false}
      visible={visible}
      width={width}
      height={height}
      centered
      destroyOnClose
    >
      <div style={{ height: height - 200, marginBottom: "20px" }}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Input
            value={searchTerm}
            onChange={onSearch}
            addonAfter={<SearchOutlined />}
            placeholder="Enter search term"
            size="large"
          />
          <div style={{ height: height - 220, overflowY: "auto" }}>
            {buildTable("Free", filteredClusters(SERVICE_LEVEL.FREE_SHARED))}
            <Divider dashed />
            {buildTable("Dedicated", filteredClusters(SERVICE_LEVEL.DEDICATED))}
          </div>
        </Space>
      </div>
    </Modal>
  );
};

export default UserClustersModal;
