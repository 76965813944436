import React, { useState, useRef, useEffect, useMemo } from "react";
import { Form, Input, Button, Space, Tooltip, notification } from "antd";

import { useAuth } from "../../hooks/useAuth";
import { handleError, checkPasswordStrength } from "../../helpers";
import ReauthModal from "../Modal/ReauthModal";
import useSegment from "../../hooks/useSegment";

const { Password } = Input;

const ProfilePane = () => {
  const auth = useAuth();
  const fullNameRef = useRef(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [showReauthModal, setShowReauthModal] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(false);

  const segment = useSegment(auth);

  const [form] = Form.useForm();

  useEffect(() => {
    fullNameRef.current.focus();
  }, []);

  const handleReauthCallback = () => {
    setShowReauthModal(false);
    form.submit();
  };

  const handleCancel = () => {
    setShowReauthModal(false);
  };

  const canUpdatePassword = useMemo(() => {
    const { providerData } = auth.user;
    return providerData.some((provider) => {
      return provider.providerId === "password";
    });
  }, [auth]);

  const onFinish = async (values) => {
    const { full_name, company_name, password, confirm_password } = values;

    form.setFields([
      {
        name: "confirm_password",
        errors: [],
      },
    ]);
    if ((password || confirm_password) && password !== confirm_password) {
      form.setFields([
        {
          name: "confirm_password",
          errors: ["Confirm password must match new password"],
        },
      ]);
      return;
    }

    if (password) {
      const strength = checkPasswordStrength(password);
      if (strength.value !== "strong") {
        form.setFields([
          {
            name: "password",
            errors: [
              "Must contain 8 characters, 1 uppercase, 1 lowercase, and 1 number.",
            ],
          },
        ]);
        return;
      }
    }

    try {
      setIsUpdating(true);

      const existingUser = await auth.getUserByEmail(auth.user.email);
      await auth.updateUser(existingUser.id, {
        display_name: full_name ?? auth.user.email.toLowerCase(),
        company_name,
      });
      await auth.updateUserProfile({
        displayName: full_name ?? auth.user.email.toLowerCase(),
      });

      if (password) {
        await auth.updateUserPassword(password);
      }
      notification.info({
        message: "Account Update",
        description: "Account information updated successfully",
      });
      form.resetFields();
      setPasswordStrength(false);

      // Segment
      segment.track("account_update");
    } catch (error) {
      if (error.code && error.code === "auth/requires-recent-login") {
        setShowReauthModal(true);
      } else {
        handleError(error);
      }
    } finally {
      setIsUpdating(false);
    }
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    // console.log("onFinishFailed", values, errorFields, outOfDate);
  };

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues?.password) {
      if (changedValues.password !== "") {
        const strength = checkPasswordStrength(changedValues.password);
        setPasswordStrength(strength);
      } else {
        setPasswordStrength(false);
      }
    }
  };

  return (
    <div style={{ padding: "0 20px" }}>
      <h2 style={{ fontWeight: 300, marginBottom: "15px" }}>User Profile</h2>
      <Form
        form={form}
        initialValues={{
          full_name: auth.user.displayName,
          company_name: auth.user.db.company_name ?? "",
        }}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={onValuesChange}
      >
        <Form.Item
          label="Full Name"
          name="full_name"
          rules={[{ required: true, message: "Please enter your full name!" }]}
        >
          <Input
            ref={(ref) => {
              fullNameRef.current = ref;
            }}
            size="large"
          />
        </Form.Item>
        <Form.Item
          label="Company/Organization"
          name="company_name"
          rules={[
            {
              required: true,
              message: "Please enter your company or organization!",
            },
          ]}
        >
          <Input
            size="large"
            placeholder="Enter ‘Personal’ for non-business use"
          />
        </Form.Item>
        {canUpdatePassword && (
          <>
            <Tooltip
              placement="right"
              title="Must contain 8 characters, 1 uppercase, 1 lowercase, and 1 number."
            >
              <Form.Item
                label="Password"
                name="password"
                validateStatus={passwordStrength?.status}
                hasFeedback
              >
                <Password
                  size="large"
                  placeholder="Enter new password to update"
                />
              </Form.Item>
            </Tooltip>
            <Form.Item label="Confirm Password" name="confirm_password">
              <Input.Password
                size="large"
                placeholder="Confirm password to update"
              />
            </Form.Item>
          </>
        )}
        <Space direction="vertical" style={{ width: "100%", marginTop: 20 }}>
          <Button
            type="default"
            htmlType="submit"
            size="large"
            loading={isUpdating}
            block
          >
            {isUpdating ? "Updating..." : "Update"}
          </Button>
        </Space>
      </Form>
      {showReauthModal && (
        <ReauthModal
          visible={showReauthModal}
          onCancel={handleCancel}
          onReauth={handleReauthCallback}
        />
      )}
    </div>
  );
};

export default ProfilePane;
