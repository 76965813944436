import React, { useContext, useEffect, useState } from "react";
import { Layout as AntLayout } from "antd";
import { useHistory } from "react-router-dom";

import Header from "../Header/Header";
import Nav from "../Nav/Nav";
import { useAuth } from "../../hooks/useAuth";
import KineticaLogo from "../../images/logo.svg";
import PeopleCollab from "../../images/people_collab.png";
import { ADMIN_LAYOUT, AlertContext } from "../../constants";

const { Sider, Content } = AntLayout;

const Layout = (props) => {
  const auth = useAuth();
  const history = useHistory();
  const alerts = useContext(AlertContext);
  const { children } = props;

  const [contentHeight, setContentHeight] = useState();

  const handleHomeClick = () => {
    history.push("/");
  };

  useEffect(() => {
    const handleResize = () => {
      const height =
        process.env.REACT_APP_ENV === "prod"
          ? window.innerHeight - alerts.length * 83
          : window.innerHeight - alerts.length * 83 - 20;
      setContentHeight(height);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [alerts]);

  return (
    <>
      <AntLayout hasSider>
        <Sider width={280} style={{ borderRight: "1px solid #826fdf11" }}>
          <div
            style={
              process.env.REACT_APP_ENV === "prod"
                ? {
                    height:
                      alerts.length > 0
                        ? `calc(100vh - ${alerts.length * 83}px)`
                        : "100vh",
                    backgroundColor: undefined,
                  }
                : {
                    height:
                      alerts.length > 0
                        ? `calc(100vh - ${alerts.length * 83 - 20}px)`
                        : "calc(100vh - 20px)",
                    backgroundColor: undefined,
                  }
            }
          >
            <div
              onClick={handleHomeClick}
              style={{
                cursor: "pointer",
                padding: ADMIN_LAYOUT.LOGO.padding,
                textAlign: auth.user ? "right" : "left",
              }}
            >
              <img
                src={KineticaLogo}
                style={{
                  height: ADMIN_LAYOUT.LOGO.height,
                }}
                alt="Kinetica Logo"
              />
              <h1
                style={{
                  color: "#ffffff66",
                  fontSize: ADMIN_LAYOUT.SUBTITLE.fontSize,
                  fontWeight: 100,
                }}
              >
                SaaS Admin Portal
              </h1>
            </div>
            <Nav />
            <img
              src={PeopleCollab}
              style={{
                width: "80%",
                position: "absolute",
                bottom: "80px",
                maxWidth: "450px",
              }}
              alt="People Collaborating"
            />
          </div>
        </Sider>
        <AntLayout>
          <Content>
            <div
              style={{
                padding: ADMIN_LAYOUT.CONTENT.padding,
                height: contentHeight,
                overflowY: "auto",
                backgroundColor: "#ffffff",
              }}
            >
              <Header />
              {React.cloneElement(children, { ...props })}
            </div>
          </Content>
        </AntLayout>
      </AntLayout>
    </>
  );
};

export default Layout;
