import React from "react";
import { Tabs } from "antd";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import ProfilePane from "./ProfilePane";
import BillingPane from "./BillingPane";
import { ADMIN_LAYOUT } from "../../constants";
import { useAuth } from "../../hooks/useAuth";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Account = () => {
  const auth = useAuth();

  const items =
    auth.user.db.is_beta || process.env.REACT_APP_ENABLE_DEDICATED === "true"
      ? [
          {
            label: "Profile",
            key: "profile",
            children: <ProfilePane />,
          },
          {
            label: "Billing",
            key: "billing",
            children: <BillingPane />,
          },
        ]
      : [
          {
            label: "Profile",
            key: "profile",
            children: <ProfilePane />,
          },
        ];

  return (
    <Elements stripe={stripePromise}>
      <h1>Account</h1>
      <div style={{ height: "5px" }}></div>
      <div style={{ maxWidth: ADMIN_LAYOUT.MAXWIDTH }}>
        <Tabs type="card" items={items} />
      </div>
    </Elements>
  );
};

export default Account;
