import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Spin } from "antd";
import { Route, useHistory } from "react-router-dom";

import { useAuth } from "../../hooks/useAuth";

const PlansRoute = ({ children, ...rest }) => {
  const auth = useAuth();
  const history = useHistory();

  const [clusters, setClusters] = useState([]);
  const [clustersLoading, setClustersLoading] = useState(true);

  const getClusters = useCallback(() => {
    const fetch = async () => {
      try {
        const token = await auth.getIdToken();
        const resp = await axios.get(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/clusters`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const clusters = resp?.data?.clusters.filter(
          (cluster) => Object.keys(cluster).length > 1
        );

        if (clusters.length === 0) {
          history.push("/plans");
        } else {
          setClusters(clusters);
          setClustersLoading(false);
        }
      } catch (error) {
        setClustersLoading(false);
      }
    };
    fetch();
  }, [auth, history]);

  useEffect(() => {
    getClusters();
  }, [getClusters]);

  return clustersLoading ? (
    <div
      style={{
        marginTop: `${window.innerHeight / 2 - 20}px`,
        textAlign: "center",
      }}
    >
      <Spin spinning={clustersLoading} size="large" />
    </div>
  ) : (
    <Route
      {...rest}
      render={() => React.cloneElement(children, { clusters })}
    ></Route>
  );
};

export default PlansRoute;
