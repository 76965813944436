import React, { useEffect, useState } from "react";
import axios from "axios";

import { useAuth } from "../../hooks/useAuth";
import { handleError } from "../../helpers";

const Limit = ({ children }) => {
  const [userLimit, setUserLimit] = useState(null);
  const [freeSharedCount, setFreeSharedCount] = useState(-1);

  const auth = useAuth();

  useEffect(() => {
    const fetchFreeSharedCount = async () => {
      try {
        const token = await auth.getIdToken();
        const resp = await axios.get(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/assignments/free_shared/count`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setFreeSharedCount(resp.data.count);
      } catch (error) {
        handleError(error);
        setFreeSharedCount(0);
      }
    };

    const fetchUserLimit = async () => {
      try {
        const token = await auth.getIdToken();
        const resp = await axios.get(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/config/system/user_limit`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUserLimit(resp.data.user_limit);
      } catch (error) {
        handleError(error);
        setFreeSharedCount(0);
      }
    };

    fetchFreeSharedCount();
    fetchUserLimit();
  }, [auth]);

  return (
    freeSharedCount >= 0 &&
    userLimit &&
    React.cloneElement(children, {
      userLimit: {
        used: freeSharedCount,
        limit: userLimit,
        exceeded: freeSharedCount >= userLimit,
      },
    })
  );
};

export default Limit;
