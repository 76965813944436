import React, { useMemo, useState } from "react";
import axios from "axios";
import {
  Modal,
  Table,
  Tag,
  Input,
  Popconfirm,
  AutoComplete,
  Button,
  Form,
  Space,
  Row,
  Col,
  notification,
} from "antd";
import {
  SearchOutlined,
  DeleteOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { format } from "date-fns";

import {
  handleError,
  uppercaseFirst,
  checkPasswordStrength,
} from "../../helpers";

const { Column } = Table;
const { Password } = Input;

const API_KEY = "2e148ac7-89c1-4c51-a422-b12881a009fa";

const ClusterUsersModal = ({
  id,
  cluster,
  users = [],
  assignments = [],
  enableAddUser = false,
  width = 750,
  height = 600,
  visible,
  close,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isAddUser, setIsAddUser] = useState(false);

  const [passwordStrength, setPasswordStrength] = useState(false);
  const [clusterCreating, setClusterCreating] = useState(false);

  const [form] = Form.useForm();

  const onSearch = (evt) => {
    setSearchTerm(evt.target.value);
  };

  const statusStyle = {
    width: "70px",
    textAlign: "center",
    marginLeft: "8px",
  };

  const filteredUsers = useMemo(() => {
    return users
      .filter((user) => {
        return (
          assignments.reduce((acc, cur) => {
            if (cur.user_id === user.id && cur.cluster_id === id) {
              return acc + 1;
            }
            return acc;
          }, 0) > 0
        );
      })
      .filter((user) => {
        return (
          user.display_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email_addr.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
  }, [id, users, searchTerm, assignments]);

  const handleDeleteAssignment = (assignment) => async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/clusters/${assignment.cluster_id}/deprovision/${assignment.user_id}`,
        {},
        {
          headers: {
            "x-api-key": API_KEY,
          },
        }
      );
      notification.success({
        message: "Database User Deletion",
        description:
          "Database user is being deprovisioned. It may take some time before user is removed from the database.",
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleAddUser = (evt) => {
    setIsAddUser(true);
  };

  const handleCancelAddUser = (evt) => {
    setIsAddUser(false);
  };

  const createCluster = (params) => {
    const fetch = async () => {
      try {
        await axios.post(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/clusters/free/provision`,
          {
            ...params,
            cluster_id: cluster.id,
          },
          {
            headers: {
              "x-api-key": API_KEY,
            },
          }
        );

        notification.success({
          message: "User Provisioning",
          description: "New user is being provisioned.",
        });

        close();
      } catch (error) {
        handleError(error);
        setClusterCreating(false);
      }
    };
    fetch();
  };

  const onFinish = async (values) => {
    const { email_addr, admin_username, password, confirm_password } = values;

    if (password !== confirm_password) {
      form.setFields([
        {
          name: "confirm_password",
          errors: ["Confirm password must match password"],
        },
      ]);
      return;
    }

    const strength = checkPasswordStrength(password);
    if (strength.value !== "strong") {
      form.setFields([
        {
          name: "password",
          errors: [
            "Must contain 8 characters, 1 uppercase, 1 lowercase, and 1 number.",
          ],
        },
      ]);
      return;
    }

    // Check if username already exists
    try {
      setClusterCreating(true);

      const resp = await axios.get(
        `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/assignments/username/${admin_username}`,
        {
          headers: {
            "x-api-key": API_KEY,
          },
        }
      );
      if (resp.data.assignment.length > 0) {
        form.setFields([
          {
            name: "admin_username",
            errors: ["Username already exists"],
          },
        ]);
        return;
      }

      const user = users.find((user) => user.email_addr === email_addr);

      createCluster({
        ...values,
        user_id: user.id,
        email_addr: undefined,
        confirm_password: undefined,
      });
    } catch (error) {
      form.setFields([
        {
          name: "admin_username",
          errors: ["Username validation failed"],
        },
      ]);
    } finally {
      setClusterCreating(false);
    }
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    // console.log("onFinishFailed", values, errorFields, outOfDate);
  };

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues?.password) {
      if (changedValues.password !== "") {
        const strength = checkPasswordStrength(changedValues.password);
        setPasswordStrength(strength);
      } else {
        setPasswordStrength(false);
      }
    }
  };

  const handleCreateCluster = () => {
    form.submit();
  };

  const pageSize = useMemo((_) => {
    return Math.floor((window.innerHeight - 520) / 41);
  }, []);

  return (
    <Modal
      title="Cluster Users"
      onCancel={close}
      onOk={close}
      closable={false}
      visible={visible}
      footer={
        isAddUser
          ? [
              <Button
                key="cancel"
                onClick={handleCancelAddUser}
                disabled={clusterCreating}
              >
                Cancel
              </Button>,
              <Button
                key="add"
                type="primary"
                onClick={handleCreateCluster}
                loading={clusterCreating}
              >
                Add User
              </Button>,
            ]
          : [
              <Button
                key="add"
                icon={<UserAddOutlined />}
                onClick={handleAddUser}
                disabled={!enableAddUser}
                style={{ float: "left" }}
              >
                Add User
              </Button>,
              <Button key="ok" type="primary" onClick={close}>
                OK
              </Button>,
            ]
      }
      width={width}
      height={height}
      centered
      destroyOnClose
    >
      <div style={{ height: height - 200, marginBottom: "20px" }}>
        {isAddUser ? (
          <div style={{ padding: "0px" }}>
            <Form
              form={form}
              initialValues={{ admin_username: "" }}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onValuesChange={onValuesChange}
            >
              <Form.Item
                label="E-Mail Address"
                name="email_addr"
                rules={[
                  {
                    required: true,
                    message: "Please enter an e-mail address!",
                  },
                ]}
              >
                <AutoComplete
                  size="large"
                  options={users
                    .sort((a, b) => {
                      if (a.email_addr > b.email_addr) return 1;
                      if (a.email_addr < b.email_addr) return -1;
                      return 0;
                    })
                    .map((user) => {
                      return {
                        label: user.email_addr,
                        value: user.email_addr,
                      };
                    })}
                  filterOption={(input, option) => {
                    return option.value
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                  disabled={clusterCreating}
                />
              </Form.Item>
              <Form.Item
                label="Username"
                name="admin_username"
                rules={[
                  {
                    required: true,
                    message: "Please enter a username!",
                  },
                ]}
              >
                <Input
                  size="large"
                  disabled={clusterCreating}
                  style={{
                    borderRadius: "5px",
                    padding: "10px 15px",
                  }}
                />
              </Form.Item>
              <Row gutter={20}>
                <Col span={12}>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your password!",
                      },
                    ]}
                    validateStatus={passwordStrength?.status}
                    hasFeedback
                  >
                    <Password
                      size="large"
                      disabled={clusterCreating}
                      style={{
                        borderRadius: "5px",
                        padding: "10px 15px",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Confirm Password"
                    name="confirm_password"
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                    ]}
                  >
                    <Password
                      size="large"
                      disabled={clusterCreating}
                      style={{
                        borderRadius: "5px",
                        padding: "10px 15px",
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        ) : (
          <Space direction="vertical" style={{ width: "100%" }}>
            <Input
              value={searchTerm}
              onChange={onSearch}
              addonAfter={<SearchOutlined />}
              placeholder="Enter search term"
              size="large"
            />
            <div style={{ height: height - 220, overflowY: "auto" }}>
              <Table
                dataSource={filteredUsers}
                rowKey="id"
                size="small"
                pagination={{
                  position: ["bottomCenter"],
                  pageSize,
                }}
              >
                <Column
                  title="Date"
                  dataIndex="id"
                  key="created"
                  width={1}
                  render={(id) => {
                    const assignment = assignments.find(
                      (assignment) => assignment.user_id === id
                    );
                    return (
                      <div
                        style={{
                          fontSize: "11px",
                          lineHeight: "12px",
                          width: 55,
                        }}
                      >
                        {format(
                          new Date(assignment.created.seconds * 1000),
                          "MM/dd/yy"
                        )}
                        <br />
                        {format(
                          new Date(assignment.created.seconds * 1000),
                          "hh:mm aaa"
                        )}
                      </div>
                    );
                  }}
                />
                <Column
                  title="Name"
                  dataIndex="display_name"
                  key="display_name"
                  render={(display_name, record) => {
                    return (
                      <div
                        style={{
                          fontSize: "11px",
                          lineHeight: "12px",
                        }}
                      >
                        {display_name}
                        <br />
                        <a href={`mailto:${record.email_addr.toLowerCase()}`}>
                          {record.email_addr.toLowerCase()}
                        </a>
                      </div>
                    );
                  }}
                />
                <Column
                  title="Username"
                  dataIndex="id"
                  key="username"
                  render={(id) => {
                    const assignment = assignments.find(
                      (assignment) => assignment.user_id === id
                    );
                    return (
                      <div
                        style={{
                          fontSize: "11px",
                          lineHeight: "12px",
                        }}
                      >
                        {assignment.admin_username}
                      </div>
                    );
                  }}
                />
                <Column
                  title="Status"
                  dataIndex="id"
                  key="status"
                  width={100}
                  render={(id) => {
                    const assignment = assignments.find(
                      (assignment) => assignment.user_id === id
                    );
                    return (
                      <>
                        {assignment.status === "complete" ? (
                          <Tag color="#87d068" style={statusStyle}>
                            {uppercaseFirst(assignment.status)}
                          </Tag>
                        ) : (
                          <Tag color="#ff5500" style={statusStyle}>
                            {uppercaseFirst(assignment.status)}
                          </Tag>
                        )}
                      </>
                    );
                  }}
                />
                <Column
                  title=""
                  dataIndex="id"
                  key="id"
                  width={45}
                  render={(id, record) => {
                    const assignment = assignments.find(
                      (assignment) => assignment.user_id === id
                    );
                    return (
                      <Popconfirm
                        key="delete"
                        title={
                          <>Are you sure you want to delete this assignment?</>
                        }
                        onConfirm={handleDeleteAssignment(assignment)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button style={{ float: "right" }} size="small">
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                    );
                  }}
                />
              </Table>
            </div>
          </Space>
        )}
      </div>
    </Modal>
  );
};

export default ClusterUsersModal;
