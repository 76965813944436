import React, { useEffect, useState } from "react";
import { Tabs, Row, Col } from "antd";
import { useHistory } from "react-router-dom";
import { CheckCircleFilled, ExperimentOutlined } from "@ant-design/icons";

import { useAuth } from "../../hooks/useAuth";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import KineticaLogo from "../../images/logo.svg";
import { STORAGE_KEY_REDIRECT } from "../../constants";

const { TabPane } = Tabs;

const FREE_COLORS = ["rgba(113,106,223,1)", "rgba(214,135,226,1)"];

const included = [
  "Store and analyze up to 10GB of data",
  "Shared compute resources",
  "Full Kinetica database functionality",
  "Unlimited SQL queries and API access",
  "Unlimited SQL workbooks",
  "Basic Support",
];

const Trynow = () => {
  const [activeTabKey, setActiveTabKey] = useState("signup");
  const auth = useAuth();
  const history = useHistory();

  useEffect(
    (_) => {
      if (auth.user) {
        history.push("/");
      } else {
        sessionStorage.setItem(STORAGE_KEY_REDIRECT, "/free");
      }
    },
    [auth, history]
  );

  const tabStyle = { width: "125px", textAlign: "center" };
  const topStyle = {
    top: "max(calc(50% - 400px), 20px)",
    position: "relative",
  };

  return (
    <Row style={topStyle}>
      <Col xs={2} sm={4} md={5} lg={5} xl={5}></Col>
      <Col xs={20} sm={16} md={14} lg={14} xl={14}>
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <img
            src={KineticaLogo}
            alt="Kinetica Logo"
            style={{ height: "40px", position: "relative", top: "-3px" }}
          />
          <h1
            style={{
              color: "#8A68C9",
              fontSize: "34px",
              fontWeight: "200",
            }}
          >
            {process.env.REACT_APP_SITE_TITLE}
          </h1>
        </div>
        <div
          className="login"
          style={{
            padding: "10px 60px 30px",
            backgroundColor: "#ffffff",
            borderRadius: "20px",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
            width: 800,
            margin: "auto",
          }}
        >
          <Row gutter={50}>
            <Col span={12}>
              <div style={{ padding: "25px 0 0 0" }}>
                <div
                  style={{
                    height: 50,
                    width: 50,
                    padding: 10,
                    borderRadius: 10,
                    marginTop: 5,
                    marginRight: 15,
                    marginBottom: 10,
                    float: "left",
                    background: `linear-gradient(6deg, ${FREE_COLORS[0]} 0%, ${FREE_COLORS[1]} 100%)`,
                  }}
                >
                  <ExperimentOutlined
                    style={{ fontSize: 30, color: "#ffffff" }}
                  />
                </div>
                <h2
                  style={{ fontSize: 22, lineHeight: "28px", fontWeight: 600 }}
                >
                  Create your Free Kinetica Cloud Account
                </h2>
                <p>
                  Sign up now to create your free account, no credit card
                  required. You will get instant access to Kinetica Cloud and
                  your very own Database for Time and Space. Free. Forever.
                </p>
                <p>What’s included:</p>
                <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
                  {included.map((include, idx) => (
                    <li
                      key={idx}
                      style={{ margin: "7px 0px 7px 5px", padding: 0 }}
                    >
                      <CheckCircleFilled
                        style={{
                          position: "absolute",
                          margin: 2,
                          fontSize: 20,
                          color: `${FREE_COLORS[0]}`,
                        }}
                      />
                      <div
                        style={{
                          display: "inline-block",
                          marginLeft: "30px",
                          width: "calc(100% - 30px)",
                        }}
                      >
                        {include}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
            <Col span={12}>
              <Tabs
                activeKey={activeTabKey}
                size="large"
                tabBarGutter={0}
                onChange={(active) => setActiveTabKey(active)}
                destroyInactiveTabPane
                centered
              >
                <TabPane tab={<div style={tabStyle}>Sign Up</div>} key="signup">
                  <div style={{ padding: "10px 0px", height: "510px" }}>
                    <SignupForm setActiveTabKey={setActiveTabKey} />
                  </div>
                </TabPane>
                <TabPane tab={<div style={tabStyle}>Login</div>} key="login">
                  <div style={{ padding: "10px 0px", height: "510px" }}>
                    <LoginForm setActiveTabKey={setActiveTabKey} />
                  </div>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
      </Col>
      <Col xs={2} sm={4} md={5} lg={5} xl={5}></Col>
    </Row>
  );
};

export default Trynow;
