import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import {
  Alert,
  Typography,
  Form,
  Input,
  Tooltip,
  Row,
  Col,
  Button,
  Steps,
  Spin,
  Checkbox,
  notification,
} from "antd";
import {
  LockOutlined,
  ClockCircleOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";

import { useAuth } from "../../hooks/useAuth";
import { handleError, checkPasswordStrength, sleep } from "../../helpers";
import Header from "../Header/Header";
import Wizard from "../Layout/Wizard";
import KineticaLogo from "../../images/logo.svg";
import useSegment from "../../hooks/useSegment";
import { SERVICE_LEVEL } from "../../constants";

const { Step } = Steps;
const { Password } = Input;
const { Paragraph } = Typography;

const PROCESSING_TIMEOUT = 2000;

const Free = ({ userLimit = {} }) => {
  const auth = useAuth();
  const history = useHistory();

  const [form] = Form.useForm();
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);

  const segment = useSegment(auth);

  const [passwordStrength, setPasswordStrength] = useState(false);
  const [clusterCreating, setClusterCreating] = useState(false);
  const [isJoining, setIsJoining] = useState(false);
  const [isWaitlisted, setIsWaitlisted] = useState(false);
  const [clustersLoading, setClustersLoading] = useState(false);
  const [clusters, setClusters] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      try {
        setClustersLoading(true);

        const token = await auth.getIdToken();
        const resp = await axios.get(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/clusters`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const clusters = resp?.data?.clusters.filter(
          (cluster) => Object.keys(cluster).length > 1
        );

        const limit = auth.user.db?.cluster_limit ?? 1;
        if (
          limit >= 0 &&
          clusters &&
          clusters.filter(
            (cluster) => cluster.service_level === SERVICE_LEVEL.FREE_SHARED
          ).length >= limit
        ) {
          history.push("/");
        }

        setClusters(clusters);
      } catch (error) {
        handleError(error);
        setClusters([]);
      } finally {
        setClustersLoading(false);
      }
    };
    fetch();
  }, [auth, history]);

  const clusterLimitExceeded = useMemo(() => {
    const limit = auth.user.db?.cluster_limit ?? 1;
    return (
      limit >= 0 &&
      clusters &&
      clusters.filter(
        (cluster) => cluster.service_level === SERVICE_LEVEL.FREE_SHARED
      ).length >= limit
    );
  }, [auth, clusters]);

  useEffect(() => {
    const userCheck = async () => {
      const token = await auth.getIdToken();
      const { id: user_id } = auth.user.db;

      const resp = await axios.get(
        `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/users/${user_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setIsWaitlisted(resp?.data?.user.waitlisted);
    };
    userCheck();
  }, [auth]);

  const handleJoin = useCallback(() => {
    const join = async () => {
      try {
        setIsJoining(true);
        const token = await auth.getIdToken();
        const { id: user_id } = auth.user.db;

        await sleep(PROCESSING_TIMEOUT);

        await axios.put(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/users/${user_id}`,
          {
            waitlisted: true,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setIsWaitlisted(true);

        notification.success({
          message: "Joined Waitlist",
          description:
            "Thank you for joining the waitlist. We will contact you as soon as spots are available.",
          duration: 0,
        });

        // Segment
        segment.track("joined_waitlist");

        // history.push("/");
      } catch (error) {
        handleError(error);
      } finally {
        setIsJoining(false);
      }
    };
    join();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    if (userLimit.exceeded && !auth.user.db?.waitlisted) {
      handleJoin();
    }
  }, [auth, userLimit, handleJoin]);

  const createCluster = useCallback(
    (params) => {
      const fetch = async () => {
        try {
          const token = await auth.getIdToken();
          await axios.post(
            `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/clusters/free/provision`,
            params,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          notification.success({
            message: "Database Provisioning",
            description: "Your new database is being provisioned.",
          });

          // Segment
          segment.track("provision_free");

          history.push("/dashboard");
        } catch (error) {
          handleError(error);
          setClusterCreating(false);
        }
      };
      fetch();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth, history]
  );

  const onFinish = async (values) => {
    const { admin_username, password, confirm_password } = values;

    if (password !== confirm_password) {
      form.setFields([
        {
          name: "confirm_password",
          errors: ["Confirm password must match password"],
        },
      ]);
      return;
    }

    const strength = checkPasswordStrength(password);
    if (strength.value !== "strong") {
      form.setFields([
        {
          name: "password",
          errors: [
            "Must contain 8 characters, 1 uppercase, 1 lowercase, and 1 number.",
          ],
        },
      ]);
      return;
    }

    // Check if username already exists
    try {
      setClusterCreating(true);

      const token = await auth.getIdToken();
      const resp = await axios.get(
        `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/assignments/username/${admin_username}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (resp.data.assignment.length > 0) {
        form.setFields([
          {
            name: "admin_username",
            errors: ["Username already exists"],
          },
        ]);
        return;
      }

      createCluster({
        ...values,
        confirm_password: undefined,
      });
    } catch (error) {
      form.setFields([
        {
          name: "admin_username",
          errors: ["Username validation failed"],
        },
      ]);
      setClusterCreating(false);
    }
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    // console.log("onFinishFailed", values, errorFields, outOfDate);
  };

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues?.password) {
      if (changedValues.password !== "") {
        const strength = checkPasswordStrength(changedValues.password);
        setPasswordStrength(strength);
      } else {
        setPasswordStrength(false);
      }
    }
  };

  const handleCreateCluster = () => {
    form.submit();
  };

  const admin_username = useMemo(() => {
    const tokens = auth?.user?.email
      .replace(/\./g, "-")
      .replace(/_/g, "-")
      .replace(/\+/g, "-")
      .replace(/@/g, "-")
      .split("-");
    tokens.pop();
    return tokens.join("_");
  }, [auth]);

  const allow_cluster_username = useMemo(() => {
    const limit = auth.user.db?.cluster_limit ?? 1;
    return limit > 1 || limit < 0;
  }, [auth]);

  return (
    <Wizard>
      <div style={{ textAlign: "center", marginBottom: "0px" }}>
        <img
          src={KineticaLogo}
          alt="Kinetica Logo"
          style={{ height: "40px", position: "relative" }}
        />
        <h1
          style={{
            color: "#8A68C9",
            fontSize: "34px",
            fontWeight: "200",
          }}
        >
          Free
        </h1>
      </div>
      <div
        className="free"
        style={{
          padding: "20px 40px 30px",
          borderRadius: "20px",
          minHeight: 680,
          width: 800,
          margin: "auto",
        }}
      >
        <Header />
        <h2 style={{ fontWeight: 300, marginBottom: "15px" }}>
          <Link to="/plans">
            <LeftOutlined style={{ marginRight: 10 }} />
          </Link>
          {!userLimit.exceeded ? "Database User Setup" : "Waitlist"}
        </h2>
        <div
          style={{
            backgroundColor: "#ffffff",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
            borderRadius: 20,
            padding: "30px 50px 50px",
            height: 565,
          }}
        >
          <Spin
            spinning={clustersLoading}
            style={{ height: 565 }}
            size="large"
          />
          {clusters ? (
            !userLimit.exceeded ? (
              <>
                <Steps>
                  <Step
                    title="Password Configuration"
                    icon={<LockOutlined />}
                  />
                </Steps>
                <div
                  style={{
                    minHeight: 390,
                    margin: "10px 0 20px",
                    padding: 0,
                    borderRadius: 10,
                  }}
                >
                  <Paragraph type="secondary" style={{ marginBottom: "20px" }}>
                    Select your desired password for your new free database
                  </Paragraph>
                  <Form
                    form={form}
                    initialValues={{ admin_username }}
                    layout="vertical"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    onValuesChange={onValuesChange}
                  >
                    <Tooltip
                      placement="right"
                      title="You will be assigned a unique username for your free plan"
                    >
                      <Form.Item
                        label="Username"
                        name="admin_username"
                        rules={[
                          {
                            required: true,
                            message: "Please enter a username!",
                          },
                        ]}
                      >
                        <Input
                          ref={(ref) => {
                            usernameRef.current = ref;
                          }}
                          size="large"
                          disabled={!allow_cluster_username}
                          style={{
                            borderRadius: "5px",
                            padding: "10px 15px",
                          }}
                        />
                      </Form.Item>
                    </Tooltip>
                    <Tooltip
                      placement="right"
                      title="Must contain 8 characters, 1 uppercase, 1 lowercase, and 1 number."
                    >
                      <Row gutter={20}>
                        <Col span={12}>
                          <Form.Item
                            label="Password"
                            name="password"
                            rules={[
                              {
                                required: true,
                                message: "Please enter your password!",
                              },
                            ]}
                            validateStatus={passwordStrength?.status}
                            hasFeedback
                          >
                            <Password
                              ref={(ref) => {
                                passwordRef.current = ref;
                              }}
                              size="large"
                              disabled={clusterCreating || clusterLimitExceeded}
                              style={{
                                borderRadius: "5px",
                                padding: "10px 15px",
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Confirm Password"
                            name="confirm_password"
                            rules={[
                              {
                                required: true,
                                message: "Please confirm your password!",
                              },
                            ]}
                          >
                            <Password
                              size="large"
                              disabled={clusterCreating || clusterLimitExceeded}
                              style={{
                                borderRadius: "5px",
                                padding: "10px 15px",
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Tooltip>
                    <Alert
                      description={
                        <span
                          style={{
                            fontSize: "13px",
                            fontWeight: 300,
                            color: "#666666",
                          }}
                        >
                          <strong style={{ fontWeight: 500 }}>Note</strong>:
                          Kinetica Cloud Free Forever is not intended for
                          production usage. Long running, computationally
                          intensive processes may be paused or terminated and
                          idle resources recovered after a period of user
                          inactivity. For production scenarios, please consider
                          our other deployment options.
                        </span>
                      }
                      type="warning"
                      style={{
                        border: 0,
                        padding: "5px 10px",
                        marginBottom: 10,
                      }}
                    />
                    <Form.Item
                      name="agreement"
                      valuePropName="checked"
                      defaultChecked={false}
                      rules={[
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject(
                                  new Error("You must agree to continue")
                                ),
                        },
                      ]}
                    >
                      <Checkbox disabled={clusterLimitExceeded}>
                        I have read and agree to the{" "}
                        <a
                          href="https://www.kinetica.com/agreements/cloud-services-agreement/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Kinetica terms and conditions
                        </a>
                      </Checkbox>
                    </Form.Item>
                  </Form>
                </div>
                <div style={{ float: "right" }}>
                  {!clusterLimitExceeded ? (
                    <Button
                      type="primary"
                      onClick={handleCreateCluster}
                      disabled={clusterCreating}
                      style={{ borderRadius: 10 }}
                      loading={clusterCreating}
                      block
                    >
                      {clusterCreating ? "Creating" : "Create"}
                    </Button>
                  ) : (
                    <Link to="/dashboard">
                      <Button type="danger" style={{ borderRadius: 10 }} block>
                        Limit Reached
                      </Button>
                    </Link>
                  )}
                </div>
                <div style={{ float: "left" }}>
                  <Link to="/dashboard">
                    <Button style={{ borderRadius: 10 }} block>
                      Cancel
                    </Button>
                  </Link>
                </div>
              </>
            ) : (
              <div className="free-plan-limit">
                <>
                  <h2>Free Plan Limit Reached</h2>
                  Sorry! We have hit our current Free Plan account limit. We
                  will be expanding availability in the near future.
                  <br />
                  <br />
                  {!isJoining && isWaitlisted && (
                    <>
                      You are <strong>on the waitlist</strong>. We will contact
                      you as soon as spots are available.
                    </>
                  )}
                  <br />
                  <br />
                  <div
                    style={{
                      padding: "10px 20px",
                      backgroundColor: "#8A68C911",
                      borderRadius: 5,
                    }}
                  >
                    Meanwhile, you can also try out Kinetica Developer Edition
                    on Mac, Linux or Windows at{" "}
                    <a
                      href="https://www.kinetica.com/try/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.kinetica.com/try/
                    </a>
                  </div>
                  <ClockCircleOutlined
                    style={{
                      fontSize: "130px",
                      display: "block",
                      margin: 55,
                      color: "#8A68C933",
                    }}
                  />
                  <div style={{ marginTop: 20, marginBottom: 10 }}>
                    {isJoining && !isWaitlisted && (
                      <div className="button-login">
                        <Button
                          type="primary"
                          size="large"
                          shape="round"
                          onClick={handleJoin}
                          loading={isJoining}
                          style={{
                            border: 0,
                            color: "#ffffff",
                            borderRadius: "5px",
                            overflow: "hidden",
                            zIndex: "1",
                            backgroundColor: "transparent",
                          }}
                          block
                        >
                          Joining The Waitlist
                          <div className="button-login-fill"></div>
                        </Button>
                      </div>
                    )}
                    {!isJoining && (
                      <Link to="/plans">
                        <Button
                          type="primary"
                          size="large"
                          shape="round"
                          style={{
                            border: 0,
                            color: "#ffffff",
                            borderRadius: "5px",
                            overflow: "hidden",
                            zIndex: "1",
                          }}
                          block
                        >
                          Back to Plans
                        </Button>
                      </Link>
                    )}
                  </div>
                </>
              </div>
            )
          ) : null}
        </div>
      </div>
    </Wizard>
  );
};

export default Free;
