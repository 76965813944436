import React, { useEffect, useState } from "react";
import { Button, Spin, Tabs } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { useAuth } from "../../hooks/useAuth";
import firebase from "../../services/firebase";
import ClusterCreateModal from "../Modal/ClusterCreateModal";
import { ADMIN_LAYOUT } from "../../constants";

import ClustersPane from "./ClustersPane";
import UsersPane from "./UsersPane";
import UsagePane from "./UsagePane";
import ActionsPane from "./ActionsPane";
import CleanupPane from "./CleanupPane";
import AlertsPane from "./AlertsPane";

const { TabPane } = Tabs;

const Admin = () => {
  const auth = useAuth();
  const db = firebase.firestore();

  const [loading, setLoading] = useState(false);

  const [clusters, setClusters] = useState([]);
  const [host_clusters, setHostClusters] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [users, setUsers] = useState([]);
  const [usage, setUsage] = useState([]);
  const [teams, setTeams] = useState([]);
  const [alerts, setAlerts] = useState([]);

  const [initialValues, setInitialValues] = useState({});
  const [showCreateClusterModal, setShowCreateClusterModal] = useState(false);

  useEffect(() => {
    setLoading(true);
    const unsubscribe = db
      .collection("clusters")
      .orderBy("cluster_name", "asc")
      .onSnapshot((snapshot) => {
        const clusters = [];
        snapshot.forEach((doc) => {
          clusters.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setClusters(clusters);
        setLoading(false);
      });
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [db, auth]);

  useEffect(() => {
    const unsubscribe = db
      .collection("host_clusters")
      .onSnapshot((snapshot) => {
        const host_clusters = [];
        snapshot.forEach((doc) => {
          host_clusters.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setHostClusters(host_clusters);
      });
    return () => unsubscribe();
  }, [db, auth]);

  useEffect(() => {
    const unsubscribe = db.collection("assignments").onSnapshot((snapshot) => {
      const assignments = [];
      snapshot.forEach((doc) => {
        assignments.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setAssignments(assignments);
    });
    return () => unsubscribe();
  }, [db, auth]);

  useEffect(() => {
    const unsubscribe = db.collection("alerts").onSnapshot((snapshot) => {
      const alerts = [];
      snapshot.forEach((doc) => {
        alerts.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setAlerts(alerts);
    });
    return () => unsubscribe();
  }, [db, auth]);

  useEffect(() => {
    const unsubscribe = db
      .collection("users")
      .orderBy("created", "desc")
      .onSnapshot((snapshot) => {
        const users = [];
        snapshot.forEach((doc) => {
          users.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setUsers(
          users.sort((a, b) => {
            return b.created.seconds - a.created.seconds;
          })
        );
      });
    return () => unsubscribe();
  }, [db, auth]);

  useEffect(() => {
    const unsubscribe = db
      .collection("billing_events")
      .orderBy("created", "desc")
      .onSnapshot((snapshot) => {
        const usage = [];
        snapshot.forEach((doc) => {
          usage.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        setUsage(
          usage.sort((a, b) => {
            return b.created.seconds - a.created.seconds;
          })
        );
      });
    return () => unsubscribe();
  }, [db, auth]);

  useEffect(() => {
    const unsubscribe = db.collection("teams").onSnapshot((snapshot) => {
      const teams = [];
      snapshot.forEach((doc) => {
        teams.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setTeams(teams);
    });
    return () => unsubscribe();
  }, [db, auth]);

  const handleCreateClick =
    (initialValues = {}) =>
    () => {
      setInitialValues(initialValues);
      setShowCreateClusterModal(true);
    };

  const handleCreateClusterClose = () => {
    setShowCreateClusterModal(false);
  };

  return (
    <>
      <h1>Admin</h1>
      <div style={{ height: "5px" }}></div>
      <div style={{ maxWidth: ADMIN_LAYOUT.MAXWIDTH + 100 }}>
        <Tabs
          type="card"
          tabBarExtraContent={
            <Button
              icon={<PlusOutlined />}
              onClick={handleCreateClick({})}
              size="small"
            >
              New Cluster
            </Button>
          }
        >
          <TabPane tab="Clusters" key="clusters">
            <div style={{ padding: "0" }}>
              <Spin spinning={loading}>
                {clusters.length > 0 || !loading ? (
                  <ClustersPane
                    clusters={clusters}
                    host_clusters={host_clusters}
                    users={users}
                    usage={usage}
                    assignments={assignments}
                    create={handleCreateClick}
                  />
                ) : (
                  <div style={{ width: "100%", height: 400 }}></div>
                )}
              </Spin>
            </div>
          </TabPane>
          <TabPane tab="Users" key="users">
            <div style={{ padding: "0" }}>
              <UsersPane
                users={users}
                host_clusters={host_clusters}
                clusters={clusters}
                assignments={assignments}
              />
            </div>
          </TabPane>
          <TabPane tab="Usage" key="Usage">
            <div style={{ padding: "0" }}>
              <UsagePane usage={usage} clusters={clusters} />
            </div>
          </TabPane>
          <TabPane tab="Actions" key="actions">
            <div style={{ padding: "0" }}>
              <ActionsPane
                users={users}
                clusters={clusters}
                assignments={assignments}
              />
            </div>
          </TabPane>
          <TabPane tab="Alerts" key="alerts">
            <div style={{ padding: "0" }}>
              <AlertsPane alerts={alerts} />
            </div>
          </TabPane>
          <TabPane tab="Cleanup" key="cleanup">
            <div style={{ padding: "0" }}>
              <CleanupPane
                clusters={clusters}
                users={users}
                assignments={assignments}
                teams={teams}
              />
            </div>
          </TabPane>
        </Tabs>
      </div>
      {showCreateClusterModal && (
        <ClusterCreateModal
          host_clusters={host_clusters}
          initialValues={initialValues}
          visible={showCreateClusterModal}
          close={handleCreateClusterClose}
        />
      )}
    </>
  );
};

export default Admin;
