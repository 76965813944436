import { useCallback, useMemo, useState, useEffect } from "react";
import {
  Table,
  Popconfirm,
  Button,
  Input,
  Empty,
  Spin,
  notification,
} from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";

import firebase from "../../services/firebase";
import { useAuth } from "../../hooks/useAuth";
import useSegment from "../../hooks/useSegment";
import { handleError } from "../../helpers";

const { Column } = Table;

const regex =
  /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([0-9]|[1-2][0-9]|3[0-2]))?$/gi;

const validateCIDR = (val) => {
  return val.match(regex);
};

const NetworkPane = ({ cluster, fetchCluster }) => {
  const auth = useAuth();
  const db = firebase.firestore();

  const segment = useSegment(auth);

  const [actions, setActions] = useState([]);

  const getActions = useCallback(() => {
    const fetch = async () => {
      try {
        const token = await auth.getIdToken();
        const types = "update_cluster_network";
        const resp = await axios.get(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/actions/cluster/${cluster.id}?types=${types}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const actions = resp?.data?.actions.filter(
          (action) => Object.keys(action).length > 1
        );

        setActions(actions);
      } catch (error) {
        handleError(error);
        setActions([]);
      }
    };
    fetch();
  }, [auth, cluster]);

  useEffect(() => {
    const unsubscribe = db
      .collection("dashboards")
      .doc(auth.user.db.id)
      .onSnapshot((snapshot) => {
        getActions();
      });
    return () => unsubscribe();
  }, [db, auth, getActions]);

  const [addresses, setAddresses] = useState(
    cluster?.network_access
      ? cluster.network_access.map(({ address, note }, idx) => {
          return {
            id: idx,
            address,
            note,
          };
        })
      : []
  );
  const [isUpdating, setIsUpdating] = useState(false);

  const handleAddAddress = useCallback(() => {
    setAddresses([
      ...addresses,
      { id: addresses.length, address: "", note: "" },
    ]);
  }, [addresses]);

  const handleChangeAddress = useCallback(
    (record) => (evt) => {
      setAddresses(
        addresses.map((item) => {
          return {
            ...item,
            address: record.id === item.id ? evt.target.value : item.address,
          };
        })
      );
    },
    [addresses]
  );

  const handleChangeNote = useCallback(
    (record) => (evt) => {
      setAddresses(
        addresses.map((item) => {
          return {
            ...item,
            note: record.id === item.id ? evt.target.value : item.note,
          };
        })
      );
    },
    [addresses]
  );

  const handleDeleteAddress = useCallback(
    (record) => () => {
      setAddresses(
        addresses
          .filter((item) => item.id !== record.id)
          .map((address, idx) => {
            return {
              ...address,
              id: idx,
            };
          })
      );
    },
    [addresses]
  );

  const handleUpdateAddresses = useCallback(async () => {
    const updates = addresses
      .filter((record) => record.address.trim() !== "")
      .map((record, idx) => {
        return {
          ...record,
          id: idx,
        };
      });

    // Validate
    const invalid = updates.filter((update) => {
      return !validateCIDR(update.address);
    });
    if (invalid.length > 0) {
      handleError(invalid.map((item) => `Invalid entry: ${item.address}`));
      return;
    }

    try {
      setIsUpdating(true);
      const token = await auth.getIdToken();

      await axios.put(
        `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/clusters/${cluster.id}`,
        {
          network_access: updates.map((update) => ({
            address: update.address,
            note: update.note,
          })),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Segment
      segment.track("ip_access_update");

      notification.success({
        message: "IP access list updated",
      });

      fetchCluster();
    } catch (error) {
      handleError(error);
    } finally {
      setIsUpdating(false);
      setAddresses(updates);
    }
  }, [auth, addresses, cluster, fetchCluster, segment]);

  const requiresUpdate = useMemo(() => {
    const sort = (list) => {
      return list.sort((a, b) => {
        if (a.address > b.address) return 1;
        if (a.address < b.address) return -1;
        return 0;
      });
    };

    const matchRecord = (source, target) => {
      return source.address === target.address && source.note === target.note;
    };

    const matchList = (source, target) => {
      if (source.length !== target.length) return false;
      return !sort(source).some((item, idx) => !matchRecord(item, target[idx]));
    };

    return cluster?.network_access
      ? !matchList(
          cluster.network_access,
          addresses.map((item) => ({
            address: item.address,
            note: item.note,
          }))
        )
      : true;
  }, [cluster, addresses]);

  const hasPending = useMemo(
    (_) => {
      return actions.some((action) => action.status === "pending");
    },
    [actions]
  );

  return (
    <>
      <Button
        icon={<PlusOutlined />}
        onClick={handleAddAddress}
        style={{ float: "right" }}
      >
        New IP Address/CIDR Range
      </Button>
      <h2 style={{ fontWeight: 300, marginBottom: "15px" }}>IP Access List</h2>
      <p>
        {addresses.length === 0 && (
          <>
            <strong>
              Your cluster is currently accessible from any IP address.
            </strong>
            <br />
          </>
        )}
        To limit access to specific IP address, enter the IP addresses or CIDR
        ranges below.
      </p>
      <Spin spinning={hasPending} tip="Pending update" size="large">
        <Table
          dataSource={addresses}
          rowKey="id"
          size="small"
          pagination={false}
          locale={{
            emptyText: (
              <>
                <Empty
                  description="No Access Restrictions"
                  style={{ margin: 25 }}
                />
              </>
            ),
          }}
        >
          <Column
            title="IP Address"
            dataIndex="address"
            key="address"
            width={280}
            render={(address, record) => {
              return (
                <Input
                  onChange={handleChangeAddress(record)}
                  type="text"
                  value={address}
                  placeholder="Enter IP Address or CIDR Notation"
                />
              );
            }}
          />
          <Column
            title="Notes"
            dataIndex="note"
            key="note"
            render={(note, record) => {
              return (
                <Input
                  onChange={handleChangeNote(record)}
                  type="text"
                  value={note}
                  placeholder="Optional note"
                />
              );
            }}
          />
          <Column
            title=""
            dataIndex="id"
            key="id"
            width={45}
            render={(id, record) => (
              <Popconfirm
                key="reset"
                title={<>Are you sure you want to delete this IP address?</>}
                onConfirm={handleDeleteAddress(record)}
                okText="Yes"
                cancelText="No"
              >
                <Button style={{ float: "right" }} size="small">
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            )}
          />
        </Table>
      </Spin>
      <div style={{ padding: 8 }}>
        <Button
          type="primary"
          size="large"
          onClick={handleUpdateAddresses}
          loading={isUpdating}
          disabled={!requiresUpdate}
          block
        >
          {isUpdating ? "Updating..." : "Update"}
        </Button>
      </div>
    </>
  );
};

export default NetworkPane;
