import {
  Button,
  Empty,
  Table,
  Tag,
  Input,
  Space,
  Row,
  Modal,
  Avatar,
  Col,
  Popconfirm,
} from "antd";
import axios from "axios";
import {
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
  SearchOutlined,
  ExportOutlined,
  InfoOutlined,
  UserOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { format } from "date-fns";
import CsvDownload from "react-json-to-csv";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import UserDeleteModal from "../Modal/UserDeleteModal";
import UserClustersModal from "../Modal/UserClustersModal";
import UserBillingModal from "../Modal/UserBillingModal";
import { handleError, uppercaseFirst } from "../../helpers";
import { useMemo, useState } from "react";

const { Column } = Table;

const API_KEY = "2e148ac7-89c1-4c51-a422-b12881a009fa";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const UsersPane = ({ users, host_clusters, clusters, assignments }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [showUserClustersModal, setShowUserClustersModal] = useState(false);
  const [showUserBillingModal, setShowUserBillingModal] = useState(false);

  const [deleteUser, setDeleteUser] = useState(null);

  const handleUserClustersClose = () => {
    setShowUserClustersModal(false);
    setSelectedUser(null);
  };

  const handleUserBillingClose = () => {
    setShowUserBillingModal(false);
    setSelectedUser(null);
  };

  const handleToggleWaitlist = (user, waitlisted) => async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/users/${user.id}`,
        {
          waitlisted,
        },
        {
          headers: {
            "x-api-key": API_KEY,
          },
        }
      );
    } catch (error) {
      handleError(error);
    }
  };

  const handleClustersClick = (user) => () => {
    setSelectedUser(user);
    setShowUserClustersModal(true);
  };

  const handleUserInfo = (user) => () => {
    const { photo_url, ...rest } = user;
    Modal.info({
      icon: photo_url ? (
        <Avatar
          size={80}
          src={<img src={photo_url} alt="User profile" />}
          style={{ float: "left", marginRight: 20 }}
        />
      ) : (
        <Avatar
          size={80}
          icon={<UserOutlined />}
          style={{ float: "left", marginRight: 20 }}
        />
      ),
      content: (
        <pre style={{ fontSize: "11px" }}>{JSON.stringify(rest, null, 2)}</pre>
      ),
      centered: true,
      width: 700,
    });
  };

  const onSearch = (evt) => {
    setSearchTerm(evt.target.value);
  };

  const filteredUsers = useMemo(() => {
    return users.filter((user) => {
      return (
        (user.display_name &&
          user.display_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        user.email_addr.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  }, [searchTerm, users]);

  const exportUsers = useMemo(() => {
    return users
      .filter((user) => {
        return (
          user.display_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          user.email_addr.toLowerCase().includes(searchTerm.toLowerCase())
        );
      })
      .sort((a, b) => {
        if (a.created.seconds > b.created.seconds) return 1;
        if (a.created.seconds < b.created.seconds) return -1;
        return 0;
      })
      .map((user) => {
        return {
          ...user,
          created: new Date(user.created.seconds * 1000),
          updated: new Date(user.updated.seconds * 1000),
        };
      });
  }, [searchTerm, users]);

  const openUserDeleteModal = (user) => (_) => {
    setDeleteUser(user);
  };

  const handleBillingInfo = (user) => () => {
    setSelectedUser(user);
    setShowUserBillingModal(true);
  };

  const statusStyle = {
    width: "70px",
    textAlign: "center",
    marginLeft: "8px",
  };

  const pageSize = useMemo((_) => {
    return Math.floor((window.innerHeight - 400) / 53);
  }, []);

  return users.length > 0 ? (
    <div style={{ marginBottom: "0px" }}>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Row gutter={10}>
          <Col span={18}>
            <Input
              value={searchTerm}
              onChange={onSearch}
              addonAfter={<SearchOutlined />}
              placeholder="Enter search term"
              size="large"
              style={{ width: "100%" }}
            />
          </Col>
          <Col span={6}>
            <CsvDownload
              className="ant-btn ant-btn-lg"
              data={exportUsers}
              delimiter=","
              filename={`saas_users_${new Date()
                .toISOString()
                .slice(-24)
                .replace(/\D/g, "")
                .slice(0, 14)}.csv`}
              style={{ width: "100%" }}
            >
              <ExportOutlined /> Export CSV
            </CsvDownload>
          </Col>
        </Row>
        <Table
          dataSource={filteredUsers}
          rowKey="id"
          size="small"
          pagination={{
            position: ["bottomCenter"],
            pageSize,
          }}
        >
          <Column
            title="Date"
            dataIndex="created"
            key="created"
            width={1}
            defaultSortOrder="descend"
            sorter={(a, b) => a.created - b.created}
            render={(created) => {
              return (
                <div
                  style={{
                    fontSize: "11px",
                    lineHeight: "12px",
                    width: 55,
                  }}
                >
                  {format(new Date(created.seconds * 1000), "MM/dd/yy")}
                  <br />
                  {format(new Date(created.seconds * 1000), "hh:mm aaa")}
                </div>
              );
            }}
          />
          <Column
            title="Name"
            dataIndex="display_name"
            key="display_name"
            sorter={(a, b) => {
              if (a.display_name > b.display_name) return 1;
              if (a.display_name < b.display_name) return -1;
              return 0;
            }}
            render={(display_name, record) => {
              return (
                <div
                  style={{
                    fontSize: "11px",
                    lineHeight: "12px",
                  }}
                >
                  {display_name}
                  <br />
                  <a href={`mailto:${record.email_addr.toLowerCase()}`}>
                    {record.email_addr.toLowerCase()}
                  </a>
                  {record.stripe_customer_id ? (
                    <span style={{ color: "#cccccc" }}>
                      <br />
                      {record.stripe_customer_id}
                    </span>
                  ) : (
                    <>
                      <br />
                      &nbsp;
                    </>
                  )}
                </div>
              );
            }}
          />
          <Column
            title="Wait"
            dataIndex="waitlisted"
            key="waitlisted"
            width={50}
            render={(waitlisted, user) => (
              <div style={{ textAlign: "center" }}>
                {waitlisted ? (
                  <Popconfirm
                    key="reset"
                    title={<>Are you sure you want to remove from waitlist?</>}
                    onConfirm={handleToggleWaitlist(user, false)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <CheckOutlined />
                  </Popconfirm>
                ) : (
                  <Popconfirm
                    key="reset"
                    title={<>Are you sure you want to add to waitlist?</>}
                    onConfirm={handleToggleWaitlist(user, true)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <CloseOutlined style={{ color: "#cccccc" }} />
                  </Popconfirm>
                )}
              </div>
            )}
          />
          <Column
            title="Clusters"
            dataIndex="id"
            key="id"
            width={75}
            render={(id, record) => (
              <div
                style={{ textAlign: "center", cursor: "pointer" }}
                onClick={handleClustersClick(record)}
              >
                {assignments.reduce((acc, cur) => {
                  if (cur.user_id === id) {
                    return acc + 1;
                  }
                  return acc;
                }, 0)}{" "}
                /{" "}
                {record?.cluster_limit === -1 ? (
                  <>&infin;</>
                ) : (
                  record?.cluster_limit
                )}
              </div>
            )}
          />
          <Column
            title="Admin"
            dataIndex="is_admin"
            key="is_admin"
            width={60}
            render={(is_admin) => (
              <div style={{ textAlign: "center" }}>
                {is_admin ? (
                  <CheckOutlined />
                ) : (
                  <CloseOutlined style={{ color: "#cccccc" }} />
                )}
              </div>
            )}
          />
          <Column
            title="Status"
            dataIndex="status"
            key="status"
            width={100}
            sorter={(a, b) => {
              if (a.status > b.status) return 1;
              if (a.status < b.status) return -1;
              return 0;
            }}
            render={(status) => (
              <>
                {status === "online" ? (
                  <Tag color="#87d068" style={statusStyle}>
                    {uppercaseFirst(status)}
                  </Tag>
                ) : (
                  <Tag color="#ff5500" style={statusStyle}>
                    {uppercaseFirst(status)}
                  </Tag>
                )}
              </>
            )}
          />
          <Column
            title=""
            dataIndex="id"
            key="id"
            width={120}
            render={(id, record) => (
              <Space style={{ float: "right" }}>
                <Button
                  key="info"
                  onClick={handleUserInfo(record)}
                  size="small"
                >
                  <InfoOutlined />
                </Button>
                <Button
                  key="billing"
                  onClick={handleBillingInfo(record)}
                  size="small"
                  disabled={!record.stripe_customer_id}
                >
                  <DollarOutlined />
                </Button>
                {/* <Popconfirm
                  key="delete"
                  title={
                    <>
                      Are you sure you want to delete this user and all their
                      database accounts?
                    </>
                  }
                  onConfirm={handleDeleteUser(record)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button style={{ float: "right" }} size="small">
                    <DeleteOutlined />
                  </Button>
                </Popconfirm> */}
                <Button
                  key="delete"
                  onClick={openUserDeleteModal(record)}
                  style={{ float: "right" }}
                  size="small"
                >
                  <DeleteOutlined />
                </Button>
              </Space>
            )}
          />
        </Table>
      </Space>
      {showUserClustersModal && selectedUser && (
        <UserClustersModal
          id={selectedUser.id}
          clusters={clusters}
          host_clusters={host_clusters}
          assignments={assignments}
          width={900}
          height={window.innerHeight - 200}
          visible={showUserClustersModal}
          close={handleUserClustersClose}
        />
      )}
      {showUserBillingModal && selectedUser && (
        <Elements stripe={stripePromise}>
          <UserBillingModal
            user={selectedUser}
            width={900}
            height={window.innerHeight - 200}
            visible={showUserBillingModal}
            close={handleUserBillingClose}
          />
        </Elements>
      )}
      {deleteUser && (
        <UserDeleteModal
          user={deleteUser}
          onCancel={() => {
            setDeleteUser(null);
          }}
          onDelete={() => {
            setDeleteUser(null);
          }}
        />
      )}
    </div>
  ) : (
    <Empty description="No Users Found" style={{ marginTop: "40px" }}></Empty>
  );
};

export default UsersPane;
