import { useState } from "react";
import axios from "axios";
import { Form, Input, Space, Button, Radio, notification } from "antd";

import { handleError } from "../../helpers";

const API_KEY = "2e148ac7-89c1-4c51-a422-b12881a009fa";

const { TextArea } = Input;

const AlertsPane = ({ alerts }) => {
  const [isUpdating, setIsUpdating] = useState(false);

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    if (
      !values.message ||
      !values.description ||
      !values.type ||
      !values.status
    ) {
      return;
    }

    try {
      setIsUpdating(true);

      const { id, ...params } = values;

      await axios.put(
        `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/alerts/${id}`,
        params,
        {
          headers: {
            "x-api-key": API_KEY,
          },
        }
      );
      notification.success({
        message: "Alert Update",
        description: "Your alert has been updated.",
      });
    } catch (error) {
      handleError(error);
    } finally {
      setIsUpdating(false);
    }
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    // console.log("onFinishFailed", values, errorFields, outOfDate);
  };

  return (
    <div style={{ marginBottom: "20px" }}>
      {alerts.map((alert) => {
        return (
          <Form
            key={alert.id}
            form={form}
            layout="vertical"
            initialValues={alert}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item label="ID" name="id" style={{ display: "none" }}>
              <Input size="large" readOnly />
            </Form.Item>
            <Form.Item label="Message" name="message">
              <Input size="large" />
            </Form.Item>
            <Form.Item label="Description" name="description">
              <TextArea size="large" />
            </Form.Item>
            <Form.Item label="Type" name="type">
              <Radio.Group
                options={[
                  { label: "Success", value: "success" },
                  { label: "Info", value: "info" },
                  { label: "Warning", value: "warning" },
                  { label: "Error", value: "error" },
                ]}
                optionType="button"
                buttonStyle="solid"
                size="large"
              />
            </Form.Item>
            <Form.Item label="Enable" name="status">
              <Radio.Group
                options={[
                  { label: "False", value: "offline" },
                  { label: "True", value: "online" },
                ]}
                optionType="button"
                buttonStyle="solid"
                size="large"
              />
            </Form.Item>
            <Form.Item label="System Disable" name="disable">
              <Radio.Group
                options={[
                  { label: "False", value: false },
                  { label: "True", value: true },
                ]}
                optionType="button"
                buttonStyle="solid"
                size="large"
              />
            </Form.Item>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={isUpdating}
                block
              >
                {isUpdating ? "Updating..." : "Update"}
              </Button>
            </Space>
          </Form>
        );
      })}
    </div>
  );
};

export default AlertsPane;
