import { useCallback, useState, useEffect } from "react";
import {
  Button,
  Table,
  Tag,
  Empty,
  Space,
  Popconfirm,
  notification,
} from "antd";
import {
  CloudSyncOutlined,
  // DeleteOutlined,
  SyncOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import { format } from "date-fns";
import axios from "axios";

import { useAuth } from "../../hooks/useAuth";
import firebase from "../../services/firebase";
import useSegment from "../../hooks/useSegment";

import { uppercaseFirst, handleError } from "../../helpers";

const { Column } = Table;

const BackupPane = ({ cluster }) => {
  const auth = useAuth();
  const db = firebase.firestore();

  const segment = useSegment(auth);

  const [actions, setActions] = useState([]);

  const getActions = useCallback(() => {
    const fetch = async () => {
      try {
        const token = await auth.getIdToken();
        const types = "backup_cluster,restore_cluster";
        const resp = await axios.get(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/actions/cluster/${cluster.id}?types=${types}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const actions = resp?.data?.actions
          .filter((action) => Object.keys(action).length > 1)
          .sort((a, b) => {
            return b.created._seconds - a.created._seconds;
          });

        setActions(actions);
      } catch (error) {
        handleError(error);
        setActions([]);
      }
    };
    fetch();
  }, [auth, cluster]);

  useEffect(() => {
    const unsubscribe = db
      .collection("dashboards")
      .doc(auth.user.db.id)
      .onSnapshot((snapshot) => {
        getActions();
      });
    return () => unsubscribe();
  }, [db, auth, getActions]);

  const handleBackupNow = async () => {
    try {
      const token = await auth.getIdToken();
      await axios.post(
        `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/clusters/${cluster.id}/backup`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Segment
      segment.track("backup_now");

      notification.success({
        message: "Database Backup",
        description: "Database is being backed up.",
      });
    } catch (error) {
      handleError(error);
    }
  };

  const handleRestoreBackup = (backup) => async (_) => {
    try {
      if (
        backup &&
        backup.type === "backup_cluster" &&
        backup.status === "complete" &&
        backup.backupName
      ) {
        const token = await auth.getIdToken();
        await axios.post(
          `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/clusters/${cluster.id}/restore`,
          {
            backupName: backup.backupName,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Segment
        segment.track("restore_backup");

        notification.success({
          message: "Backup Restore",
          description: "Backup is being restored.",
        });
      }
    } catch (error) {
      handleError(error);
    }
  };

  // const handleDeleteBackup = (backup) => {
  //   //
  // };

  const activity = [
    // {
    //   id: "5d6709c7-a072-4539-a373-be04410a62fa",
    //   type: "backup",
    //   status: "pending",
    //   created: { seconds: 1674781068 },
    // },
    // {
    //   id: "fc49bd4f-7e18-408f-aac2-a6006d9b1349",
    //   type: "backup",
    //   status: "complete",
    //   created: { seconds: 1673771068 },
    // },
    ...actions,
  ];

  const statusStyle = {
    width: "80px",
    textAlign: "center",
    marginLeft: "8px",
  };

  return (
    <>
      <Space style={{ float: "right" }}>
        {/* <Popconfirm
          key="restore"
          title={<>Are you sure you want to restore from latest backup?</>}
          onConfirm={handleRestoreBackup()}
          okText="Yes"
          cancelText="No"
        >
          <Button type="primary" icon={<RollbackOutlined />} ghost>
            Restore Last Backup
          </Button>
        </Popconfirm> */}
        <Popconfirm
          key="reset"
          title="Database will be suspended during backup. Continue?"
          onConfirm={handleBackupNow}
          okText="Yes"
          cancelText="No"
        >
          <Button type="primary" icon={<CloudSyncOutlined />}>
            Backup Now
          </Button>
        </Popconfirm>
      </Space>
      <h2 style={{ fontWeight: 300, marginBottom: "15px" }}>Backup</h2>
      <Table
        dataSource={activity}
        rowKey="id"
        size="small"
        pagination={false}
        locale={{
          emptyText: (
            <>
              <Empty description="No Activity Found" style={{ margin: 25 }} />
            </>
          ),
        }}
      >
        <Column
          title="Activity"
          dataIndex="created"
          key="created"
          width={1}
          render={(created) => {
            return (
              <div
                style={{
                  fontSize: "11px",
                  lineHeight: "12px",
                  width: 55,
                }}
              >
                {format(new Date(created._seconds * 1000), "MM/dd/yy")}
                <br />
                {format(new Date(created._seconds * 1000), "hh:mm aaa")}
              </div>
            );
          }}
        />
        <Column
          title="Type"
          dataIndex="type"
          key="type"
          render={(type) => {
            return type;
          }}
        />
        <Column
          title="Status"
          dataIndex="status"
          key="status"
          width={110}
          render={(status) => (
            <>
              {status === "complete" ? (
                <Tag color="#87d068" style={statusStyle}>
                  {uppercaseFirst(status)}
                </Tag>
              ) : (
                <Tag color="#ff5500" style={statusStyle}>
                  {status === "pending" && <SyncOutlined spin />}{" "}
                  {uppercaseFirst(status)}
                </Tag>
              )}
            </>
          )}
        />
        <Column
          title=""
          dataIndex="id"
          key="id"
          width={1}
          render={(id, record) => {
            const disabled =
              record.type !== "backup_cluster" ||
              record.status !== "complete" ||
              !record.backupName;
            return record.type === "backup_cluster" ? (
              <Space style={{ float: "right" }}>
                <Popconfirm
                  key="restore"
                  title="Database will be suspended during restore. Continue?"
                  onConfirm={handleRestoreBackup(record)}
                  okText="Yes"
                  cancelText="No"
                  disabled={disabled}
                >
                  <Button
                    style={{ float: "right" }}
                    size="small"
                    disabled={disabled}
                  >
                    <RollbackOutlined /> Restore
                  </Button>
                </Popconfirm>
                {/* <Popconfirm
                  key="delete"
                  title={<>Are you sure you want to delete this backup?</>}
                  onConfirm={handleDeleteBackup(record)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button style={{ float: "right" }} size="small">
                    <DeleteOutlined />
                  </Button>
                </Popconfirm> */}
              </Space>
            ) : (
              <></>
            );
          }}
        />
      </Table>
    </>
  );
};

export default BackupPane;
