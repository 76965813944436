import { useMemo } from "react";
import axios from "axios";

const api = (auth) => {
  const track = async (event, params = {}) => {
    const token = await auth.getIdToken();
    await axios.post(
      `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/sm/track`,
      {
        event,
        params,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };
  return {
    auth,
    identify: (id, payload = {}) => {
      window.analytics.identify(id, payload);
    },
    group: (id) => {
      window.analytics.group(id);
    },
    page: (location) => {
      window.analytics.page(location);
    },
    track: (id, payload = {}) => {
      if (!(window.analytics.track(id, payload) instanceof Promise)) {
        track(id, payload);
      }
    },
  };
};

const useSegment = (auth) => {
  const memoized = useMemo(() => {
    return api;
  }, []);
  return memoized(auth);
};

export default useSegment;
