import React, { useEffect, useMemo, useState } from "react";
import { Button, Space, Avatar, Select, Tooltip } from "antd";
import axios from "axios";
import store from "store2";

import { UserOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { handleError } from "../../helpers";
import { useAuth } from "../../hooks/useAuth";
import { STORAGE_KEY_TEAM } from "../../constants";
import useSegment from "../../hooks/useSegment";

const { Option } = Select;

const Header = () => {
  const auth = useAuth();
  const history = useHistory();

  const [teams, setTeams] = useState([]);
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const segment = useSegment(auth);

  const [currentTeam, setCurrentTeam] = useState(null);
  useEffect(() => {
    const currentTeam = store.get(STORAGE_KEY_TEAM);
    setCurrentTeam(currentTeam);
  }, []);

  useEffect(() => {
    const fetchTeams = async () => {
      const token = await auth.getIdToken();
      const resp = await axios.get(
        `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/teams`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTeams(resp?.data?.teams);
      if (resp?.data?.teams.length > 0) {
        const team = resp?.data?.teams[0];
        setCurrentTeam(team);
        store.set(STORAGE_KEY_TEAM, team);
      }
    };
    fetchTeams();
  }, [auth]);

  const handleLogout = async () => {
    try {
      // Segment
      segment.track("console_logout");

      setIsLoggingOut(true);
      await auth.logout();

      history.push("/");
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoggingOut(false);
    }
  };

  const displayName = useMemo(() => {
    if (auth) {
      const displayName = auth?.user?.displayName ?? "";
      if (displayName.split(" ").length > 1) {
        return displayName.split(" ")[0];
      } else {
        return displayName;
      }
    } else {
      return "";
    }
  }, [auth]);

  const handleTeamChange = (teamId) => {
    const team = teams.find((team) => team.id === teamId);
    store.set(STORAGE_KEY_TEAM, team);
  };

  return (
    <>
      {auth.user && (
        <div style={{ float: "right" }}>
          <Space align="center">
            <h3 style={{ margin: "0px", fontWeight: 300 }}>
              Welcome,{" "}
              <span style={{ fontWeight: 600, textTransform: "capitalize" }}>
                {displayName}
              </span>
              !
              {auth.user.photoURL ? (
                <Avatar
                  src={auth.user.photoURL}
                  style={{ marginLeft: "10px" }}
                />
              ) : (
                <Avatar
                  icon={<UserOutlined />}
                  style={{ marginLeft: "10px" }}
                />
              )}
            </h3>
            <Tooltip placement="top" title="Team">
              <Select
                size="small"
                value={teams.length > 0 ? currentTeam?.id : ""}
                onChange={handleTeamChange}
                style={{ minWidth: 80, display: "none" }}
              >
                {teams.map((team) => {
                  return (
                    <Option key={team.id} value={team.id}>
                      {team.name}
                    </Option>
                  );
                })}
              </Select>
            </Tooltip>
            <Button onClick={handleLogout} loading={isLoggingOut} size="small">
              {isLoggingOut ? "Logging Out..." : "Log Out"}
            </Button>
          </Space>
        </div>
      )}
    </>
  );
};

export default Header;
