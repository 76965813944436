import React, { useEffect, useState } from "react";
import { Tabs, Row, Col } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import KineticaLogo from "../../images/logo.svg";

const { TabPane } = Tabs;

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const Login = () => {
  const query = useQuery();
  const auth = useAuth();
  const history = useHistory();

  const [activeTabKey, setActiveTabKey] = useState(
    query.get("t") && query.get("t") === "s" ? "signup" : "login"
  );

  useEffect(
    (_) => {
      if (auth.user) {
        history.push("/");
      }
    },
    [auth, history]
  );

  const tabStyle = { width: "125px", textAlign: "center" };
  const topStyle = {
    top: "max(calc(50% - 400px), 20px)",
    position: "relative",
  };

  return (
    <Row style={topStyle}>
      <Col xs={3} sm={5} md={6} lg={7} xl={8}></Col>
      <Col xs={18} sm={14} md={12} lg={10} xl={8}>
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <img
            src={KineticaLogo}
            alt="Kinetica Logo"
            style={{ height: "40px", position: "relative", top: "-3px" }}
          />
          <h1
            style={{
              color:
                process.env.REACT_APP_ENV === "prod" ? "#8A68C9" : "#eb2f96",
              fontSize: "34px",
              fontWeight: "200",
            }}
          >
            {process.env.REACT_APP_SITE_TITLE}
          </h1>
        </div>
        <div
          className="login"
          style={{
            padding: "10px 60px 30px",
            backgroundColor: "#ffffff",
            borderRadius: "20px",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
            maxWidth: 420,
            margin: "auto",
          }}
        >
          <Tabs
            activeKey={activeTabKey}
            size="large"
            tabBarGutter={0}
            onChange={(active) => setActiveTabKey(active)}
            destroyInactiveTabPane
            centered
          >
            <TabPane tab={<div style={tabStyle}>Login</div>} key="login">
              <div style={{ padding: "10px 0px", height: "510px" }}>
                <LoginForm setActiveTabKey={setActiveTabKey} />
              </div>
            </TabPane>
            <TabPane tab={<div style={tabStyle}>Sign Up</div>} key="signup">
              <div style={{ padding: "10px 0px", height: "510px" }}>
                <SignupForm setActiveTabKey={setActiveTabKey} />
              </div>
            </TabPane>
          </Tabs>
        </div>
      </Col>
      <Col xs={3} sm={5} md={6} lg={7} xl={8}></Col>
    </Row>
  );
};

export default Login;
