import React, { useContext } from "react";
import { WarningOutlined } from "@ant-design/icons";

import KineticaLogo from "../../images/logo.svg";
import { AlertContext } from "../../constants";

const Disabled = () => {
  const alerts = useContext(AlertContext);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        paddingTop:
          alerts.length > 0 ? "calc(50% - 340px)" : "calc(50% - 260px)",
        textAlign: "center",
      }}
    >
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <img
          src={KineticaLogo}
          alt="Kinetica Logo"
          style={{ height: "40px", position: "relative", top: "-3px" }}
        />
        <h1
          style={{
            color: "#8A68C9",
            fontSize: "34px",
            fontWeight: "200",
          }}
        >
          Cloud
        </h1>
      </div>
      <WarningOutlined
        style={{
          fontSize: 200,
          color: "#8A68C9",
          opacity: 0.25,
        }}
      />
    </div>
  );
};

export default Disabled;
