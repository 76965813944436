import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Spin, Tabs, Tag } from "antd";
import axios from "axios";

import SummaryPane from "./SummaryPane";
import NetworkPane from "./NetworkPane";
import BackupPane from "./BackupPane";
import ActivityPane from "./ActivityPane";
import { useAuth } from "../../hooks/useAuth";
import { handleError, uppercaseFirst } from "../../helpers";
import { ADMIN_LAYOUT, SERVICE_LEVEL } from "../../constants";

const { TabPane } = Tabs;

const Cluster = () => {
  const auth = useAuth();
  const { cluster_id } = useParams();

  const [cluster, setCluster] = useState(null);
  const [assignment, setAssignment] = useState(null);

  const fetchCluster = useCallback(
    (_) => {
      const load = async () => {
        try {
          const token = await auth.getIdToken();
          const resp1 = await axios.get(
            `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/clusters/${cluster_id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const { cluster } = resp1.data;
          setCluster(cluster);

          const resp2 = await axios.get(
            `${process.env.REACT_APP_FB_FUNCTIONS_DOMAIN}/assignments`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const assignments = resp2?.data?.assignments.filter(
            (assignment) => Object.keys(assignment).length > 1
          );

          const assignment = assignments.find(
            (assignment) => assignment.cluster_id === cluster.id
          );
          if (assignment) {
            setAssignment(assignment);
          } else {
            throw Error("No assignment found for cluster");
          }
        } catch (error) {
          handleError(error);
          setCluster(null);
          setAssignment(null);
        }
      };
      load();
    },
    [cluster_id, auth]
  );

  useEffect(() => {
    fetchCluster();
  }, [fetchCluster]);

  const statusStyle = {
    width: "70px",
    textAlign: "center",
    marginLeft: "8px",
    marginTop: "10px",
    verticalAlign: "top",
  };

  return (
    <>
      {cluster && assignment ? (
        <h1 style={{ marginBottom: 0 }}>
          {cluster.cluster_label || cluster.cluster_name}{" "}
          {cluster.status !== "online" && (
            <Tag color="#ff5500" style={statusStyle}>
              {uppercaseFirst(cluster.status || "unknown")}
            </Tag>
          )}
        </h1>
      ) : (
        <h1>Database</h1>
      )}
      <div className="cluster" style={{ maxWidth: ADMIN_LAYOUT.MAXWIDTH }}>
        {cluster && assignment ? (
          <>
            <h4 style={{ color: "#999999", fontWeight: "normal" }}>
              {cluster.cluster_name}
            </h4>
            <div style={{ height: "5px" }}></div>
            <div style={{ maxWidth: ADMIN_LAYOUT.MAXWIDTH }}>
              <Tabs type="card">
                <TabPane tab="Summary" key="summary">
                  <div style={{ padding: "0 20px" }}>
                    <SummaryPane cluster={cluster} assignment={assignment} />
                  </div>
                </TabPane>
                {cluster.service_level === SERVICE_LEVEL.DEDICATED && (
                  <TabPane tab="Network" key="network">
                    <div style={{ padding: "0 20px" }}>
                      <NetworkPane
                        cluster={cluster}
                        fetchCluster={fetchCluster}
                      />
                    </div>
                  </TabPane>
                )}
                {cluster.service_level === SERVICE_LEVEL.DEDICATED && (
                  <TabPane tab="Backup" key="backup">
                    <div style={{ padding: "0 20px" }}>
                      <BackupPane cluster={cluster} />
                    </div>
                  </TabPane>
                )}
                {cluster.service_level === SERVICE_LEVEL.DEDICATED && (
                  <TabPane tab="Activity" key="activity">
                    <div style={{ padding: "0 20px" }}>
                      <ActivityPane cluster={cluster} />
                    </div>
                  </TabPane>
                )}
              </Tabs>
            </div>
          </>
        ) : (
          <div
            style={{
              maxWidth: ADMIN_LAYOUT.MAXWIDTH,
              padding: "200px",
              textAlign: "center",
            }}
          >
            <Spin size="large" />
          </div>
        )}
      </div>
    </>
  );
};

export default Cluster;
