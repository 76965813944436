import React, { useMemo } from "react";
import { Modal, Button } from "antd";
import {
  LineChart,
  Line,
  Label,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { format } from "date-fns";

const ClusterUsageModal = ({
  cluster,
  usage = [],
  width = 750,
  height = 600,
  visible,
  close,
}) => {
  const compute = useMemo(
    (_) => {
      return usage
        .filter((event) => event.type === "compute")
        .map((event) => {
          const { quantity, created } = event;
          return {
            timestamp: created.seconds * 1000,
            quantity,
          };
        });
    },
    [usage]
  );

  const storage = useMemo(
    (_) => {
      return usage
        .filter((event) => event.type === "storage")
        .map((event) => {
          const { quantity, created } = event;
          return {
            timestamp: created.seconds * 1000,
            quantity: quantity / 10,
          };
        });
    },
    [usage]
  );

  const network = useMemo(
    (_) => {
      return usage
        .filter((event) => event.type === "network")
        .map((event) => {
          const { quantity, created } = event;
          return {
            timestamp: created.seconds * 1000,
            quantity: quantity / 10,
          };
        });
    },
    [usage]
  );

  const CustomTooltip = ({ active, payload, label, unit }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "#e7e6f7",
            padding: "5px 10px",
            fontSize: "12px",
            borderRadius: 5,
          }}
        >
          {format(new Date(label), "MM/dd/yy hh:mm aa")}
          <br />
          <strong>
            {payload[0].value} {unit}
          </strong>
        </div>
      );
    }

    return null;
  };

  const tickFormatter = (value) => {
    return format(new Date(value), "MM/dd/yy hh:mm aa");
  };

  const LINE_CHART_MARGINS = {
    top: 5,
    right: 30,
    left: 10,
    bottom: 5,
  };

  const TICK_STYLE = { fontSize: "11px" };
  const XAXIS_PADDING = { left: 30, right: 30 };
  const XAXIS_DOMAIN = ["auto", "auto"];

  return (
    <Modal
      title={`Cluster Usage: ${cluster.cluster_name}`}
      onCancel={close}
      onOk={close}
      closable={false}
      visible={visible}
      footer={[
        <Button key="ok" type="primary" onClick={close}>
          OK
        </Button>,
      ]}
      width={width}
      height={height}
      centered
      destroyOnClose
    >
      <div
        style={{
          height: height - 200,
          marginBottom: "20px",
          textAlign: "center",
        }}
      >
        <h3>Compute</h3>
        <div style={{ height: 200 }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={compute} margin={LINE_CHART_MARGINS}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="timestamp"
                type="number"
                tick={TICK_STYLE}
                tickFormatter={tickFormatter}
                padding={XAXIS_PADDING}
                domain={XAXIS_DOMAIN}
              />
              <YAxis>
                <Label position="left" offset={-10} angle={-90}>
                  Hours
                </Label>
              </YAxis>
              <Tooltip content={<CustomTooltip unit="hours" />} />
              <Line
                type="monotone"
                dataKey="quantity"
                stroke="#8884d8"
                activeDot={{ r: 5 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <h3>Storage</h3>
        <div style={{ height: 200 }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={storage} margin={LINE_CHART_MARGINS}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="timestamp"
                type="number"
                tick={TICK_STYLE}
                tickFormatter={tickFormatter}
                padding={XAXIS_PADDING}
                domain={XAXIS_DOMAIN}
              />
              <YAxis>
                <Label position="left" offset={-10} angle={-90}>
                  Dollars
                </Label>
              </YAxis>
              <Tooltip content={<CustomTooltip unit="dollars" />} />
              <Line
                type="monotone"
                dataKey="quantity"
                stroke="#8884d8"
                activeDot={{ r: 5 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <h3>Network</h3>
        <div style={{ height: 200 }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={network} margin={LINE_CHART_MARGINS}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="timestamp"
                type="number"
                tick={TICK_STYLE}
                tickFormatter={tickFormatter}
                padding={XAXIS_PADDING}
                domain={XAXIS_DOMAIN}
              />
              <YAxis>
                <Label position="left" offset={-10} angle={-90}>
                  Dollars
                </Label>
              </YAxis>
              <Tooltip content={<CustomTooltip unit="dollars" />} />
              <Line
                type="monotone"
                dataKey="quantity"
                stroke="#8884d8"
                activeDot={{ r: 5 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </Modal>
  );
};

export default ClusterUsageModal;
