import { useCallback, useEffect, useState } from "react";
import { Modal, Input, Form, Checkbox, Space } from "antd";

import { useAuth } from "../../hooks/useAuth";
import useSegment from "../../hooks/useSegment";
import { handleError } from "../../helpers";

const INTERESTS = [
  {
    label: "Time-series analytics",
    value: "company_interest_time",
  },
  {
    label: "Spatial analytics",
    value: "company_interest_space",
  },
  {
    label: "Graph analytics",
    value: "company_interest_graph",
  },
  {
    label: "Real-time analytics",
    value: "company_interest_real",
  },
  {
    label: "Conversational query (SQL-GPT)",
    value: "company_interest_sqlgpt",
  },
  {
    label: "Evaluating performance",
    value: "company_interest_perf",
  },
  {
    label: "OLAP",
    value: "company_interest_olap",
  },
  {
    label: "Other",
    value: "company_interest_other",
  },
];

const ProfileUpdateModal = ({ showIfMissing = [] }) => {
  const auth = useAuth();
  const segment = useSegment(auth);

  const [form] = Form.useForm();

  const [isUpdating, setIsUpdating] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);

  const [interests, setInterests] = useState([]);
  const [other, setOther] = useState("");

  useEffect(() => {
    const hasMissing =
      auth.user &&
      auth.user.emailVerified &&
      showIfMissing.some((item) => {
        return auth.user.db[item] === undefined || auth.user.db[item] === "";
      });
    if (hasMissing) {
      setInfoModalOpen(true);
    }
  }, [auth.user, showIfMissing]);

  const onFinish = useCallback(
    async (values) => {
      const { company_name, company_contact_me } = values;

      if (interests.includes("company_interest_other") && other.trim() === "") {
        form.setFields([
          {
            name: "interests",
            errors: ["Please specify your other interest!"],
          },
        ]);
        return;
      }

      const update = INTERESTS.reduce(
        (acc, cur) => {
          if (cur.value === "company_interest_other") {
            acc[cur.value] = interests.includes(cur.value) ? other.trim() : "";
          } else {
            acc[cur.value] = interests.includes(cur.value);
          }
          return acc;
        },
        { company_name, company_contact_me }
      );

      try {
        setIsUpdating(true);
        await auth.updateUser(auth.user.db.id, update);
        form.resetFields();

        // Segment
        segment.track("account_update");
        for (const key of Object.keys(update)) {
          let event;
          const interest = INTERESTS.find((interest) => interest.value === key);
          if (interest) {
            event = `Interested in ${interest.label}: ${update[key]}`;
          } else if (key === "company_name") {
            event = `Company or organization: ${update[key]}`;
          } else if (key === "company_contact_me") {
            event = `I would like Kinetica to reach out with more information: ${update[key]}`;
          }
          segment.track(event);
        }

        setInfoModalOpen(false);
      } catch (error) {
        if (error.code && error.code === "auth/requires-recent-login") {
          setInfoModalOpen(true);
        } else {
          handleError(error);
        }
      } finally {
        setIsUpdating(false);
      }
    },
    [auth, form, interests, other, segment]
  );

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    // console.log("onFinishFailed", values, errorFields, outOfDate);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setInfoModalOpen(false);
  };

  const onInterestsChange = (interests) => {
    setInterests(interests);
  };

  const onOtherChange = (e) => {
    setOther(e.target.value);
  };

  return (
    <Modal
      title={
        <>
          <h2 style={{ marginBottom: 5, color: "#8a68c9" }}>
            Welcome to Kinetica Cloud!
          </h2>
          <h4 style={{ margin: 0 }}>Tell us more about yourself</h4>
        </>
      }
      open={infoModalOpen}
      onOk={handleOk}
      okText="Update"
      okButtonProps={{ loading: isUpdating }}
      onCancel={handleCancel}
      cancelButtonProps={{ style: { display: "none" } }}
      closable={false}
      maskClosable={false}
      centered
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={auth.user.db}
      >
        <Form.Item
          label="Company or organization"
          name="company_name"
          rules={[
            {
              required: true,
              message: "Please enter your company or organization!",
            },
          ]}
        >
          <Input
            size="large"
            placeholder="Enter ‘Personal’ for non-business use"
          />
        </Form.Item>
        <Form.Item
          label="Why are you interested in Kinetica? Check all that apply"
          name="interests"
          rules={[
            {
              required: true,
              message: "Please select one or more interests!",
            },
          ]}
        >
          <Checkbox.Group value={interests} onChange={onInterestsChange}>
            <Space direction="vertical" size={10} style={{ width: "100%" }}>
              {INTERESTS.map((interest) => {
                return (
                  <Checkbox key={interest.value} value={interest.value}>
                    {interest.label}
                    {interest.value === "company_interest_other" &&
                      interests.includes("company_interest_other") && (
                        <Input
                          value={other}
                          onChange={onOtherChange}
                          placeholder="Enter your other interest"
                          size="small"
                          style={{
                            width: 300,
                            marginLeft: 20,
                            fontSize: "14px",
                            lineHeight: "18px",
                          }}
                        />
                      )}
                  </Checkbox>
                );
              })}
            </Space>
          </Checkbox.Group>
        </Form.Item>
        <div style={{ height: 10 }}></div>
        <Form.Item
          name="company_contact_me"
          valuePropName="checked"
          initialValue={false}
        >
          <Checkbox>
            I would like Kinetica to reach out with more information
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ProfileUpdateModal;
